<template>
  <div class="electronics" ref="electronics">
    <section
      class="electronics__section electronics__hero"
      id="section-products-hero"
    >
      <div class="electronics__hero__img">
        <div class="div-background-img"></div>
      </div>
      <div class="electronics__hero__heading">
        <h1>
          {{ $t("electronicsHeading1") }} <br />
          <span>{{ $t("electronicsHeading2") }}</span
          >!
        </h1>
      </div>
      <div
        class="electronics__hero__btn-container"
        v-show="isWindowWidthDesktop"
      >
        <a
          class="btn-primary btn-primary-down btn-primary-down--blue"
          href="#tessaSection"
          @click="setUrlBack"
        >
          <div></div>
          <p>{{ $t("scrollDownLbl") }}</p>
          <span></span>
        </a>
      </div>
    </section>
    <section
      class="electronics__section electronics__container"
      :id="product.idTag"
      :ref="product.idTag"
      :class="[{ 'electronics__container--reverse': !product.positionLeft }]"
      v-for="product in products"
      :key="product.id"
    >
      <div
        class="electronics__container__content__number"
        v-show="!isWindowWidthDesktop"
      >
        <h1>{{ product.number }}</h1>
      </div>
      <div class="electronics__container__image" v-show="!isWindowWidthDesktop">
        <Carousel :images="product.image" />
        <!-- <div
          class="div-background-img"
          :style="{
            backgroundImage:
              'url(' + require(`../assets/imgs/${product.image}`) + ')',
          }"
          :class="
            product.watermarkClass === 'electronics__container__image--circle'
              ? ''
              : product.watermarkClass
          "
        ></div> -->
        <div
          class="div-background-img"
          :class="
            product.watermarkClass === 'electronics__container__image--circle'
              ? product.watermarkClass
              : ''
          "
        ></div>
      </div>
      <div class="electronics__container__content">
        <div
          class="electronics__container__content__number"
          v-show="isWindowWidthDesktop"
        >
          <h1>{{ product.number }}</h1>
        </div>
        <div class="electronics__container__content__main">
          <h1>{{ product.name }}</h1>
          <p
            style="white-space: pre-line"
            v-html="converHtmlToText(product.description)"
          ></p>
        </div>
        <div class="electronics__container__content__btn-container">
          <button
            @click="goToPage(product.button.link)"
            class="btn-primary btn-primary-right"
            :class="`btn-primary-right--${product.button.color}`"
          >
            <span></span>
            <p>{{ product.button.text }}</p>
            <div></div>
          </button>
        </div>
      </div>
      <div class="electronics__container__image" v-show="isWindowWidthDesktop">
        <Carousel :images="product.image" />
        <!-- <div
          class="div-background-img"
          :style="{
            backgroundImage:
              'url(' + require(`../assets/imgs/${product.image}`) + ')',
          }"
          :class="
            product.watermarkClass === 'electronics__container__image--circle'
              ? ''
              : product.watermarkClass
          "
        ></div> -->
        <!-- <div
          class="div-background-img"
          :class="
            product.watermarkClass === 'electronics__container__image--circle'
              ? product.watermarkClass
              : ''
          "
        ></div> -->
      </div>
    </section>
    <transition name="fade">
      <div
        class="electronics__pagination"
        v-if="currentSlide > 0 && currentSlide < 6"
        v-show="isWindowWidthDesktop"
      >
        <div class="electronics__pagination__text">
          <p>{{ $t("clickOrScrollLbl") }}</p>
        </div>
        <div class="electronics__pagination__circles">
          <span
            @click="moveScrollTo(4)"
            :class="{
              'electronics__pagination--active-line electronics__pagination--active':
                currentSlide === 4,
            }"
            class="electronics__pagination__bullet "
          ></span>
          <span
            @click="moveScrollTo(3)"
            :class="{
              'electronics__pagination--active-line electronics__pagination--active':
                currentSlide === 3,
            }"
            class="electronics__pagination__bullet "
          ></span>
          <span
            @click="moveScrollTo(2)"
            :class="{
              'electronics__pagination--active-line': currentSlide === 1,
              'electronics__pagination--active-line electronics__pagination--active':
                currentSlide === 2,
            }"
            class="electronics__pagination__bullet "
          ></span>
          <span
            @click="moveScrollTo(1)"
            :class="{ 'electronics__pagination--active': currentSlide === 1 }"
            class="electronics__pagination__bullet "
          ></span>
        </div>
      </div>
    </transition>
    <section class="electronics__footer-section">
      <Footer />
    </section>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import EventBus from "../event-bus";
import useHtmlToTextConverter from "../mixins/useHtmlToTextConverter";
import Carousel from "../components/Carousel.vue";
export default {
  data() {
    const { converHtmlToText } = useHtmlToTextConverter();
    return {
      converHtmlToText,
      scrollPosition: 0,
      currentElId: "other",
      inWidth: 0,
    };
  },
  computed: {
    products() {
      return [
        {
          id: 0,
          number: "01",
          name: "Tessa",
          description: this.$t("projectTessaDesc"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "red2",
            link: "https://www.tinybots.nl/",
          },
          image: [
            { id: 0, path: "Tessa01_min.png" },
            { id: 1, path: "Tessa02_min.png" },
            { id: 2, path: "Tessa03_min.png" },
          ],
          watermarkClass: "electronics__container__image--triangle",
          classTag: "electronics__tessa",
          idTag: "tessaSection",
          positionLeft: true,
        },
        {
          id: 1,
          number: "02",
          name: "FloatPlus",
          description: this.$t("floatPlusElecDescription"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "blue",
            link: "https://floatplus.com/",
          },
          image: [
            { id: 0, path: "FloatPlus02-min.png" },
            { id: 1, path: "FloatPlus03-min.png" },
          ],
          watermarkClass: "electronics__container__image--triangle",
          idTag: "floatplusSection",
          classTag: "electronics__floatplus",
          positionLeft: false,
        },
        {
          id: 2,
          number: "03",
          name: "Smart Floor",
          description: this.$t("smartFloorDescription"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "purple",
            link: "https://smartfloor.com/",
          },
          image: [
            { id: 0, path: "SmartFloor01_min.png" },
            { id: 1, path: "SmartFloor02_min.png" },
            { id: 2, path: "SmartFloor03_min.png" },
          ],
          watermarkClass: "electronics__container__image--square",
          idTag: "smartfloorSection",
          classTag: "electronics__smartfloor",
          positionLeft: true,
        },
        {
          id: 3,
          number: "04",
          name: "Inicare",
          description: this.$t("inicareDescription"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "purple",
            link: "https://www.inicare.com/nura/",
          },
          image: [
            { id: 0, path: "Inicare01_min.png" },
            { id: 1, path: "Inicare02_min.png" }

          ],
          watermarkClass: "electronics__container__image--square",
          idTag: "inicareSection",
          classTag: "electronics__inicare",
          positionLeft: false,
        },
        {
          id: 5,
          number: "06",
          name: "Fysicon",
          description: this.$t("fysiconDesc"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "purple3",
            link: "https://www.fysicon.com/",
          },
          image: [
            { id: 0, path: "Fysicon01-min.png" },
          ],
          watermarkClass: "software__container__image--triangle",
          idTag: "fysicon",
          classTag: "software__gms",
          positionLeft: true,
        },
        {
          id: 6,
          number: "07",
          name: "Taplite",
          description: this.$t("tapliteDesc"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "purple3",
            link: "https://www.taplite.com/",
          },
          image: [
            { id: 0, path: "Taplite01-min.png" },
          ],
          watermarkClass: "software__container__image--triangle",
          idTag: "taplite",
          classTag: "software__gms",
          positionLeft: false
        }
      ];
    },
    isWindowWidthDesktop() {
      if (this.inWidth <= 960) {
        return false;
      }
      return true;
    },
    colorToScrollPositionMap() {
      // if (this.currentElId === "other") {
      //   this.emitMethod("gray");
      // } else if (this.currentElId === "tessaSection") {
      //   this.emitMethod("red_2");
      // } else if (this.currentElId === "floatplusSection") {
      //   this.emitMethod("cyan_2");
      // } else if (this.currentElId === "smartfloorSection") {
      //   this.emitMethod("white_1");
      // }
      return {
        // change_gray: this.currentElId === "other",
        // change_red_2: this.currentElId === "tessaSection",
        // change_cyan_2: this.currentElId === "floatplusSection",
        // change_white_1: this.currentElId === "smartfloorSection",
      };
    },
    currentSlide() {
      for (let index = 0; index < this.products.length; index++) {
        if (this.products[index].idTag === this.currentElId) {
          return index + 1;
        } else if (this.products[index].idTag === "other") {
          return 0;
        }
      }
      return 0;
    },
  },
  mounted() {
    //this.testingOverflow();
    if (this.$refs.electronics) {
      this.$refs.electronics.addEventListener("scroll", this.updateScroll);
      this.$refs.electronics.addEventListener("scroll", this.isScrolledToElem);
      this.$refs.electronics.addEventListener("scroll", this.onScrollHandler);
    }
    window.addEventListener("resize", this.updateInnerWidth);
    this.updateInnerWidth();
  },
  methods: {
    onScrollHandler(event) {
      if (this.resizedSmallerScreen) {
        window.scrollTo(0, 1);
      }
      let x = (event.target.scrollTop / 10) % Math.PI;
      EventBus.$emit("pageScrolled", { theta: x });
    },
    goToPage(link) {
      window.open(
        link,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    emitMethod(payload) {
      EventBus.$emit("colorChange", payload);
    },
    updateInnerWidth() {
      this.inWidth = window.innerWidth;
    },
    updateScroll(event) {
      this.scrollPosition = event.target.scrollTop;
    },
    isScrolledToElem(event) {
      let elOffsetTop = 0;
      let idTags;
      // * looping through all products, calculating the scroll position
      // * from the middle of them to the top and changing the colour based on that
      for (let index = 0; index < this.products.length; index++) {
        idTags = this.products[index].idTag;
        elOffsetTop = this.$refs[idTags][0].offsetTop;

        if (
          event.target.scrollTop + 400 <
          this.$refs[this.products[index].idTag][0].offsetTop
        ) {
          this.currentElId = "other";
          return;
        }
        if (index < this.products.length - 1) {
          if (
            event.target.scrollTop + 400 >= elOffsetTop &&
            event.target.scrollTop + 400 <=
              this.$refs[this.products[index + 1].idTag][0].offsetTop
          ) {
            this.currentElId = this.products[index].idTag;
            return;
          }
        } else {
          this.currentElId = this.products[index].idTag;
          return;
        }
      }
    },
    moveScrollTo(scrollPos) {
      switch (scrollPos) {
        case 1:
          window.location.href = "#tessaSection";
          break;
        case 2:
          window.location.href = "#floatplusSection";
          break;
        case 3:
          window.location.href = "#smartfloorSection";
          break;
        case 4:
          window.location.href = "#monoaSection";
          break;
        case 5:
          window.location.href = "#fysiconSection";
          break;
        default:
          window.location.href = "#inicareSection";
          break;
      }
      this.setUrlBack();
    },
    setUrlBack() {
      setTimeout(() => {
        history.replaceState(null, null, "/#/electronics");
      }, 0);
    },
  },
  components: {
    Footer,
    Carousel,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";

.electronics {
  height: calc(100vh - 115.2px);
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond(laptop) {
    height: calc(100vh - 108px);
  }
  @include respond(small-laptop) {
    height: calc(100vh - 101.73px);
  }
  @include respond(tablet-land) {
    height: calc(100vh - 96px);
  }

  @include respond(tablet-port) {
    height: calc(100vh - 72px);
  }

  @media screen and (min-width: 1920px) {
    width: 1920px;
    margin: 0 auto;
  }

  &__section {
    padding: 240px 15rem 240px 15rem;
    transition: 0.6s background-color;
    scroll-snap-align: center;
    min-height: 90vh;

    @include respond(laptop) {
      padding: 10rem 12rem 10rem 12rem;
    }

    @include respond(small-laptop) {
      padding: 0 10rem 0 10rem;
    }

    @include respond(tablet-land) {
      min-height: 80rem;
      padding: 0 8rem 0 8rem;
    }

    @include respond(tablet-port) {
      min-height: 60rem;
      padding: 0 5rem 0 5rem;
    }

    @include respond(phone-land) {
      min-height: 40rem;
      padding: 2rem 3rem 0 3rem;
    }
  }

  &__section-container {
    position: relative;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: 10rem;

    @include respond(tablet-port) {
      flex-flow: row wrap;
    }
    @include respond(phone-land) {
      padding-bottom: 0.5rem;
      padding-top: 5rem;
      flex-flow: row wrap;
    }

    &--reverse {
      flex-direction: row-reverse;
      justify-content: space-evenly;

      @include respond(tablet-land) {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 5rem;
      }
      @include respond(phone-land) {
        padding-bottom: 3rem;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;

      @include respond(phone-land) {
        justify-content: center;
      }

      &__number {
        & > h1 {
          position: relative;
          font-family: "Raleway";
          font-size: 14rem;
          font-weight: bold;
          line-height: 1.17;
          -webkit-text-stroke: 3px #000000;
          -webkit-text-fill-color: transparent;
          opacity: 0.8;

          @include respond(phone-land) {
            font-size: 10rem;
            margin-bottom: 5rem;
          }

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1rem;
            background-color: black;
            border-radius: 0.2rem;
          }
        }
      }

      &__main {
        & > h1 {
          font-family: "Raleway";
          font-size: 7rem;
          font-weight: bold;
          line-height: 1.18;
          opacity: 0.8;

          @include respond(phone-land) {
            font-size: 4rem;
          }
        }

        & > p {
          margin-top: 3rem;
          font-family: "Open Sans";
          font-size: 2rem;
          line-height: 1.38;
          max-width: 63rem;
          opacity: 0.6;

          @include respond(small-laptop) {
            max-width: 53rem;
          }
          @include respond(tablet-port) {
            font-size: 1.8rem;
            max-width: 90%;
          }
          @include respond(phone-land) {
            font-size: 1.6rem;
            max-width: 90%;
          }
        }
      }
    }

    &__image {
      position: relative;
      margin-right: 5rem;
      width: 80rem;
      display: flex;
      justify-content: center;

      @include respond(small-laptop) {
        margin-top: 15rem;
        margin-right: 5rem;
      }
      @include respond(tablet-port) {
        margin-right: 0rem;
        margin: 0 0 15rem 0;
        width: 100%;
      }
      @include respond(phone-land) {
        margin-top: 1rem;
        margin-right: 0rem;
        width: 100%;
        margin: 0rem;
      }
      & > div:nth-child(1) {
        width: 70rem;
        height: 70rem;
        margin: 0.5rem;
        align-self: flex-end;

        @include respond(small-laptop) {
          width: 60rem;
          height: 60rem;
        }
        @include respond(tablet-port) {
          width: 50rem;
          height: 50rem;
          margin-left: auto;
          margin-right: auto;
        }
        @include respond(phone-land) {
          background-size: 100% auto;
          width: 100% !important;
          //padding-top: 100% !important;
          height: 35rem;
        }
      }
    }
  }

  &__pagination {
    position: fixed;
    top: 50%;
    right: -10rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    transform: rotate(-90deg);

    @include respond(tablet-land) {
      right: -15rem;
    }
    @include respond(phone-land) {
      right: -18rem;
    }
    &__text {
      margin-top: -0.3rem;
      margin-left: 2rem;

      & > p {
        font-family: "Open Sans";
        font-size: 2.3rem;
        line-height: 1.38;
      }
    }

    &__circles {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__bullet {
      cursor: pointer;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      border: 0.2rem solid black;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &--active {
      background-color: black;
    }

    &--active-line {
      position: relative;
      margin-right: 14rem !important;

      &::after {
        content: "";
        position: absolute;
        top: 45%;
        left: 2.6rem;
        width: 11rem;
        height: 0.2rem;
        border-radius: 10rem;
        background-color: black;
      }
    }
  }

  &__hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-gray-2;

    @include respond(tablet-port) {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__img {
      & > div {
        background-image: url("../assets/electronicsImg.png");
        width: 67.7rem;
        height: 50rem;

        @include respond(small-laptop) {
          width: 57.7rem;
          height: 42.6rem;
        }

        @include respond(tablet-land) {
          width: 40rem;
          height: 29.6rem;
        }

        @include respond(tablet-port) {
          width: 50rem;
          height: 37rem;

          margin-top: 5rem;
        }

        @include respond(phone-land) {
          width: 40rem;
          height: 29.5rem;

          margin-top: 5rem;
        }
      }
    }

    &__heading {
      margin-left: 10rem;
      margin-top: -10rem;

      @include respond(tablet-port) {
        margin-left: 0;
        margin-top: 0;
        text-align: center;
      }
      @include respond(phone-land) {
        margin-left: 0;
        margin-top: 10rem;
        margin-bottom: 10rem;
        text-align: center;
      }
      & > h1 {
        font-family: "Raleway";
        font-size: 8rem;
        line-height: 1;
        color: $color-blue-3;

        @include respond(phone-land) {
          font-size: 5rem;
        }

        & > span {
          color: #e73151;
        }
      }
    }

    &__btn-container {
      margin: 35rem -15.5rem 0 auto;
      transform: rotate(-90deg);

      @include respond(tablet-port) {
        margin: 20rem -15.5rem 0 auto;
      }

      @include respond(phone-land) {
        margin: 5rem -10rem 0 auto;
      }
    }
  }

  &__tessaSection {
    background-color: $color-yellow;
  }

  &__floatplus {
    background-color: $color-green;
  }

  &__smartfloor {
    background-color: $color-gray-3;
  }
}

.change_gray {
  background-color: $color-gray-2;
}

.change_yellow {
  background-color: $color-yellow;
}

.change_green {
  background-color: $color-green;
}

.change_gray_2 {
  background-color: $color-gray-3;
}

.change_yellow_2 {
  background-color: $color-yellow;
}

.change_red_2 {
  background-color: $color-red-2;
}

.change_cyan_2 {
  background-color: $color-cyan-2;
}
.change_white_1 {
  background-color: $color-white-1;
}
</style>
