<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__left">
        <div class="footer__left__logo">
          <h1>{{ $t("spinnovLbl") }}</h1>
          <span></span>
        </div>
        <div class="footer__left__menu">
          <ul>
            <li
              class="footer__left__menu__option"
              v-for="option in footerMenuOptions"
              :key="option.id"
            >
              <router-link :to="option.link" replace>{{
                option.name
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="footer__left__follow-us">
          <p>{{ $t("followUsLbl") }}</p>
          <span></span>
        </div>
      </div>
      <div class="footer__right">
        <div class="footer__right__group">
          <h1>{{ $t("ourOfficeLbl") }}</h1>
          <h2>
            {{ $t("addressPartOne") }}<br />
            {{ $t("addressPartTwo") }}
          </h2>
        </div>
        <div class="footer__right__group">
          <h1>{{ $t("getInTouchLbl") }}</h1>
          <h2>
            {{ $t("spinnovEmail") }}<br />
            {{ $t("spinnovNumber") }}
          </h2>
        </div>
        <div class="footer__right__social-media">
          <div
            class="footer__right__social-media__item"
            v-for="option in socialMediaOptions"
            :key="option.id"
          >
            <div
              @click="goToPage(option.link)"
              class="div-background-img"
              :style="{
                backgroundImage:
                  'url(' + require(`../assets/${option.image}`) + ')',
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__btn-container" v-if="$route.path === '/'">
      <a
        v-if="$i18n.locale === 'en'"
        class="btn-primary btn-primary-up"
        href="#hero-section"
        @click="setUrlBack"
      >
        <div></div>
        <p>{{ $t("scrollUpLbl") }}</p>
        <span></span>
      </a>

      <a
        v-if="$i18n.locale === 'nl'"
        class="btn-primary btn-primary-up-nl"
        href="#hero-section"
        @click="setUrlBack"
      >
        <div></div>
        <p>{{ $t("scrollUpLbl") }}</p>
        <span></span>
      </a>
    </div>
    <div class="footer__btn-container" v-if="$route.path === '/products'">
      <a
        class="btn-primary btn-primary-up-nl"
        href="#section-products-hero"
        @click="setUrlBack"
      >
        <div></div>
        <p>{{ $t("scrollUpLbl") }}</p>
        <span></span>
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      socialMediaOptions: [
        {
          id: 0,
          link: "https://www.linkedin.com/company/spinnov/",
          image: "linkedin.svg",
        },
      ],
    };
  },
  computed: {
    footerMenuOptions() {
      return [
        //uncomment when the pages are ready
        {
          id: 0,
          name: this.$t("projectsFootLbl"),
          link: "menu/projects",
        },
        {
          id: 1,
          name: this.$t("skillsFootLbl"),
          link: "skills",
        },
        {
          id: 2,
          name: this.$t("teamFootLbl"),
          link: "team",
        },
        {
          id: 3,
          name: this.$t("contactFootLbl"),
          link: "contact",
        },
        {
          id: 4,
          name: this.$t("jobsFootLbl"),
          link: "jobs",
        },
      ];
    },
  },
  methods: {
    goToPage(link) {
      window.open(
        link,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    setUrlBack() {
      setTimeout(() => {
        history.replaceState(null, null, "/#/");
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";

.footer {
  position: relative;
  background-color: #1d2445;
  color: white;

  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 15rem;
    padding: 10rem 0 5rem 0;

    @include respond(laptop) {
      margin: 0 12rem;
    }
    @include respond(small-laptop) {
      margin: 0 10rem;
    }
    @include respond(tablet-land) {
      margin: 0 8rem;
    }
    @include respond(tablet-port) {
      flex-direction: column;
      margin: 0 3rem;
      padding: 2rem 0 5rem 0;
    }
  }

  &__left {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    width: 50%;

    @include respond(tablet-port) {
      width: 100%;
    }

    &__logo {
      & > h1 {
        font-family: "Raleway";
        font-size: 13.5rem;
        font-weight: 600;
        line-height: 1.22;

        @include respond(tablet-port) {
          font-size: 6rem;
        }
      }

      & > span {
        display: block;
        margin-top: 1rem;
        width: 100%;
        height: 0.2rem;
        background-color: white;
        opacity: 0.4;
      }
    }

    &__menu {
      margin: 5rem 0;

      @include respond(tablet-port) {
        margin: 2rem 0 2rem 0;
      }

      & > ul {
        display: inline-block;
      }

      &__option {
        list-style: none;

        & > * {
          display: block;
          padding-bottom: 2rem;
          font-family: "Raleway";
          font-size: 3rem;
          font-weight: bold;
          line-height: 1.17;
          text-decoration: none;
          color: white;
          opacity: 0.9;
          transition: 0.2s all;

          @include respond(tablet-port) {
            font-size: 1.8rem;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    &__follow-us {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > p {
        font-family: "Open Sans";
        font-size: 2rem;
        line-height: 1.34;

        @include respond(tablet-port) {
          display: none;
        }
      }

      & > span {
        position: relative;
        display: block;
        height: 2px;
        width: 85%;
        background-color: white;
        opacity: 0.4;

        @include respond(tablet-port) {
          display: none;
        }

        &::before {
          content: "";
          position: absolute;
          top: -0.7rem;
          right: -0.2rem;
          width: 2rem;
          height: 0.2rem;
          background-color: white;
          transform: rotate(45deg);
        }

        &::after {
          content: "";
          position: absolute;
          top: 0.7rem;
          right: -0.2rem;
          width: 2rem;
          height: 0.2rem;
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    margin: 15rem 0 0 10rem;
    width: 50%;

    @include respond(tablet-port) {
      margin: 0;
      width: 100%;
    }

    &__group {
      @include respond(tablet-port) {
        margin-bottom: 2rem;
      }
      & > h1 {
        font-family: "Raleway";
        font-size: 4rem;
        font-weight: bold;
        line-height: 1.17;
        opacity: 0.9;
        margin-bottom: 1rem;
        @include respond(tablet-port) {
          font-size: 3rem;
          margin-bottom: 1rem;
        }
      }

      & > h2 {
        font-family: "Open Sans";
        font-size: 2rem;
        font-weight: normal;
        line-height: 1.36;
        opacity: 0.6;
      }
    }

    &__social-media {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: -1.2rem;
      max-width: 35rem;

      &__item {
        cursor: pointer;

        & > div {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }

  &__btn-container {
    position: absolute;
    top: 50%;
    right: 0;
    transform: rotate(90deg);

    @include respond(tablet-port) {
      top: 30%;
      right: -7rem;
    }
  }
}
</style>
