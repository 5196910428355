var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header",class:[
    _vm.getTeamPageColor,
    _vm.colorHeaderBack,
    _vm.getJobsPageColor,
    _vm.setTransition,
  ]},[(!_vm.showBackButton)?_c('div',{class:_vm.$route.path === '/team' ? 'header__logo_black' : 'header__logo',on:{"click":_vm.routeToHome}},[_c('div',{staticClass:"div-background-img",style:(_vm.rotate)}),_c('div',{staticClass:"div-background-img"})]):_c('div',{staticClass:"header__back-btn"},[_c('div',{staticClass:"header__back-btn__innerDiv",on:{"click":_vm.routeToMenu}},[(!_vm.isJobsOrTeamsPage)?_c('div',{staticClass:"div-background-img header__back-btn__innerDivImg"}):_c('div',{staticClass:"div-background-img header__back-btn__innerDivImgWhite"})])]),(!_vm.isJobsOrTeamsPage)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSelectedJob),expression:"!isSelectedJob"}],staticClass:"header__language"},[_c('button',{staticClass:"btn-no-style header__language__btn",class:{ 'header__language__btn--active': _vm.selectedLanguage === 'EN' },on:{"click":function($event){return _vm.changeLanguage('NL')}}},[_vm._v(" NL ")]),_c('button',{staticClass:"btn-no-style header__language__btn",class:{ 'header__language__btn--active': _vm.selectedLanguage === 'NL' },on:{"click":function($event){return _vm.changeLanguage('EN')}}},[_vm._v(" EN ")])]):_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSelectedJob),expression:"!isSelectedJob"}],staticClass:"header__language"},[_c('button',{staticClass:"btn-no-style header__language__btnWhite",class:{
        'header__language__btnWhite--active': _vm.selectedLanguage === 'EN',
      },on:{"click":function($event){return _vm.changeLanguage('NL')}}},[_vm._v(" NL ")]),_c('button',{staticClass:"btn-no-style header__language__btnWhite",class:{
        'header__language__btnWhite--active': _vm.selectedLanguage === 'NL',
      },on:{"click":function($event){return _vm.changeLanguage('EN')}}},[_vm._v(" EN ")])]),(!_vm.isJobsOrTeamsPage)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSelectedJob),expression:"!isSelectedJob"}],staticClass:"header__menu"},[_c('button',{staticClass:"btn-no-style header__menu__menu-btn",class:{ 'header__menu__menu-btn--opened': _vm.menuIsOpened },on:{"click":_vm.toggleMenu}},[_c('span'),_c('span')])]):_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSelectedJob),expression:"!isSelectedJob"}],staticClass:"header__menu"},[_c('button',{staticClass:"btn-no-style header__menu__menu-btnWhite",class:{ 'header__menu__menu-btnWhite--opened': _vm.menuIsOpened },on:{"click":_vm.toggleMenu}},[_c('span'),_c('span')])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelectedJob),expression:"isSelectedJob"}],staticClass:"btn_container"},[_c('button',{staticClass:"applyBtn",on:{"click":function($event){return _vm.goToJobApllicationForm()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("applyNowLblHeader")))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }