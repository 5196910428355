var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"software",staticClass:"software"},[_c('section',{staticClass:"software__section software__hero",attrs:{"id":"section-products-hero"}},[_c('div',{staticClass:"software__hero__heading"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("ourAwesomeLbl"))),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t("softwareAppsLbl")))]),_vm._v(". ")])]),_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWindowWidthDesktop),expression:"isWindowWidthDesktop"}],staticClass:"software__hero__btn-container"},[_c('a',{staticClass:"btn-primary btn-primary-down btn-primary-down--blue",attrs:{"href":"#htmSection"},on:{"click":_vm.setUrlBack}},[_c('div'),_c('p',[_vm._v(_vm._s(_vm.$t("scrollDownLbl")))]),_c('span')])])]),_vm._l((_vm.products),function(product){return _c('section',{key:product.id,ref:product.idTag,refInFor:true,staticClass:"software__section software__container",class:[{ 'software__container--reverse': !product.positionLeft }]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isWindowWidthDesktop),expression:"!isWindowWidthDesktop"}],staticClass:"software__container__content__number"},[_c('h1',[_vm._v(_vm._s(product.number))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isWindowWidthDesktop),expression:"!isWindowWidthDesktop"}],staticClass:"software__container__image"},[_c('Carousel',{class:product.watermarkClass === 'products__container__image--circle'
          ? ''
          : product.watermarkClass,attrs:{"images":product.image}})],1),_c('div',{staticClass:"software__container__content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWindowWidthDesktop),expression:"isWindowWidthDesktop"}],staticClass:"software__container__content__number"},[_c('h1',[_vm._v(_vm._s(product.number))])]),_c('div',{staticClass:"software__container__content__main"},[_c('h1',[_vm._v(_vm._s(product.name))]),_c('p',{staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(_vm.converHtmlToText(product.description))}})]),_c('div',{staticClass:"software__container__content__btn-container"},[_c('button',{staticClass:"btn-primary btn-primary-right",class:`btn-primary-right--${product.button.color}`,on:{"click":function($event){return _vm.goToPage(product.button.link)}}},[_c('span'),_c('p',[_vm._v(_vm._s(product.button.text))]),_c('div')])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWindowWidthDesktop),expression:"isWindowWidthDesktop"}],staticClass:"software__container__image"},[_c('Carousel',{staticClass:"div-background-img",attrs:{"images":product.image}})],1)])}),_c('transition',{attrs:{"name":"fade"}},[(_vm.currentSlide > 0 && _vm.currentSlide < 6)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWindowWidthDesktop),expression:"isWindowWidthDesktop"}],staticClass:"software__pagination"},[_c('div',{staticClass:"software__pagination__text"},[_c('p',[_vm._v(_vm._s(_vm.$t("clickOrScrollLbl")))])]),_c('div',{staticClass:"software__pagination__circles"},[_c('span',{staticClass:"software__pagination__bullet",class:{
          'software__pagination--active-line software__pagination--active':
            _vm.currentSlide === 5,
        },on:{"click":function($event){return _vm.moveScrollTo(5)}}}),_c('span',{staticClass:"software__pagination__bullet",class:{
          'software__pagination--active-line software__pagination--active':
            _vm.currentSlide === 4,
        },on:{"click":function($event){return _vm.moveScrollTo(4)}}}),_c('span',{staticClass:"software__pagination__bullet",class:{
          'software__pagination--active-line software__pagination--active':
            _vm.currentSlide === 3,
        },on:{"click":function($event){return _vm.moveScrollTo(3)}}}),_c('span',{staticClass:"software__pagination__bullet",class:{
          'software__pagination--active-line': _vm.currentSlide === 1,
          'software__pagination--active-line software__pagination--active':
            _vm.currentSlide === 2,
        },on:{"click":function($event){return _vm.moveScrollTo(2)}}}),_c('span',{staticClass:"software__pagination__bullet",class:{ 'software__pagination--active': _vm.currentSlide === 1 },on:{"click":function($event){return _vm.moveScrollTo(1)}}})])]):_vm._e()]),_c('section',{staticClass:"software__footer-section"},[_c('Footer')],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"software__hero__img"},[_c('div',{staticClass:"div-background-img"})])
}]

export { render, staticRenderFns }