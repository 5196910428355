<template>
  <div class="menu">
    <div class="menu__options">
      <ul class="options">
        <li
          :class="{
            'options__option--not-hovered':
              !hoveredMenuOptionsState[option.id] && menuOptionIsHovered,
          }"
          class="options__option"
          @mouseover="onMenuOptionHover(option.id)"
          @mouseleave="onMenuOptionMouseLeave"
          v-for="option in menuOptions"
          :key="option.id"
        >
          <router-link :to="option.link" replace>{{ option.name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="menu__lines">
      <Lines />
    </div>
  </div>
</template>

<script>
import Lines from "../components/Lines";
export default {
  data() {
    return {
      hoveredMenuOptionsState: [false, false, false, false, false],
      menuOptionIsHovered: false,
    };
  },
  computed: {
    menuOptions() {
      return [
        //uncomment when the pages are ready
        {
          id: 0,
          name: this.$t("projectsFootLbl"),
          link: "menu/projects",
        },
        {
          id: 1,
          name: this.$t("skillsFootLbl"),
          link: "skills",
        },
        {
          id: 2,
          name: this.$t("teamFootLbl"),
          link: "team",
        },
        {
          id: 3,
          name: this.$t("contactFootLbl"),
          link: "contact",
        },
        {
          id: 4,
          name: this.$t("jobsFootLbl"),
          link: "jobs",
        },
      ];
    },
  },
  methods: {
    onMenuOptionHover(menuOption) {
      this.menuOptionIsHovered = true;
      this.hoveredMenuOptionsState.forEach((item, index) => {
        if (index === menuOption) {
          this.$set(this.hoveredMenuOptionsState, index, true);
          return;
        }
        this.$set(this.hoveredMenuOptionsState, index, false);
      });
    },
    onMenuOptionMouseLeave() {
      this.hoveredMenuOptionsState = [false, false, false, false, false];
      this.menuOptionIsHovered = false;
    },
  },
  components: {
    Lines,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/_mixins.scss";
.menu {
  height: $viewport-height;
  overflow-y: scroll;
  position: relative;

  &__options {
    margin: 8rem 15rem 0 15rem;
    height: 60rem;
    @include respond(laptop) {
      margin: 8rem 12rem 0 12rem;
      height: 55rem;
    }

    @include respond(small-laptop) {
      margin: 8rem 10rem 0 10rem;
    }

    @include respond(tablet-land) {
      margin: 8rem 8rem 0 8rem;
      height: 40rem;
    }

    @include respond(phone-land) {
      margin: 8rem 3rem 0 3rem;
    }
  }
  &__lines {
    display: block;
    position: relative;
    width: 100%;
  }
}

.options {
  display: inline-block;
  margin-left: 20%;
  height: 57rem; // Check when making responsive

  @include respond(laptop) {
    height: 30rem;
  }

  &__option {
    list-style: none;

    & > * {
      display: block;
      padding-bottom: 4.5rem;
      font-family: "Raleway";
      font-size: 6rem;
      font-weight: bold;
      line-height: 1.17;
      text-decoration: none;
      color: black;
      opacity: 0.8;
      transition: 0.2s all;

      @include respond(laptop) {
        font-size: 5rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
        padding-bottom: 3rem;
      }

      &:hover {
        font-size: 10rem;
        font-weight: 800;

        @include respond(laptop) {
          font-size: 8rem;
        }

        @include respond(tablet-land) {
          font-size: 6rem;
        }
      }
    }

    &--not-hovered {
      & > * {
        font-family: "Raleway";
        font-size: 4rem;
        font-weight: bold;
        line-height: 1.18;
        opacity: 0.3;

        @include respond(laptop) {
          font-size: 3.5rem;
        }
      }
    }
  }
}
</style>
