var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"homeContainer",staticClass:"container"},[_c('section',{staticClass:"carousel",attrs:{"id":"carousel-section"}},[_c('div',{staticClass:"carousel__container"},[_c('swiper',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isWindowWidthDesktop),expression:"!isWindowWidthDesktop"}],ref:"mySwiperMob",staticClass:"swiper",staticStyle:{"margin-top":"10rem"},attrs:{"options":_vm.swiperOptionMob},on:{"slide-change-transition-start":_vm.onSwiperSlideChangeTransitionStart,"click-slide":_vm.onSwiperSlide}},_vm._l((_vm.slides),function(slide,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"carousel__slide--active"},[_c('div',{staticClass:"carousel__slide__image"},[_c('img',{staticClass:"carousel__slide__image--active",staticStyle:{"margin-left":"auto","display":"block","margin-right":"auto"},attrs:{"src":require("../assets/interaction-design-temp.png")}})]),_c('div',{staticClass:"carousel__slide__headline"},[_c('h2',{staticClass:"carousel__slide__text--active"},[_vm._v(" "+_vm._s(slide)+" ")]),_c('span',{staticClass:"carousel__slide__headline--active"})])])])}),1),_c('swiper',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWindowWidthDesktop),expression:"isWindowWidthDesktop"}],ref:"mySwiper",staticClass:"swiper",attrs:{"options":_vm.swiperOption},on:{"slide-change-transition-start":_vm.onSwiperSlideChangeTransitionStart,"click-slide":_vm.onSwiperSlide}},_vm._l((_vm.slides),function(slide,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"carousel__slide",class:{
              'carousel__slide--active':
                index + 2 === _vm.currentSlide || index + 5 === _vm.currentSlide,
            }},[_c('div',{staticClass:"carousel__slide__image"},[_c('img',{class:{
                  'carousel__slide__image--active':
                    index + 2 === _vm.currentSlide || index + 5 === _vm.currentSlide,
                },attrs:{"src":require("../assets/interaction-design-temp.png")}})]),_c('div',{staticClass:"carousel__slide__headline"},[_c('h2',{class:{
                  'carousel__slide__text--active':
                    index + 2 === _vm.currentSlide || index + 5 === _vm.currentSlide,
                }},[_vm._v(" "+_vm._s(slide)+" ")]),_c('span',{class:{
                  'carousel__slide__headline--active':
                    index + 2 === _vm.currentSlide || index + 5 === _vm.currentSlide,
                }})])])])}),1),_c('div',{staticClass:"swiper-pagination__container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWindowWidthDesktop),expression:"isWindowWidthDesktop"}],staticClass:"swiper-pagination__text"},[_c('p',[_vm._v(_vm._s(_vm.$t("clickOrSwipeLbl")))])]),_c('div',{staticClass:"swiper-pagination"},[_c('span',{staticClass:"swiper-pagination__bullet",class:{
              'swiper-pagination--active-line swiper-pagination--active': [
                0,
                3,
              ].includes(_vm.currentSlide),
            },on:{"click":function($event){return _vm.moveSlideTo(1)}}}),_c('span',{staticClass:"swiper-pagination__bullet",class:{
              'swiper-pagination--active-line swiper-pagination--active': [
                1,
                4,
              ].includes(_vm.currentSlide),
              'swiper-pagination--active-line': _vm.currentSlide == 2,
            },on:{"click":function($event){return _vm.moveSlideTo(2)}}}),_c('span',{staticClass:"swiper-pagination__bullet",class:{
              'swiper-pagination--active': _vm.currentSlide === 2,
            },on:{"click":function($event){return _vm.moveSlideTo(0)}}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWindowWidthDesktop),expression:"isWindowWidthDesktop"}],staticClass:"hero__btn-container"},[_c('a',{staticClass:"btn-primary btn-primary-down--skills",attrs:{"href":"#projectsSection"},on:{"click":_vm.setUrlBack}},[_c('div'),_c('p'),_c('span')])])],1)]),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSection('E')),expression:"showSection('E')"}],ref:"projectsSection",attrs:{"id":"projectsSection"}},[_c('div',{staticClass:"software__section software__container software__container--reverse"},[_c('div',{staticClass:"software__container__content"},[_c('div',{staticClass:"software__container__content__main"},[_c('h1',[_vm._v(_vm._s(_vm.$t("aboutElectronicDesign")))]),_c('p',{staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(_vm.converHtmlToText(_vm.$t('aboutElecDesc')))}})])]),_vm._m(0)]),_c('div',{staticClass:"see-all"},[_c('button',{staticClass:"btn-primary",class:_vm.isWindowWidthDesktop
            ? 'btn-primary-right--skills'
            : 'btn-primary-right--center',on:{"click":function($event){return _vm.$router.replace('/electronics')}}},[_c('span'),_c('p',[_vm._v(_vm._s(_vm.$t("checkOutElec")))]),_c('div')])])]),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSection('S')),expression:"showSection('S')"}],ref:"projectsSection",attrs:{"id":"projectsSection"}},[_c('div',{staticClass:"software__section software__container software__container"},[_c('div',{staticClass:"software__container__content"},[_c('div',{staticClass:"software__container__content__main"},[_c('h1',[_vm._v(_vm._s(_vm.$t("aboutSoftwareDesignLbl")))]),_c('p',{staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(this.converHtmlToText(_vm.$t('abotSoftwareDesc')))}})])]),_vm._m(1)]),_c('div',{staticClass:"see-all"},[_c('button',{staticClass:"btn-primary",class:_vm.isWindowWidthDesktop
            ? 'btn-primary-right--skills'
            : 'btn-primary-right--center',on:{"click":function($event){return _vm.$router.replace('/software')}}},[_c('span'),_c('p',[_vm._v(_vm._s(_vm.$t("checkOutSoftwareLbl")))]),_c('div')])])]),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSection('P')),expression:"showSection('P')"}],ref:"projectsSection",attrs:{"id":"projectsSection"}},[_c('div',{staticClass:"software__section software__container software__container"},[_c('div',{staticClass:"software__container__content"},[_c('div',{staticClass:"software__container__content__main"},[_c('h1',[_vm._v(_vm._s(_vm.$t("aboutProductDesignLbl")))]),_c('p',{staticStyle:{"white-space":"break-spaces"},domProps:{"innerHTML":_vm._s(_vm.converHtmlToText(_vm.$t('aboutProductDesignDesc')))}})])]),_vm._m(2)]),_c('div',{staticClass:"see-all"},[_c('button',{staticClass:"btn-primary",class:_vm.isWindowWidthDesktop
            ? 'btn-primary-right--skills'
            : 'btn-primary-right--center',on:{"click":function($event){return _vm.$router.replace('/products')}}},[_c('span'),_c('p',[_vm._v(_vm._s(_vm.$t("checkOutProductsBtn")))]),_c('div')])])]),_c('section',{staticClass:"offersSection",attrs:{"id":"hero-section-3"}},[_c('div',{staticClass:"offersSection__headline_likeUs"},[_c('h1',[_vm._v(_vm._s(_vm.$t("interestedLbl")))])]),_c('div',{staticClass:"offersSection__headline_Team"},[_c('h1',[_vm._v(_vm._s(_vm.$t("writeToUsLbl")))])]),_c('div',{staticClass:"offersSection__see-all"},[_c('button',{staticClass:"button_skills",on:{"click":function($event){return _vm.$router.replace('/contact')}}},[_vm._v(" "+_vm._s(_vm.$t("contactUsLbl"))+" ")])])]),_c('div',{staticClass:"footer-section"},[_c('Footer')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"software__container__image"},[_c('div',{staticClass:"div-background-img"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"software__container__image"},[_c('div',{staticClass:"div-background-img-soft1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"software__container__image",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"div-background-img-products1"})])
}]

export { render, staticRenderFns }