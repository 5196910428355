import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Menu from '../views/Menu.vue'
import MenuProjects from '../views/MenuProjects'
import Products from '../views/Products.vue'
import Electronics from '../views/Electronics.vue'
import Software from '../views/Software.vue'
import Skills from '../views/Skills.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
  },
  {
    path: '/menu/projects',
    name: 'MenuProjects',
    component: MenuProjects,
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
  },
  {
    path: '/electronics',
    name: 'Electronics',
    component: Electronics,
  },
  {
    path: '/software',
    name: 'Software',
    component: Software,
  },
  {
    path: '/skills',
    name: 'Skills',
    component: Skills,
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import('../views/Team.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import('../views/Jobs.vue')
  },
  {
    path: '/jobs/:id',
    name: 'JobsID',
    component: () => import('../views/SelectedJob.vue')
  },
  {
    path: '/jobsApplication/:id',
    name: 'JobsAplicationId',
    component: () => import('../views/jobApllicationPage.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
