<template>
  <div class="container" ref="homeContainer">
    <section class="hero" id="hero-section">
      <div class="hero__headline">
        <h1>
          {{ $t("inovWith") }} <br />
          {{ $t("tech") }}
        </h1>
      </div>
      <div :class="$i18n.locale === 'en' ? 'hero__text' : 'hero__text_nl'">
        <p>
          {{ $t("homeFirstDescription1") }}<br />
          {{ $t("homeFirstDescription2") }} <br />
          {{ $t("homeFirstDescription3") }}<br />
          {{ $t("homeFirstDescription4") }}
        </p>
      </div>
      <div class="hero__btn-container" v-show="isWindowWidthDesktop">
        <a
          class="btn-primary btn-primary-down"
          href="#carousel-section"
          @click="setUrlBack"
        >
          <div></div>
          <p>{{ $t("scrollDownLbl") }}</p>
          <span></span>
        </a>
      </div>
    </section>
    <div class="hero__animation">
      <Lines />
    </div>
    <section class="carousel" id="carousel-section">
      <div class="carousel__headline">
        <h1>{{ $t("whatWeDoLbl") }}</h1>
        <span class="carousel__headline__line"></span>
      </div>
      <div class="carousel__container">
        <swiper
          class="swiper"
          :options="swiperOptionMob"
          ref="mySwiperMob"
          @slide-change-transition-start="onSwiperSlideChangeTransitionStart"
          @click-slide="onSwiperSlide"
          v-show="!isWindowWidthDesktop"
          style="margin-top: 10rem"
        >
          <swiper-slide v-for="(slide, index) of slides" :key="index">
            <div class="carousel__slide--active">
              <div class="carousel__slide__image">
                <img
                  class="carousel__slide__image--active"
                  style="margin-left: auto; display: block; margin-right: auto"
                  src="../assets/interaction-design-temp.png"
                />
              </div>
              <div class="carousel__slide__headline">
                <h2 class="carousel__slide__text--active">
                  {{ slide.title }}
                </h2>
                <span class="carousel__slide__headline--active"></span>
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <swiper
          class="swiper"
          :options="swiperOption"
          ref="mySwiper"
          @slide-change-transition-start="onSwiperSlideChangeTransitionStart"
          @click-slide="onSwiperSlide"
          v-show="isWindowWidthDesktop"
        >
          <swiper-slide v-for="(slide, index) of slides" :key="index">
            <div
              @click="goToPage(slide.link)"
              class="carousel__slide"
              :class="{
                'carousel__slide--active':
                  index + 2 === currentSlide || index + 5 === currentSlide,
              }"
            >
              <div class="carousel__slide__image">
                <img
                  :class="{
                    'carousel__slide__image--active':
                      index + 2 === currentSlide || index + 5 === currentSlide,
                  }"
                  src="../assets/interaction-design-temp.png"
                />
              </div>
              <div class="carousel__slide__headline">
                <h2
                  :class="{
                    'carousel__slide__text--active':
                      index + 2 === currentSlide || index + 5 === currentSlide,
                  }"
                >
                  {{ slide.title }}
                </h2>
                <span
                  :class="{
                    'carousel__slide__headline--active':
                      index + 2 === currentSlide || index + 5 === currentSlide,
                  }"
                ></span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination__container">
          <div class="swiper-pagination__text" v-show="isWindowWidthDesktop">
            <p>{{ $t("clickOrSwipeLbl") }}</p>
          </div>
          <div class="swiper-pagination">
            <span
              @click="moveSlideTo(1)"
              :class="{
                'swiper-pagination--active-line swiper-pagination--active': [
                  0,
                  3,
                ].includes(currentSlide),
              }"
              class="swiper-pagination__bullet"
            ></span>
            <span
              @click="moveSlideTo(2)"
              :class="{
                'swiper-pagination--active-line swiper-pagination--active': [
                  1,
                  4,
                ].includes(currentSlide),
                'swiper-pagination--active-line': currentSlide == 2,
              }"
              class="swiper-pagination__bullet"
            ></span>
            <span
              @click="moveSlideTo(0)"
              :class="{
                'swiper-pagination--active': currentSlide === 2,
              }"
              class="swiper-pagination__bullet"
            ></span>
          </div>
        </div>
      </div>
    </section>
    <section class="projects" ref="projectsSection">
      <div class="projects__headline">
        <h1>{{ $t("ourProjectLbl") }}</h1>
        <span class="projects__headline__line"></span>
      </div>
      <div class="projects__text">
        <p>{{ $t("ourProjectsDescrption") }}</p>
      </div>
      <div v-for="project in projects" :key="project.id" class="projects__element">
        <div class="projects__element__content__numberMob" v-if="!isWindowWidthDesktop">
          <h1>{{ project.number }}</h1>
        </div>
        <div class="projects__element__img">
          <!-- <div
            :style="{
              backgroundImage:
                'url(' +
                require(`../assets/imgs/carousels/${project.image[0].path}`) +
                ')',
            }"
            :class="isExpanded(project.id)"
            class="div-background-img"
          ></div> -->
          <Carousel
            :images="project.image"
            :class="isExpanded(project.id)"
            style="align-self: center; margin-top: 20px"
          />
        </div>
        <div class="projects__element__content">
          <div class="projects__element__content__number" v-if="isWindowWidthDesktop">
            <h1>{{ project.number }}</h1>
          </div>
          <transition name="slide">
            <div
              :class="hideText(project.id)"
              class="projects__element__content__heading"
              v-show="hoveredProjectsState[project.id] || isWindowWidthDesktop"
            >
              <h1>{{ project.name }}</h1>
              <p style="white-space: pre-line" v-html="project.text"></p>
            </div>
          </transition>
          <transition name="slide">
            <div
              :class="hideText(project.id)"
              class="projects__element__content__btn-container"
              v-show="hoveredProjectsState[project.id] || isWindowWidthDesktop"
            >
              <button class="btn-no-style" @click="goToPageExternal(project.link)">
                <p>{{ $t("caseStudyLbl") }}</p>
                <div class="div-background-img"></div>
              </button>
            </div>
          </transition>
        </div>
      </div>
      <div class="projects__see-all" v-if="$i18n.locale === 'en'">
        <button
          class="btn-primary btn-primary-right"
          @click="$router.replace('/menu/projects')"
        >
          <span></span>
          <p>{{ $t("seeAllOurProjectsLbl") }}</p>
          <div></div>
        </button>
      </div>
      <div class="projects__see-all" v-if="$i18n.locale === 'nl'">
        <button
          class="btn-primary btn-primary-right-nl"
          @click="$router.replace('/menu/projects')"
        >
          <span></span>
          <p>{{ $t("seeAllOurProjectsLbl") }}</p>
          <div></div>
        </button>
      </div>
    </section>
    <section class="about">
      <div class="about__headline">
        <h1>{{ $t("aboutUsLbl") }}</h1>
        <span class="about__headline__line"></span>
      </div>
      <div class="about__container">
        <div class="about__content">
          <div class="about__content__text">
            <p>{{ $t("abousUsDescription") }}</p>
          </div>
          <div class="about__content__btn">
            <button
              class="btn-primary btn-primary-right"
              @click="$router.replace('/team')"
            >
              <span></span>
              <p>{{ $t("meetTheTeamBtnLbl") }}</p>
              <div></div>
            </button>
          </div>
        </div>
        <div class="about__image">
          <div class="div-background-img"></div>
        </div>
      </div>
    </section>
    <section class="partners">
      <div class="partners__headline">
        <h1>{{ $t("partnersLbl") }}</h1>
        <span class="partners__headline__line"></span>
      </div>
      <div class="partners__text">
        <p>{{ $t("parthenrsDescriptionLbl") }}</p>
      </div>
      <div class="partners__logos">
        <a
          :href="logo"
          target="_blank"
          v-for="(logo, num) in logolinks"
          :key="num"
          class="partners__column div-background-img"
          :class="`partners__logos--${num + 1}`"
        ></a>
      </div>
    </section>

    <div class="footer-section">
      <Footer />
    </div>
  </div>
</template>

<script>
import Lines from "../components/Lines";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel.vue";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import EventBus from "../event-bus";

import useHtmlToTextConverter from "../mixins/useHtmlToTextConverter";

export default {
  data() {
    const { converHtmlToText } = useHtmlToTextConverter();
    return {
      converHtmlToText,
      inWidth: 0,
      swiperOption: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 500,
          disableOnInteraction: false,
        },
      },
      swiperOptionMob: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        autoplay: {
          delay: 500,
          disableOnInteraction: false,
        },
      },
      resizedSmallerScreen: false,
      currentSlide: 3,
      logolinks: [
        "https://smartgoals.nl/",
        "https://www.bestronics.nl/en/",
        "https://www.koningenhartman.nl/",
        "https://smartfloor.com/",
        "https://www.enexis.nl/meter/meterstanden-doorgeven",
        "https://floatplus.com/nl/",
        "https://www.inicare.com/nura/ ",
        "https://movingintelligence.com/en/",
        "https://opencareconnect.eu/",
        "https://www.tinybots.nl/n",
        "https://competentiematch.woltring.nl/#/signin",
        "https://theacechallenge.com/",
      ],

      hoveredProjectsState: [true, true, true, true, true, true],
    };
  },
  mounted() {
    this.$refs.mySwiper.$swiper.slideTo(this.currentSlide, 1000, false);
    this.changeSlidesTo960(window.matchMedia("(max-width: 960px)"));
    window.addEventListener("resize", this.updateInnerWidth);
    this.updateInnerWidth();
    this.$refs.homeContainer.addEventListener("scroll", this.onScrollHandler);
    if (!this.isWindowWidthDesktop) {
      this.currentSlide = 1;
    }
  },
  computed: {
    slides() {
      return [
        {
          title: this.$t("swiperProductLbl"),
          link: "/products",
        },
        {
          title: this.$t("swiperElectronicLbl"),
          link: "/electronics",
        },
        {
          title: this.$t("swiperSoftwareLbl"),
          link: "/software",
        },
      ];
    },
    projects() {
      return [
        {
          id: 0,
          number: "01",
          name: "SmartGoals",
          image: [
            { id: 0, path: "SmartGoals01_min.png" },
            { id: 1, path: "SmartGoals02_min.png" },
            { id: 2, path: "SmartGoals03_min.png" },
          ],
          text: this.converHtmlToText(this.$t("projectSmartGoalsDesc")),
          link: "https://www.smartgoals.nl",
        },

        {
          id: 1,
          number: "02",
          name: "PROSpike",
          image: [
            { id: 0, path: "ProSpike01_min.png" },
            { id: 1, path: "ProSpike02_min.png" },
          ],
          text: this.converHtmlToText(this.$t("projectPROSpikeDesc")),
          link: "https://prospike.nl/en/",
        },
        {
          id: 2,
          number: "03",
          name: "Woltring",
          image: [
            { id: 0, path: "Woltring01_min.png" },
            { id: 1, path: "Woltring02_min.png" },
            { id: 2, path: "Woltring03_min.png" },
          ],
          text: this.converHtmlToText(this.$t("woltringDesc")),
          link: "https://competentiematch.woltring.nl/",
        },
        {
          id: 3,
          number: "04",
          name: "HTM",
          image: [
            { id: 0, path: "HTM01_min.png" },
            { id: 1, path: "HTM03_min.png" },
          ],
          text: this.converHtmlToText(this.$t("htmSoftDesc")),
          link: "https://www.htm.nl/",
        },
        {
          id: 4,
          number: "05",
          name: "Tessa",
          image: [
            { id: 0, path: "Tessa01_min.png" },
            { id: 1, path: "Tessa02_min.png" },
            { id: 2, path: "Tessa03_min.png" },
          ],
          text: this.converHtmlToText(this.$t("projectTessaDesc")),
          link: "https://www.tinybots.nl/",
        },
        {
          id: 5,
          number: "06",
          name: "FloatPlus",
          image: [
            { id: 0, path: "FloatPlus02-min.png" },
            { id: 1, path: "FloatPlus03-min.png" },
          ],
          text: this.converHtmlToText(this.$t("floatPlusElecDescription")),
          link: "https://floatplus.com/",
        },
      ];
    },
    isWindowWidthDesktop() {
      if (this.inWidth <= 600) {
        return false;
      }
      return true;
    },
  },
  methods: {
    goToPage(link) {
      this.$router.replace(link);
    },
    goToPageExternal(link) {
      window.open(
        link,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    hideText(id) {
      if (this.isWindowWidthDesktop) {
        if (!this.hoveredProjectsState[id]) {
          return "";
        }
      }
      return "not-expandedText";
    },
    isExpanded(id) {
      if (this.isWindowWidthDesktop) {
        if (!this.hoveredProjectsState[id]) {
          return "projects__element__img-expanded";
        }
      }
      return "projects__element__img-expanded";
    },
    onSwiperSlideChangeTransitionStart(swiper) {
      if (swiper.activeIndex === 4) {
        this.currentSlide = 4;
        return;
      } else if (swiper.activeIndex === 5) {
        this.currentSlide = 2;
        return;
      } else if (swiper.activeIndex === 6) {
        this.currentSlide = 3;
        return;
      } else if (swiper.activeIndex === 0) {
        this.currentSlide = 3;
        return;
      } else if (swiper.activeIndex === 1) {
        this.currentSlide = 4;
        return;
      }
      this.currentSlide = swiper.activeIndex;
    },
    onSwiperSlide(index, realIndex) {
      if (realIndex === 0) {
        this.currentSlide = 2;
      } else {
        this.currentSlide = realIndex + 2;
      }
      this.$refs.mySwiper.$swiper.slideTo(this.currentSlide, 1000, false);
    },
    moveSlideTo(slide) {
      this.currentSlide = slide + 2;
      if (this.isWindowWidthDesktop) {
        this.$refs.mySwiper.$swiper.slideTo(this.currentSlide, 1000, false);
      } else {
        this.$refs.mySwiperMob.$swiper.slideTo(this.currentSlide, 1000, false);
      }
    },

    onProjectHover(value) {
      if (this.isWindowWidthDesktop) {
        this.hoveredProjectsState.forEach((element, index) => {
          this.$set(this.hoveredProjectsState, index, false);
          if (index === value) {
            if (!this.hoveredProjectsState[index]) {
              this.$set(this.hoveredProjectsState, index, true);
            }
          }
        });
      }
    },
    onProjectMouseLeave() {
      if (this.isWindowWidthDesktop) {
        this.hoveredProjectsState = [false, false, false, false, false, false];
      }
    },
    setUrlBack() {
      setTimeout(() => {
        history.replaceState(null, null, "/#/");
      }, 50);
    },
    changeSlidesTo960(x) {
      if (!this.resizedSmallerScreen && x.matches) {
        this.resizedSmallerScreen = true;
      }
    },
    updateInnerWidth() {
      this.inWidth = window.innerWidth;
      if (!this.isWindowWidthDesktop) {
        this.hoveredProjectsState.forEach((element, index) => {
          this.$set(this.hoveredProjectsState, index, true);
        });
      } else {
        this.hoveredProjectsState.forEach((element, index) => {
          this.$set(this.hoveredProjectsState, index, false);
        });
      }
    },
    onScrollHandler(event) {
      if (this.resizedSmallerScreen) {
        window.scrollTo(0, 1);
      }
      let x = (event.target.scrollTop / 10) % Math.PI;
      EventBus.$emit("pageScrolled", { theta: x });
    },
  },
  components: {
    Lines,
    Footer,
    Swiper,
    SwiperSlide,
    Carousel,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";
@import "../scss/_mixins.scss";

.container {
  height: calc(100vh - 115.2px);
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond(laptop) {
    height: calc(100vh - 108px);
  }
  @include respond(small-laptop) {
    height: calc(100vh - 101.73px);
  }
  @include respond(tablet-land) {
    height: calc(100vh - 96px);
  }

  @include respond(tablet-port) {
    height: calc(100vh - 72px);
  }

  @media screen and (min-width: 1920px) {
    width: 1920px;
    margin: 0 auto;
  } 
}

.hero {
  display: flex;
  align-items: flex-end;
  margin: 17rem 15rem 0 15rem;
  scroll-snap-align: center;

  @include respond(laptop) {
    margin: 15rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 8rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 8rem 8rem 0 8rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    margin: 8rem 3rem 0 3rem;
  }

  &__headline {
    & > h1 {
      font-family: "Raleway";
      font-size: 14rem;
      font-weight: bold;
      opacity: 0.9;
      line-height: 1.04;

      @include respond(desktop) {
        font-size: 13.5rem;
      }

      @include respond(laptop) {
        font-size: 11.3rem;
      }

      @include respond(small-laptop) {
        font-size: 11rem;
      }

      @include respond(tablet-land) {
        font-size: 9.4rem;
      }

      @include respond(phone-land) {
        max-width: 35rem;
        font-size: 6.4rem;
      }
    }
  }
  &__text_nl {
    margin-bottom: -5rem;
    margin-left: 10rem;

    @include respond(laptop) {
      margin-left: 10rem;
    }

    @include respond(small-laptop) {
      margin-left: 10rem;
    }

    @include respond(tablet-land) {
      margin-left: 10rem;
      margin-left: 0;
    }

    @include respond(tablet-port) {
      margin-left: 0;
      margin-top: 3rem;
    }
    @include respond(phone-land) {
      margin-top: 2rem;
    }

    & > p {
      opacity: 0.6;
      font-family: "Open Sans";
      font-size: 2.4rem;
      line-height: 1.42;

      @include respond(desktop) {
        font-size: 2.3rem;
      }

      @include respond(laptop) {
        font-size: 2.1rem;
      }

      @include respond(small-laptop) {
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        font-size: 1.8rem;
      }

      @include respond(phone-land) {
        font-size: 1.6rem;
      }
    }
  }
  &__text {
    margin-bottom: -5rem;
    margin-left: 10rem;

    @include respond(laptop) {
      margin-left: 10rem;
    }

    @include respond(small-laptop) {
      margin-left: 5rem;
    }

    @include respond(tablet-land) {
      margin-left: 5rem;
    }

    @include respond(tablet-port) {
      margin-left: 0;
      margin-top: 3rem;
    }
    @include respond(phone-land) {
      margin-top: 2rem;
    }

    & > p {
      opacity: 0.6;
      font-family: "Open Sans";
      font-size: 2.4rem;
      line-height: 1.42;

      @include respond(desktop) {
        font-size: 2.3rem;
      }

      @include respond(laptop) {
        font-size: 2.1rem;
      }

      @include respond(small-laptop) {
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        font-size: 1.8rem;
      }

      @include respond(phone-land) {
        font-size: 1.6rem;
      }
    }
  }

  &__btn-container {
    margin-left: auto;
    margin-right: -15.5rem;
    transform: rotate(-90deg);

    @include respond(laptop) {
      margin-right: -12.5;
    }

    @include respond(phone-land) {
      margin-right: -10rem;
      margin-top: 15rem;
    }
  }

  &__animation {
    margin-top: 15rem;

    @include respond(desktop) {
      margin-top: 20rem;
    }

    @include respond(laptop) {
      margin-top: 17rem;
    }

    @include respond(small-laptop) {
      margin-top: 12rem;
    }

    @include respond(tablet-land) {
      margin-top: 11rem;
    }

    @include respond(phone-land) {
    }
  }
}

.carousel {
  margin: 20rem 15rem 0 15rem;
  scroll-snap-align: center;

  @include respond(laptop) {
    margin: 20rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 20rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 20rem 8rem 0 8rem;
  }
  @include respond(tablet-port) {
    margin: 20rem 5rem 0 5rem;
  }
  @include respond(phone-land) {
    margin: 20rem 3rem 0 3rem;
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      font-family: "Raleway";
      font-size: 10rem;
      font-weight: bold;
      line-height: 1.04;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 9.5rem;
      }

      @include respond(laptop) {
        font-size: 8.3rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(tablet-port) {
        min-width: 43rem;
      }

      @include respond(phone-land) {
        min-width: 35rem;
        font-size: 5.5rem;
      }
    }

    &__line {
      margin-top: 1.5rem;
      width: 57%;
      height: 0.4rem;
      border-radius: 1rem;
      background-color: black;

      @include respond(tablet-port) {
        width: 47%;
      }
      @include respond(phone-land) {
        display: none;
      }
    }
  }

  &__container {
    margin-top: 15rem;

    @include respond(desktop) {
      margin-top: 14.5rem;
    }

    @include respond(laptop) {
      margin-top: 13.5rem;
    }

    @include respond(small-laptop) {
      margin-top: 12.5rem;
    }

    @include respond(tablet-land) {
      margin-top: 11.5rem;
    }

    @include respond(tablet-port) {
      margin-top: 10.5rem;
    }

    @include respond(phone-land) {
      margin-top: 3rem;
    }
  }

  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 54rem;
    height: 34rem;
    opacity: 0.4;

    @include respond(small-laptop) {
      width: 44rem;
    }

    @include respond(tablet-land) {
      width: 40rem;
    }
    @include respond(tablet-port) {
      width: 20rem;
    }
    @include respond(phone-land) {
      width: 100%;
    }
    @include respond(phone) {
      width: 100%;
    }

    &--active {
      opacity: 1;
      cursor: pointer;
    }

    &__image {
      & > img {
        width: 10rem;
        height: 8rem;
        transform: rotate(90deg);
        transition: all 0.6s !important;

        @include respond(tablet-port) {
          width: 8rem;
          height: 6rem;
        }
        @include respond(phone) {
          width: 5rem;
          height: 4rem;
        }
      }

      &--active {
        width: 16rem !important;
        height: 12rem !important;
        transform: rotate(0deg) !important;
        transition: all 0.6s !important;

        @include respond(tablet-port) {
          width: 16rem !important;
          height: 12rem !important;
        }
        // @include respond(phone-land) {
        //     width: 8rem !important;
        //     height: 6rem !important;
        // }
        @include respond(phone) {
          width: 16rem !important;
          height: 12rem !important;
        }
      }
    }

    &__headline {
      display: flex;
      justify-content: center;
      flex-direction: column;

      & > h2 {
        margin-top: 7rem;
        font-family: "Raleway";
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 1.17;

        @include respond(tablet-port) {
          font-size: 1.8rem;
          text-align: center;
          margin-top: 3rem;
        }
        @include respond(phone-land) {
          font-size: 1.6rem;
          text-align: center;
          margin-top: 2rem;
        }
      }

      &--active {
        margin-top: 1rem;
        width: 5rem;
        height: 0.7rem;
        border-radius: 2rem;
        background-color: black;
        display: none;

        @include respond(tablet-port) {
          display: none;
        }
      }
    }

    &__text--active {
      font-size: 3.8rem !important;

      @include respond(tablet-port) {
        font-size: 3rem !important;
      }

      @include respond(phone-land) {
        font-size: 1.8rem !important;
        text-align: center;
      }
    }
  }
}

.swiper-pagination {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8rem;

    @include respond(tablet-port) {
      justify-content: flex-end;
    }
    @include respond(phone-land) {
      justify-content: center;
    }
    @include respond(phone) {
      justify-content: center;
    }
  }

  &__text {
    margin-top: -0.3rem;
    margin-right: 5rem;

    @include respond(tablet-port) {
      margin-right: 3rem;
    }

    @include respond(phone-land) {
      margin-right: 2rem;
    }

    & > p {
      font-family: "Open Sans";
      font-size: 2.2rem;
      line-height: 1.38;

      @include respond(desktop) {
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        font-size: 1.8rem;
      }

      @include respond(phone) {
        font-size: 1.6rem;
      }
    }
  }

  &__bullet {
    cursor: pointer;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.2rem solid black;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &--active {
    background-color: black;
  }

  &--active-line {
    position: relative;
    margin-right: 14rem !important;

    &::after {
      content: "";
      position: absolute;
      top: 45%;
      left: 2.6rem;
      width: 11rem;
      height: 0.2rem;
      border-radius: 10rem;
      background-color: black;
    }
  }
}

.projects {
  margin-top: 20rem;

  @include respond(tablet-port) {
    margin-top: 10rem;
  }

  @include respond(phone-land) {
    margin-top: 10rem;
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $margin-sides;
    scroll-snap-align: start;

    @include respond(laptop) {
      margin: 0 12rem;
    }

    @include respond(small-laptop) {
      margin: 0 10rem;
    }

    @include respond(tablet-land) {
      margin: 0 8rem;
    }

    @include respond(tablet-port) {
      margin: 0 5rem;
    }

    @include respond(phone-land) {
      margin: 0 3rem;
    }

    & > h1 {
      font-family: "Raleway";
      font-size: 10rem;
      font-weight: bold;
      line-height: 1.04;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 9.5rem;
      }

      @include respond(laptop) {
        font-size: 8.3rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(tablet-port) {
        min-width: 43rem;
        font-size: 6rem;
      }

      @include respond(phone) {
        min-width: 35rem;
        font-size: 5.5rem;
      }
    }

    &__line {
      margin-top: 1.5rem;
      width: 57%;
      height: 0.4rem;
      border-radius: 1rem;
      background-color: black;

      @include respond(tablet-port) {
        width: 47%;
      }
      @include respond(phone-land) {
        display: none;
      }
    }
  }

  &__text {
    margin: 10rem 15rem;

    @include respond(laptop) {
      margin: 10rem 12rem;
    }

    @include respond(small-laptop) {
      margin: 10rem 10rem;
    }

    @include respond(tablet-land) {
      margin: 10rem 8rem;
    }
    @include respond(tablet-port) {
      margin: 5rem;
    }
    @include respond(phone) {
      margin: 3rem 3rem 5rem 3rem;
    }

    & > p {
      font-family: "Open Sans";
      font-size: 2.2rem;
      width: 60rem;
      line-height: 1.38;
      opacity: 0.6;

      @include respond(desktop) {
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        font-size: 1.8rem;
      }

      @include respond(phone-land) {
        max-width: 35rem;
        font-size: 1.6rem;
      }
    }
  }

  &__element {
    cursor: default;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 23rem;
    scroll-snap-align: center;
    max-width: 250rem;

    @include respond(tablet-port) {
      margin-bottom: 0rem;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      scroll-snap-align: end !important;
    }

    &__img {
      display: flex;
      justify-content: center;
      @include respond(tablet-port) {
        width: 100%;
      }

      & > div {
        width: 95rem;
        // height: 90rem;
        transition: 0.5s ease-in-out;
        object-fit: cover;

        @include respond(laptop) {
          width: 92rem;
        }

        @include respond(small-laptop) {
          width: 85rem;
        }

        @include respond(tablet-land) {
          width: 85rem;
        }

        @include respond(tablet-port) {
          width: 100%;
          height: 40rem;
        }

        @include respond(phone) {
          width: 100%;
          height: 33rem;
        }
      }
      // TODO : Change when Sjef sees it
      &--not-expanded {
        width: 95rem !important;
        display: flex;
        justify-content: center;
        margin-left: 25rem;
        margin-right: 35rem;

        @include respond(laptop) {
          // margin-left: 22.5rem;
          // margin-right: 22.5rem;
          width: 90rem !important;
        }

        @include respond(small-laptop) {
          width: 85rem !important;
          // margin-left: 20rem;
          // margin-right: 20rem;
        }

        @include respond(tablet-land) {
          width: 85rem !important;
          // width: 75rem !important;
          // margin-left: 7.5rem;
          // margin-right: 7.5rem;
        }

        @include respond(tablet-port) {
          width: 85rem !important;
        }

        @include respond(phone) {
          width: 55rem;
        }
      }

      &-expanded {
        width: 95rem !important;
        display: flex;
        justify-content: center;

        @include respond(laptop) {
          // margin-left: 22.5rem;
          // margin-right: 22.5rem;
          width: 90rem !important;
        }

        @include respond(small-laptop) {
          width: 85rem !important;
          // margin-left: 20rem;
          // margin-right: 20rem;
        }

        @include respond(tablet-land) {
          width: 65rem !important;
          // margin-left: 7.5rem;
          // margin-right: 7.5rem;
        }

        @include respond(tablet-port) {
          width: 85rem !important;
        }
      }
    }

    &__content {
      display: flex;
      width: 70rem;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin: $margin-sides;

      @include respond(laptop) {
        margin: 0 12rem;
      }

      @include respond(small-laptop) {
        margin: 0;
        padding: 0 12px 0 10px;
        width: 65rem;
      }

      @include respond(tablet-land) {
        margin: 0;
        padding: 0 12px 0 10px;
        width: 55rem;
      }
      @include respond(tablet-port) {
        margin: 0;
        padding: 0 10px 0 10px;
        width: 50rem;
      }
      @include respond(phone) {
        margin: 0;
        padding: 0 10px 0 10px;
        width: 40rem;
      }
      &__numberMob {
        @include respond(laptop) {
          margin: 0 12rem;
        }

        @include respond(small-laptop) {
          margin: 0 10rem;
        }

        @include respond(tablet-land) {
          margin: 0 8rem;
        }
        @include respond(tablet-port) {
          margin: 0 5rem;
        }
        @include respond(phone-land) {
          margin: 0 3rem;
        }
        & > h1 {
          position: relative;
          font-family: "Raleway";
          font-size: 14rem;
          font-weight: bold;
          line-height: 1.17;
          -webkit-text-stroke: 3px #000000;
          -webkit-text-fill-color: white;
          opacity: 0.8;

          @include respond(tablet-port) {
            font-size: 12rem;
          }
          @include respond(phone-land) {
            font-size: 10rem;
          }

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1rem;
            background-color: black;
            border-radius: 0.2rem;
          }
        }
      }
      &__number {
        & > h1 {
          position: relative;
          font-family: "Raleway";
          font-size: 14rem;
          font-weight: bold;
          line-height: 1.17;
          -webkit-text-stroke: 3px #000000;
          -webkit-text-fill-color: white;
          opacity: 0.8;

          @include respond(tablet-port) {
            font-size: 12rem;
          }
          @include respond(phone-land) {
            font-size: 10rem;
          }

          &::after {
            content: "";
            position: absolute;
            top: 95%;
            left: 0;
            width: 100%;
            height: 1rem;
            background-color: black;
            border-radius: 0.2rem;
          }
        }
      }

      &__heading {
        & > h1 {
          font-family: "Raleway";
          font-size: 7rem;
          font-weight: bold;
          line-height: 1.18;
          opacity: 0.8;

          @include respond(tablet-port) {
            margin-top: 3rem;
            font-size: 5.5rem;
          }
          @include respond(phone-land) {
            margin-top: 2rem;
            font-size: 4.5rem;
          }
        }

        & > p {
          margin-top: 3rem;
          font-family: "Open Sans";
          font-size: 2rem;
          line-height: 1.38;
          max-width: 63rem;
          opacity: 0.6;

          @include respond(tablet-port) {
            margin: 3rem 0;
            font-size: 1.8rem;
          }
          @include respond(phone-land) {
            margin: 2rem 0;
            font-size: 1.6rem;
          }
        }
      }

      &__btn-container {
        @include respond(tablet-port) {
          margin-bottom: 5rem;
        }
        @include respond(phone-land) {
          margin-bottom: 3rem;
        }
        & > button {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            animation-name: bounce;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
          }

          & > p {
            margin-right: 2rem;
            font-family: "Open Sans";
            font-size: 2rem;
            line-height: 1.38;
          }

          & > div {
            width: 4rem;
            height: 3rem;
            background-image: url("../assets/right.png");
          }
        }
      }
    }
  }

  &__see-all {
    display: flex;
    justify-content: flex-end;
    margin: 10rem 15rem 0 0;
    scroll-snap-align: center;

    @include respond(tablet-port) {
      margin: 8rem 5rem 0 5rem;
      width: 60rem;
      justify-content: flex-start;
    }
    @include respond(phone-land) {
      margin: 6rem 3rem 0 3rem;
      width: 50rem;
      justify-content: flex-start;
    }
    @include respond(phone) {
      margin: 6rem 3rem 0 3rem;
      width: 30rem;
      justify-content: flex-start;
    }
  }
}

.about {
  margin: 20rem 15rem 0 15rem;
  scroll-snap-align: end;

  @include respond(laptop) {
    margin: 20rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 20rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 20rem 8rem 0 8rem;
  }
  @include respond(tablet-port) {
    margin: 8rem 8rem 0 5rem;
  }
  @include respond(phone-land) {
    margin: 8rem 8rem 0 3rem;
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      font-family: "Raleway";
      font-size: 10rem;
      font-weight: bold;
      line-height: 1.04;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 9.5rem;
      }

      @include respond(laptop) {
        font-size: 8.3rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(tablet-port) {
        min-width: 43rem;
        font-size: 6rem;
      }

      @include respond(phone) {
        min-width: 35rem;
        font-size: 5.5rem;
      }
    }

    &__line {
      margin-top: 1.5rem;
      width: 68%;
      height: 0.4rem;
      border-radius: 1rem;
      background-color: black;

      @include respond(tablet-port) {
        width: 47%;
      }
      @include respond(phone-land) {
        display: none;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 7rem;

    @include respond(tablet-port) {
      flex-direction: column-reverse;
      margin-top: 5rem;
    }
    @include respond(phone-land) {
      margin-top: 3rem;
    }
  }

  &__content {
    &__text {
      & > p {
        font-family: "Open Sans";
        font-size: 1.8rem;
        width: 60rem;
        line-height: 1.38;
        opacity: 0.6;

        @include respond(laptop) {
          width: 58rem;
        }

        @include respond(small-laptop) {
          width: 55rem;
        }

        @include respond(tablet-land) {
          width: 47rem;
        }
        @include respond(tablet-port) {
          width: 40rem;
          margin-top: 3rem;
          font-size: 1.7rem;
        }
        @include respond(phone-land) {
          width: 35rem;
          margin-top: 2rem;
          font-size: 1.6rem;
        }
      }
    }

    &__btn {
      margin-top: 10rem;

      & > btn {
        @include respond(tablet-port) {
          max-width: 20rem;
        }
      }
      @include respond(tablet-port) {
        margin-top: 0;
      }
    }
  }

  &__image {
    & > div {
      width: 100rem;
      height: 60rem;
      background-image: url("../assets/arch.png");

      @include respond(laptop) {
        width: 90rem;
      }

      @include respond(small-laptop) {
        width: 85rem;
        height: 55rem;
      }

      @include respond(tablet-land) {
        width: 75rem;
        height: 50rem;
      }
      @include respond(phone-land) {
        width: 55rem;
        height: 35rem;
      }
      @include respond(phone) {
        width: 40rem;
        height: 25rem;
      }
    }
  }
}

.partners {
  margin: 20rem 15rem 0 15rem;
  scroll-snap-align: start;

  @include respond(laptop) {
    margin: 20rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 20rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 20rem 8rem 0 8rem;
  }
  @include respond(tablet-port) {
    margin: 20rem 5rem 0 5rem;
  }
  @include respond(phone-land) {
    margin: 10rem 3rem 0 3rem;
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      font-family: "Raleway";
      font-size: 10rem;
      font-weight: bold;
      line-height: 1.04;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 9.5rem;
      }

      @include respond(laptop) {
        font-size: 8.3rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(tablet-port) {
        min-width: 43rem;
        font-size: 6rem;
      }

      @include respond(phone-land) {
        min-width: 35rem;
        font-size: 5.5rem;
      }
    }

    &__line {
      margin-top: 1.5rem;
      width: 69%;
      height: 0.4rem;
      border-radius: 1rem;
      background-color: black;

      @include respond(tablet-port) {
        width: 47%;
      }
      @include respond(phone-land) {
        display: none;
      }
    }
  }

  &__text {
    margin: 5rem 0;

    @include respond(tablet-port) {
      margin: 5rem 0;
    }
    @include respond(phone-land) {
      margin: 3rem 0;
    }

    & > p {
      width: 60rem;
      font-family: "Open Sans";
      font-size: 1.8rem;
      line-height: 1.38;
      opacity: 0.6;

      @include respond(tablet-port) {
        width: 35rem;
        font-size: 1.6rem;
      }
    }
  }

  &__logos {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin: 20px;

    @include respond(tablet-land) {
      flex-wrap: wrap;
    }

    @include respond(tablet-port) {
      align-items: center;
      margin: 0rem 0rem 3rem 0rem;
    }

    &--1 {
      grid-row: 1;
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/smartgoals.png");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-right: 0;
        margin-bottom: 3rem;
        // width: 40rem;
        // height: 4rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &--2 {
      grid-row: 1;
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/bestronics.png");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 3rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--3 {
      grid-row: 1;
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/KandH.png");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 3rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--4 {
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/smartfloor.png");

      @include respond(tablet-land) {
        width: 45rem;

        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        width: 45rem;

        margin-bottom: 3rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--5 {
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/enexis.png");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 3rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--6 {
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/floatplus.png");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 3rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--7 {
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/inicare.png");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 3rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--8 {
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/movingintelligence.png");

      @include respond(tablet-land) {
        // margin-right: 3rem;
      }
      @include respond(tablet-port) {
        // margin-bottom: 3rem;
        width: 20rem;
        height: 5rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--9 {
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/opencare.png");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 3rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--10 {
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/tinybots.png");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 3rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--11 {
      width: 22rem;
      height: 12rem;
      background-image: url("../assets/partners/woltring.png");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 5rem;
      }
      @include respond(phone-land) {
        width: 22rem;
        height: 12rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--12 {
      width: 22rem;
      height: 10rem;
      background-image: url("../assets/partners/AceChallenge.png");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 5rem;
      }
      @include respond(phone) {
        width: 22rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
.not-expandedText {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  width: 100%;

  @include respond(laptop) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  @include respond(small-laptop) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  @include respond(tablet-land) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  @include respond(tablet-port) {
    margin: auto;
  }
}
.footer-section {
  scroll-snap-align: center;
}
</style>
