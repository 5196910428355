var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-projects-wrapper"},[_c('div',{staticClass:"menu-projects"},[_c('div',{staticClass:"menu-projects__heading",class:{ 'menu-projects__heading--hovered': _vm.menuProjectIsHovered }},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("someLbl"))+" "),_c('span',{staticClass:"menu-projects__heading--blue"},[_vm._v(_vm._s(_vm.$t("projectsLbl")))]),_vm._v(" "+_vm._s(_vm.$t("weLbl"))+" "),_c('br'),_vm._v(_vm._s(_vm.$t("haveDoneLbl"))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWindowWidthDesktop),expression:"isWindowWidthDesktop"}],staticClass:"menu-projects__content"},_vm._l((_vm.projects),function(project){return _c('div',{key:project.id,staticClass:"menu-projects__cart",on:{"click":function($event){return _vm.routeToProject(project.id)},"mouseover":function($event){return _vm.onMenuProjectHover(project.id)},"mouseleave":_vm.onMenuProjectMouseLeave}},[_c('h2',{staticClass:"menu-projects__cart__heading",class:{
            'menu-projects__cart__heading--not-hovered':
              !_vm.hoveredProjectsState[project.id] && _vm.menuProjectIsHovered,
          }},[_vm._v(" "+_vm._s(project.title)+" ")]),_c('p',{staticClass:"menu-projects__cart__text",class:{
            'menu-projects__cart__text--not-hovered':
              !_vm.hoveredProjectsState[project.id] && _vm.menuProjectIsHovered,
          }},[_vm._v(" "+_vm._s(project.text)+" ")]),_c('transition',{attrs:{"name":"slide"}},[(_vm.hoveredProjectsState[project.id])?_c('button',{staticClass:"btn-primary btn-primary-right--small",on:{"click":function($event){return _vm.routeToProject(project.id)}}},[_c('span'),_c('p',[_vm._v(_vm._s(_vm.$t("seeMoreLbl")))]),_c('div')]):_vm._e()])],1)}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isWindowWidthDesktop),expression:"!isWindowWidthDesktop"}]},_vm._l((_vm.projects),function(project){return _c('div',{key:project.id},[_c('button',{staticClass:"btn-primary btn-primary-right-menu",on:{"click":function($event){return _vm.routeToProject(project.id)}}},[_c('p',[_vm._v(_vm._s(project.title))]),_c('div')])])}),0)]),_c('div',{staticClass:"menu-projects__lines"},[_c('Lines')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }