<template>
  <div class="menu-projects-wrapper">
    <div class="menu-projects">
      <div
        class="menu-projects__heading"
        :class="{ 'menu-projects__heading--hovered': menuProjectIsHovered }"
      >
        <h1>
          {{ $t("someLbl") }}
          <span class="menu-projects__heading--blue">{{
            $t("projectsLbl")
          }}</span>
          {{ $t("weLbl") }} <br />{{ $t("haveDoneLbl") }}
        </h1>
      </div>
      <div class="menu-projects__content" v-show="isWindowWidthDesktop">
        <div
          class="menu-projects__cart"
          v-for="project in projects"
          :key="project.id"
          @click="routeToProject(project.id)"
          @mouseover="onMenuProjectHover(project.id)"
          @mouseleave="onMenuProjectMouseLeave"
        >
          <h2
            :class="{
              'menu-projects__cart__heading--not-hovered':
                !hoveredProjectsState[project.id] && menuProjectIsHovered,
            }"
            class="menu-projects__cart__heading"
          >
            {{ project.title }}
          </h2>
          <p
            :class="{
              'menu-projects__cart__text--not-hovered':
                !hoveredProjectsState[project.id] && menuProjectIsHovered,
            }"
            class="menu-projects__cart__text"
          >
            {{ project.text }}
          </p>
          <transition name="slide">
            <button
              @click="routeToProject(project.id)"
              class="btn-primary btn-primary-right--small"
              v-if="hoveredProjectsState[project.id]"
            >
              <span></span>
              <p>{{ $t("seeMoreLbl") }}</p>
              <div></div>
            </button>
          </transition>
        </div>
      </div>
      <div v-show="!isWindowWidthDesktop">
        <div v-for="project in projects" :key="project.id">
          <button
            @click="routeToProject(project.id)"
            class="btn-primary btn-primary-right-menu"
          >
            <p>{{ project.title }}</p>
            <div></div>
          </button>
        </div>
      </div>
    </div>
    <div class="menu-projects__lines">
      <Lines />
    </div>
  </div>
</template>

<script>
import Lines from "../components/Lines";
export default {
  data() {
    return {
      inWidth: 0,

      hoveredProjectsState: [false, false, false],
      menuProjectIsHovered: false,
    };
  },
  computed: {
    projects() {
      return [
        {
          id: 0,
          title: this.$t("productMenuLbl"),
          text: this.$t("productTextDesc"),
        },
        {
          id: 1,
          title: this.$t("softwareMenuLbl"),
          text: this.$t("softwareTextDesc"),
        },
        {
          id: 2,
          title: this.$t("electronicMenuLbl"),
          text: this.$t("electronicTextDesc"),
        },
      ];
    },
    isWindowWidthDesktop() {
      if (this.inWidth <= 960) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateInnerWidth);
    this.updateInnerWidth();
  },
  methods: {
    updateInnerWidth() {
      this.inWidth = window.innerWidth;
    },
    onMenuProjectHover(menuItem) {
      this.menuProjectIsHovered = true;
      this.hoveredProjectsState.forEach((item, index) => {
        if (index === menuItem) {
          this.$set(this.hoveredProjectsState, index, true);
          return;
        }
        this.$set(this.hoveredProjectsState, index, false);
      });
    },
    onMenuProjectMouseLeave() {
      this.hoveredProjectsState = [false, false, false, false, false];
      this.menuProjectIsHovered = false;
    },
    routeToProject(index) {
      if (index === 2) {
        this.$router.replace("/electronics");
        return;
      }
      if (index === 1) {
        this.$router.replace("/software");
        return;
      }
      this.$router.replace("/products");
    },
  },
  components: {
    Lines,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
.menu-projects-wrapper {
  height: $viewport-height;
  overflow-y: scroll;
}

.menu-projects {
  margin: 8rem 25rem 0 25rem;
  height: 57rem; // Check when making responsive

  @include respond(laptop) {
    margin: 8rem 20rem 0 20rem;
  }

  @include respond(small-laptop) {
    margin: 8rem 15rem 0 15rem;
  }

  @include respond(tablet-land) {
    margin: 8rem 10rem 0 10rem;
  }

  @include respond(phone-land) {
    margin: 5rem 3rem 0 3rem;
  }

  &__heading {
    cursor: default;
    margin-bottom: 10rem;

    @include respond(tablet-port) {
      margin-bottom: 5rem;
    }

    &--hovered {
      margin-bottom: 5rem !important;
      opacity: 0.4;

      & > h1 {
        font-size: 4rem !important;
      }
    }

    & > h1 {
      font-family: "Raleway";
      font-size: 8rem;
      font-weight: bold;
      line-height: 1;
      text-align: left;
      color: $color-gray;
      transition: 0.2s all;

      @include respond(tablet-port) {
        font-size: 7rem;
      }
      @include respond(phone-land) {
        font-size: 5rem;
      }
    }

    &--blue {
      color: $color-blue;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(tablet-port) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__cart {
    cursor: pointer;

    @include respond(tablet-port) {
      margin-bottom: 5rem;
    }
    @include respond(phone-land) {
      margin-bottom: 3rem;
    }

    &:hover &__heading {
      font-size: 8rem;
      @include respond(tablet-port) {
        font-size: 6rem;
      }
      @include respond(phone-land) {
        font-size: 5rem;
      }
    }

    &:hover &__text {
      font-size: 3rem;
      max-width: 50rem;

      @include respond(tablet-port) {
        font-size: 2rem;
      }
      @include respond(phone-land) {
        font-size: 1.8rem;
      }
    }

    &__heading {
      margin-bottom: 4rem;
      font-family: "Raleway";
      font-size: 4rem;
      font-weight: bold;
      line-height: 1.18;
      opacity: 0.8;
      transition: 0.2s all;

      @include respond(tablet-port) {
        margin-bottom: 3rem;
      }
      @include respond(phone-land) {
        margin-bottom: 2rem;
      }

      &--not-hovered {
        opacity: 0.4 !important;
      }
    }

    &__text {
      max-width: 40rem;
      font-family: "Open Sans";
      font-size: 2.4rem;
      line-height: 1.42;
      opacity: 0.6;
      transition: 0.2s all;

      @include respond(laptop) {
        font-size: 2.2rem;
      }

      @include respond(small-laptop) {
        max-width: 35rem;
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        max-width: 30rem;
        font-size: 1.8rem;
      }

      @include respond(tablet-port) {
        max-width: 100%;
        font-size: 1.8rem;
      }
      @include respond(phone-land) {
        font-size: 1.6rem;
      }
      &--not-hovered {
        opacity: 0.4 !important;
      }
    }
  }

  &__lines {
    margin-top: 8rem;
    @include respond(tablet-port) {
      display: none;
    }
  }
}
</style>
