<template>
  <header
    class="header"
    :class="[
      getTeamPageColor,
      colorHeaderBack,
      getJobsPageColor,
      setTransition,
    ]"
  >
    <div
      :class="$route.path === '/team' ? 'header__logo_black' : 'header__logo'"
      @click="routeToHome"
      v-if="!showBackButton"
    >
      <div class="div-background-img" :style="rotate"></div>
      <div class="div-background-img"></div>
    </div>
    <div class="header__back-btn" v-else>
      <div @click="routeToMenu" class="header__back-btn__innerDiv">
        <div
          class="div-background-img header__back-btn__innerDivImg"
          v-if="!isJobsOrTeamsPage"
        ></div>
        <div
          class="div-background-img header__back-btn__innerDivImgWhite"
          v-else
        ></div>
      </div>
    </div>

    <div
      class="header__language"
      v-if="!isJobsOrTeamsPage"
      v-show="!isSelectedJob"
    >
    <!-- TODO: Uncomment when Dutch versions are added -->
      <button
        class="btn-no-style header__language__btn"
        :class="{ 'header__language__btn--active': selectedLanguage === 'EN' }"
        @click="changeLanguage('NL')"
      >
        NL
      </button>
      <button
        class="btn-no-style header__language__btn"
        :class="{ 'header__language__btn--active': selectedLanguage === 'NL' }"
        @click="changeLanguage('EN')"
      >
        EN
      </button>
    </div>
    <div class="header__language" v-else v-show="!isSelectedJob">
      <button
        class="btn-no-style header__language__btnWhite"
        :class="{
          'header__language__btnWhite--active': selectedLanguage === 'EN',
        }"
        @click="changeLanguage('NL')"
      >
        NL
      </button>
      <button
        class="btn-no-style header__language__btnWhite"
        :class="{
          'header__language__btnWhite--active': selectedLanguage === 'NL',
        }"
        @click="changeLanguage('EN')"
      >
        EN
      </button>
    </div>
    <div class="header__menu" v-if="!isJobsOrTeamsPage" v-show="!isSelectedJob">
      <button
        class="btn-no-style header__menu__menu-btn"
        :class="{ 'header__menu__menu-btn--opened': menuIsOpened }"
        @click="toggleMenu"
      >
        <span></span>
        <span></span>
      </button>
    </div>
    <div class="header__menu" v-else v-show="!isSelectedJob">
      <button
        class="btn-no-style header__menu__menu-btnWhite"
        :class="{ 'header__menu__menu-btnWhite--opened': menuIsOpened }"
        @click="toggleMenu"
      >
        <span></span>
        <span></span>
      </button>
    </div>
    <div class="btn_container" v-show="isSelectedJob">
      <button class="applyBtn" @click="goToJobApllicationForm()">
        <span>{{ $t("applyNowLblHeader") }}</span>
      </button>
    </div>
  </header>
</template>

<script>
import EventBus from "../event-bus";
export default {
  data() {
    return {
      selectedLanguage: "NL",
      menuIsOpened: false,
      currentPage: null,
      showBackButton: false,
      theta: 0,
      colorHeaderBack: null,
    };
  },

  mounted() {
    EventBus.$on("colorChange", (payload) => {
      this.changeHeaderColor(payload);
    });
    if (
      this.$route.path === "/menu" ||
      this.$route.path === "/menu/projects" ||
      this.isSelectedJob ||
      this.isSelectedForm
    ) {
      this.menuIsOpened = true;
      if (
        this.$route.path === "/menu/projects" ||
        this.isSelectedJob ||
        this.isSelectedForm
      ) {
        this.showBackButton = true;
        return;
      }
      this.theta = 0;
      this.showBackButton = false;
    }
    EventBus.$on("pageScrolled", this.rotateLogo);
  },
  watch: {
    $route(to, from) {
      if (
        from.path === "/menu" ||
        from.path === "/menu/projects" ||
        to.path === "/jobs" ||
        to.path === "/team" ||
        this.isSelectedJob ||
        this.isSelectedForm ||
        this.$route.path === "/"
      ) {
        this.theta = 0;
        this.showBackButton = false;
        this.menuIsOpened = false;
      }
      if (
        to.path === "/menu" ||
        to.path === "/menu/projects" ||
        this.isSelectedJob ||
        this.isSelectedForm
      ) {
        if (
          to.path === "/menu/projects" ||
          this.isSelectedJob ||
          this.isSelectedForm
        ) {
          this.showBackButton = true;
          this.menuIsOpened = true;
        } else {
          this.theta = 0;
          this.showBackButton = false;
          this.menuIsOpened = true;
        }
      }
      if (
        from.path === "/products" ||
        from.path === "/software" ||
        from.path === "/electronics"
      ) {
        this.theta = 0;
        this.changeHeaderColor(" ");
      }
    },
  },
  methods: {
    goToJobApllicationForm() {
      this.$router.replace(
        `/jobsApplication/${this.$route.path.substring(1).split("/")[1]}`
      );
    },
    changeHeaderColor(payload) {
      if (
        this.$route.path === "/software" ||
        this.$route.path === "/products" ||
        this.$route.path === "/electronics"
      ) {
        if (payload === "gray") {
          this.colorHeaderBack = "change_gray";
        } else if (payload === "orange1") {
          this.colorHeaderBack = "change_orange_1";
        } else if (payload === "cyan1") {
          this.colorHeaderBack = "change_cyan_1";
        } else if (payload === "yellow") {
          this.colorHeaderBack = "change_yellow";
        } else if (payload === "green") {
          this.colorHeaderBack = "change_green";
        } else if (payload === "gray_2") {
          this.colorHeaderBack = "change_gray_2";
        } else if (payload === "yellow_2") {
          this.colorHeaderBack = "change_yellow_2";
        } else if (payload === "red") {
          this.colorHeaderBack = "change_red";
        } else if (payload === "blue") {
          this.colorHeaderBack = "change_blue";
        } else if (payload === "red_2") {
          this.colorHeaderBack = "change_red_2";
        } else if (payload === "cyan_2") {
          this.colorHeaderBack = "change_cyan_2";
        } else if (payload === "white_1") {
          this.colorHeaderBack = "change_white_1";
        }
      } else {
        this.colorHeaderBack = "";
      }
    },
    changeLanguage(lang) {
      if (lang === this.selectedLanguage) {
        return;
      }
      if (lang === "EN") {
        this.selectedLanguage = "EN";
        this.$i18n.locale = "en";
      } else {
        this.selectedLanguage = "NL";
        this.$i18n.locale = "nl";
      }
    },
    toggleMenu() {
      this.menuIsOpened = !this.menuIsOpened;
      if (this.menuIsOpened) {
        this.currentPage = this.$route.path;
        this.$router.replace("/menu");
        return;
      }
      if (this.currentPage) {
        this.$router.replace(this.currentPage);
      } else {
        this.$router.replace("/");
      }
    },
    routeToHome() {
      if (this.$route.path !== "/") {
        this.$router.replace("/");
      }
    },
    routeToMenu() {
      if (this.isSelectedForm) {
        this.$router.replace(
          `/jobs/${this.$route.path.substring(1).split("/")[1]}`
        );
      } else if (this.isSelectedJob) {
        this.$router.replace("/jobs");
      } else if (this.$route.path !== "/menu") {
        this.$router.replace("/menu");
      }
    },
    rotateLogo(payload) {
      this.theta = payload.theta;
    },
  },
  computed: {
    setTransition() {
      return {
        transitionAdd:
          this.$route.path === "/electronics" ||
          this.$route.path === "/products" ||
          this.$route.path === "/software",
      };
    },
    isJobsOrTeamsPage() {
      if (this.$route.path === "/team" || this.isSelectedJob) {
        return true;
      } else {
        return false;
      }
    },
    getTeamPageColor() {
      return { colorHeader: this.$route.path === "/team" };
    },
    getJobsPageColor() {
      return { colorHeaderJobs: this.isSelectedJob };
    },
    isSelectedJob() {
      if (/^\/(jobs\/).*$/.test(this.$route.path)) {
        return true;
      } else {
        return false;
      }
    },
    isSelectedForm() {
      if (/^\/(jobsApplication\/).*$/.test(this.$route.path)) {
        return true;
      } else {
        return false;
      }
    },
    getBackgroundColor() {
      return {
        headerColor: this.$route.path === "/menu",
      };
    },
    rotate() {
      return { transform: `rotate(${this.theta}rad)` };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_utilities.scss";
@import "../scss/_mixins.scss";
@import "../scss/_components.scss";
.colorHeader {
  background-color: #e73151;
}
.colorHeaderJobs {
  background-color: #4e3ee0;
}
.transitionAdd {
  transition: 0.6s background-color;
}
.header {
  display: flex;
  align-items: center;
  padding: 1rem 15rem;

  @include respond(laptop) {
    padding: 1rem 12rem;
  }
  @include respond(small-laptop) {
    padding: 1rem 10rem;
  }
  @include respond(tablet-land) {
    padding: 1rem 8rem;
  }
  @include respond(tablet-port) {
    padding: 1rem 3rem;
  }



  &__logo {
    cursor: pointer;
    display: flex;
    align-items: center;

    & > div:nth-child(1) {
      width: 9rem;
      height: 10rem;
      background-image: url("../assets/logo_color_1.png");

      @include respond(tablet-port) {
        width: 6.2rem;
        height: 7rem;
      }
    }

    & > div:nth-child(2) {
      margin-left: 1rem;
      width: 20rem;
      height: 5.1rem;
      background-image: url("../assets/logo_color_2.png");

      @include respond(tablet-port) {
        display: none;
      }
    }
  }
  &__logo_black {
    cursor: pointer;
    display: flex;
    align-items: center;

    & > div:nth-child(1) {
      width: 9rem;
      height: 10rem;
      background-image: url("../assets/logo_white_1.png");

      @include respond(tablet-port) {
        width: 6.2rem;
        height: 7rem;
      }
    }

    & > div:nth-child(2) {
      margin-left: 1rem;
      width: 20rem;
      height: 5.1rem;
      background-image: url("../assets/logo_white_2.png");

      @include respond(tablet-port) {
        display: none;
      }
    }
  }

  &__back-btn {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 28.2rem;
    height: 10.3rem;
    z-index: 200;

    @include respond(tablet-port) {
      width: 6rem;
      height: 7rem;
    }

    &__innerDiv {
      cursor: pointer;
      padding: 1rem;
    }
    &__innerDivImg {
      width: 3rem;
      height: 3rem;
      background-image: url("../assets/back.png");
    }
    &__innerDivImgWhite {
      width: 3rem;
      height: 3rem;
      background-image: url("../assets/backWhite.png");
    }
  }

  &__language {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: auto;
    z-index: 200;

    &__btn {
      cursor: pointer;
      margin-right: 6rem;
      font-family: ProximaNova;
      font-size: 3.3rem;
      transition: 0.2s;

      @include respond(tablet-port) {
        margin-right: 0;
        font-size: 2.7rem;
        padding: 1rem;
      }

      &--active {
        opacity: 0.6;
        transition: 0.2s;
      }

      &:hover {
        opacity: 1;
      }

      &:last-child {
        margin-right: 8rem;

        @include respond(tablet-port) {
          margin-right: 2rem;
        }
      }
    }
    &__btnWhite {
      cursor: pointer;
      margin-right: 6rem;
      font-family: ProximaNova;
      font-size: 3.3rem;
      transition: 0.2s;
      color: white;

      @include respond(tablet-port) {
        margin-right: 0;
        font-size: 2.7rem;
        padding: 1rem;
      }

      &--active {
        opacity: 0.6;
        transition: 0.2s;
      }

      &:hover {
        opacity: 1;
      }

      &:last-child {
        margin-right: 8rem;

        @include respond(tablet-port) {
          margin-right: 2rem;
        }
      }
    }
  }

  &__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    z-index: 200;
    margin-left: auto;

    &__menu-btn {
      position: relative;
      width: 4.7rem;
      height: 3rem;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;

      @include respond(tablet-port) {
        width: 3.6rem;
        height: 2.4rem;
      }

      & span {
        position: absolute;
        left: 0;
        display: block;
        height: 0.4rem;
        width: 100%;
        background: black;
        border-radius: 0.2rem;
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          top: 0;
          transform-origin: left center;
        }

        &:nth-child(2) {
          top: 2.5rem;
          transform-origin: left center;

          @include respond(tablet-port) {
            top: 2rem;
          }
        }
      }

      &--opened {
        & span:nth-child(1) {
          width: 76%;
          transform: rotate(45deg);

          @include respond(tablet-port) {
          }
        }

        & span:nth-child(2) {
          width: 76%;
          transform: rotate(-45deg);

          @include respond(tablet-port) {
          }
        }
      }
    }

    &__menu-btnWhite {
      position: relative;
      width: 4.7rem;
      height: 3rem;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;

      @include respond(tablet-port) {
        width: 3.6rem;
        height: 2.4rem;
      }

      & span {
        position: absolute;
        left: 0;
        display: block;
        height: 0.4rem;
        width: 100%;
        background: white;
        border-radius: 0.2rem;
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          top: 0;
          transform-origin: left center;
        }

        &:nth-child(2) {
          top: 2.5rem;
          transform-origin: left center;

          @include respond(tablet-port) {
            top: 2rem;
          }
        }
      }

      &--opened {
        & span:nth-child(1) {
          width: 76%;
          transform: rotate(45deg);

          @include respond(tablet-port) {
          }
        }

        & span:nth-child(2) {
          width: 76%;
          transform: rotate(-45deg);

          @include respond(tablet-port) {
          }
        }
      }
    }
  }
}

.btn_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  z-index: 200;
}
.applyBtn {
  width: 20rem;
  height: 7rem;
  background-color: #e73151;
  border-style: none;
  color: #ffffff;

  @include respond(laptop) {
    width: 18rem;
    height: 6rem;
  }
  @include respond(tablet-land) {
    width: 16rem;
    height: 5.5rem;
  }
  @include respond(phone-land) {
    width: 14rem;
    height: 5rem;
  }

  & > span {
    font-family: "Raleway";
    font-weight: 400;
    line-height: 1.21;
    font-size: 2rem;

    @include respond(laptop) {
      font-size: 1.9rem;
    }
    @include respond(tablet-land) {
      font-size: 1.7rem;
    }
    @include respond(phone-land) {
      font-size: 1.6rem;
    }
  }
}
.change_gray {
  background-color: $color-gray-2;
}

.change_yellow {
  background-color: $color-yellow;
}

.change_green {
  background-color: $color-green;
}

.change_gray_2 {
  background-color: $color-gray-3;
}

.change_yellow_2 {
  background-color: $color-yellow;
}

.change_red {
  background-color: $color-red-2;
}

.change_blue {
  background-color: $color-blue-2;
}
.change_cyan_1 {
  background-color: $color-cyan-1;
}
.change_orange_1 {
  background-color: $color-orange-1;
}
.change_red_2 {
  background-color: $color-red-2;
}

.change_cyan_2 {
  background-color: $color-cyan-2;
}
.change_white_1 {
  background-color: $color-white-1;
}
</style>
