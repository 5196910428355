export default function() {
  /* eslint-disable */

  function converHtmlToText(html) {
    // html = html.replace(/<\/div>/gi, "\n");
    html = html.replace(/<\/li>/gi, "\n");
    html = html.replace(/<li>/gi, "  ●  ");
    html = html.replace(/<\/ul>/gi, "\n");
    // html = html.replace(/<\/p>/gi, "\n");
    // html = html.replace(/<br[^>]*>/g, "\n");
    // html = html.replace(/<[^>]+>/gi, "");
    return html;
  }

  return { converHtmlToText };
}
