<template>
  <div class="software" ref="software">
    <section class="software__section software__hero" id="section-products-hero">
      <div class="software__hero__heading">
        <h1>
          {{ $t("ourAwesomeLbl") }}<br />
          <span>{{ $t("softwareAppsLbl") }}</span>.
        </h1>
      </div>
      <div class="software__hero__img">
        <div class="div-background-img"></div>
      </div>
      <div class="software__hero__btn-container" v-show="isWindowWidthDesktop">
        <a class="btn-primary btn-primary-down btn-primary-down--blue" href="#htmSection" @click="setUrlBack">
          <div></div>
          <p>{{ $t("scrollDownLbl") }}</p>
          <span></span>
        </a>
      </div>
    </section>
    <section class="software__section software__container" v-for="product in products" :key="product.id"
      :ref="product.idTag" :class="[{ 'software__container--reverse': !product.positionLeft }]">
      <div class="software__container__content__number" v-show="!isWindowWidthDesktop">
        <h1>{{ product.number }}</h1>
      </div>
      <div class="software__container__image" v-show="!isWindowWidthDesktop">
        <Carousel :images="product.image" :class="
          product.watermarkClass === 'products__container__image--circle'
            ? ''
            : product.watermarkClass
        " />
        <!-- <div
          class="div-background-img"
          :class="
            product.watermarkClass === 'software__container__image--circle'
              ? product.watermarkClass
              : ''
          "
        ></div> -->
      </div>
      <div class="software__container__content">
        <div class="software__container__content__number" v-show="isWindowWidthDesktop">
          <h1>{{ product.number }}</h1>
        </div>
        <div class="software__container__content__main">
          <h1>{{ product.name }}</h1>

          <!-- <TechnologiesTable
            v-if="product.technologies?.length > 0"
            :technologies="product.technologies"
          /> -->

          <p style="white-space: pre-line" v-html="converHtmlToText(product.description)"></p>
        </div>
        <div class="software__container__content__btn-container">
          <button @click="goToPage(product.button.link)" class="btn-primary btn-primary-right"
            :class="`btn-primary-right--${product.button.color}`">
            <span></span>
            <p>{{ product.button.text }}</p>
            <div></div>
          </button>
        </div>
      </div>
      <div class="software__container__image" v-show="isWindowWidthDesktop">
        <Carousel :images="product.image" class="div-background-img" />
      </div>
    </section>
    <transition name="fade">
      <div class="software__pagination" v-if="currentSlide > 0 && currentSlide < 6" v-show="isWindowWidthDesktop">
        <div class="software__pagination__text">
          <p>{{ $t("clickOrScrollLbl") }}</p>
        </div>
        <div class="software__pagination__circles">
          <span @click="moveScrollTo(5)" :class="{
            'software__pagination--active-line software__pagination--active':
              currentSlide === 5,
          }" class="software__pagination__bullet "></span>
          <span @click="moveScrollTo(4)" :class="{
            'software__pagination--active-line software__pagination--active':
              currentSlide === 4,
          }" class="software__pagination__bullet "></span>
          <span @click="moveScrollTo(3)" :class="{
            'software__pagination--active-line software__pagination--active':
              currentSlide === 3,
          }" class="software__pagination__bullet "></span>
          <span @click="moveScrollTo(2)" :class="{
            'software__pagination--active-line': currentSlide === 1,
            'software__pagination--active-line software__pagination--active':
              currentSlide === 2,
          }" class="software__pagination__bullet "></span>
          <span @click="moveScrollTo(1)" :class="{ 'software__pagination--active': currentSlide === 1 }"
            class="software__pagination__bullet "></span>
        </div>
      </div>
    </transition>
    <section class="software__footer-section">
      <Footer />
    </section>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import EventBus from "../event-bus";
import useHtmlToTextConverter from "../mixins/useHtmlToTextConverter";

import Carousel from "../components/Carousel.vue";
// import TechnologiesTable from "../components/TechnologiesTable.vue";
export default {
  data() {
    const { converHtmlToText } = useHtmlToTextConverter();
    return {
      converHtmlToText,
      scrollPosition: 0,
      currentElId: "other",
      inWidth: 0,
    };
  },
  computed: {
    products() {
      return [
        {
          id: 0,
          number: "01",
          name: "HTM",
          description: this.$t("htmSoftDesc"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "red",
            link: "https://www.htm.nl/",
          },
          image: [
            { id: 0, path: "HTM01_min.png" },
            { id: 1, path: "HTM03_min.png" },
          ],
          // watermarkClass: "software__container__image--triangle",
          classTag: "software__htm",
          idTag: "htmSection",
          positionLeft: false,
        },
        {
          id: 1,
          number: "02",
          name: "GMS",
          description: this.$t("gmsSoftDesc"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "purple3",
            link: "https://www.landelijkemeldkamer.org/",
          },
          image: [
            { id: 0, path: "GMS01_min.png" },
            { id: 1, path: "GMS03_min.png" },
          ],
          // watermarkClass: "software__container__image--triangle",
          idTag: "gmsSection",
          classTag: "software__gms",
          positionLeft: true,
        },
        {
          id: 2,
          number: "03",
          name: "Woltring",
          description: this.$t("woltringDesc"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "purple3",
            link: " https://competentiematch.woltring.nl/",
          },
          image: [
            { id: 0, path: "Woltring01_min.png" },
            { id: 1, path: "Woltring02_min.png" },
            { id: 2, path: "Woltring03_min.png" }
          
          ],
          watermarkClass: "software__container__image--triangle",
          idTag: "woltring",
          classTag: "software__gms",
          positionLeft: false,
        },
        {
          id: 3,
          number: "04",
          name: "Moving Intelligence",
          description: this.$t("movingIntelligenceDesc"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "purple3",
            link: "https://movingintelligence.com/en/",
          },
          image: [
            { id: 0, path: "MoovingIntelligence03_min.png" },
            { id: 1, path: "movingintelligence01_min.png" },
          ],
          watermarkClass: "software__container__image--triangle",
          idTag: "movingIntelligence",
          classTag: "software__gms",
          positionLeft: true,
        },
        {
          id: 4,
          number: "05",
          name: "Ace Challenge",
          description: this.$t("aceChallengeDescription"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "purple3",
            link: "https://theacechallenge.com/",
          },
          image: [

            { id: 1, path: "AceChallenge01-min.png" }
          ],
          watermarkClass: "software__container__image--triangle",
          idTag: "aceChallenge",
          classTag: "software__gms",
          positionLeft: false,
        },
      ];
    },
    isWindowWidthDesktop() {
      if (this.inWidth <= 960) {
        return false;
      }
      return true;
    },
    colorToScrollPositionMap() {
      // if (this.currentElId === "other") {
      //   this.emitMethod("gray");
      // } else if (this.currentElId === "htmSection") {
      //   this.emitMethod("orange1");
      // } else if (this.currentElId === "gmsSection") {
      //   this.emitMethod("cyan1");
      // } else if (this.currentElId === "woltring") {
      //   this.emitMethod("blue1");
      // } else if (this.currentElId === "movingIntelligence") {
      //   this.emitMethod("purple1");
      // }
      return {
        // change_gray: this.currentElId === "other",
        // change_orange_1: this.currentElId === "htmSection",
        // change_cyan_1: this.currentElId === "gmsSection",
      };
    },
    currentSlide() {
      for (let index = 0; index < this.products.length; index++) {
        if (this.products[index].idTag === this.currentElId) {
          return index + 1;
        } else if (this.products[index].idTag === "other") {
          return 0;
        }
      }
      return 0;
    },
  },
  mounted() {
    //this.testingOverflow();
    if (this.$refs.software) {
      this.$refs.software.addEventListener("scroll", this.updateScroll);
      this.$refs.software.addEventListener("scroll", this.isScrolledToElem);
      this.$refs.software.addEventListener("scroll", this.onScrollHandler);
    }
    window.addEventListener("resize", this.updateInnerWidth);
    this.updateInnerWidth();
  },
  methods: {
    onScrollHandler(event) {
      if (this.resizedSmallerScreen) {
        window.scrollTo(0, 1);
      }
      let x = (event.target.scrollTop / 10) % Math.PI;
      EventBus.$emit("pageScrolled", { theta: x });
    },
    goToPage(link) {
      window.open(
        link,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    emitMethod(payload) {
      EventBus.$emit("colorChange", payload);
    },
    updateInnerWidth() {
      this.inWidth = window.innerWidth;
    },
    updateScroll(event) {
      this.scrollPosition = event.target.scrollTop;
    },
    isScrolledToElem(event) {
      let elOffsetTop = 0;
      let idTags;
      // * looping through all products, calculating the scroll position
      // * from the middle of them to the top and changing the colour based on that
      for (let index = 0; index < this.products.length; index++) {
        idTags = this.products[index].idTag;
        elOffsetTop = this.$refs[idTags][0].offsetTop;

        // ? the number 73 that is added to the scrollTop is the 12.3rem we substracted from the top - 50px from section top padding
        if (
          event.target.scrollTop + 400 <
          this.$refs[this.products[index].idTag][0].offsetTop
        ) {
          this.currentElId = "other";
          return;
        }
        if (index < this.products.length - 1) {
          if (
            event.target.scrollTop + 400 >= elOffsetTop &&
            event.target.scrollTop + 400 <=
            this.$refs[this.products[index + 1].idTag][0].offsetTop
          ) {
            this.currentElId = this.products[index].idTag;
            return;
          }
        } else {
          this.currentElId = this.products[index].idTag;
          return;
        }
      }
    },
    moveScrollTo(scrollPos) {
      switch (scrollPos) {
        case 1:
          window.location.href = "#htmSection";
          break;
        case 2:
          window.location.href = "#woltring";
          break;
        case 3:
          window.location.href = "#movingIntelligence";
          break;
        case 4:
          window.location.href = "#gmsSection";
          break;
        default:
          window.location.href = "#aceChallenge";
          break;
      }
      this.setUrlBack();
    },
    setUrlBack() {
      setTimeout(() => {
        history.replaceState(null, null, "/#/software");
      }, 50);
    },
  },
  components: {
    Footer,
    Carousel,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";

.software {
  height: calc(100vh - 115.2px);
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond(laptop) {
    height: calc(100vh - 108px);
  }

  @include respond(small-laptop) {
    height: calc(100vh - 101.73px);
  }

  @include respond(tablet-land) {
    height: calc(100vh - 96px);
  }

  @include respond(tablet-port) {
    height: calc(100vh - 72px);
  }

  @media screen and (min-width: 1920px) {
    width: 1920px;
    margin: 0 auto;
  }

  &__section {
    padding: 240px 15rem 240px 15rem;
    transition: 0.6s background-color;
    scroll-snap-align: center;
    min-height: 90vh;

    @include respond(laptop) {
      padding: 10rem 12rem 10rem 12rem;
    }

    @include respond(small-laptop) {
      padding: 0 10rem 0 10rem;
    }

    @include respond(tablet-land) {
      min-height: 80rem;
      padding: 0 8rem 0 8rem;
    }

    @include respond(tablet-port) {
      min-height: 60rem;
      padding: 0 5rem 0 5rem;
    }

    @include respond(phone-land) {
      min-height: 40rem;
      padding: 2rem 3rem 0 3rem;
    }
  }

  &__section-container {
    position: relative;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: 10rem;

    @include respond(tablet-port) {
      flex-flow: row wrap;
    }

    @include respond(phone-land) {
      padding-bottom: 0.5rem;
      padding-top: 5rem;
      flex-flow: row wrap;
    }

    &--reverse {
      flex-direction: row-reverse;
      justify-content: space-evenly;

      @include respond(tablet-land) {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 5rem;
      }

      @include respond(phone-land) {
        padding-bottom: 0.5rem;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;

      @include respond(phone-land) {
        justify-content: center;
      }

      &__number {
        &>h1 {
          position: relative;
          font-family: "Raleway";
          font-size: 14rem;
          font-weight: bold;
          line-height: 1.17;
          -webkit-text-stroke: 3px #000000;
          -webkit-text-fill-color: transparent;
          opacity: 0.8;

          @include respond(phone-land) {
            font-size: 10rem;
            margin-bottom: 5rem;
          }

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1rem;
            background-color: black;
            border-radius: 0.2rem;
          }
        }
      }

      &__main {
        &>h1 {
          font-family: "Raleway";
          font-size: 7rem;
          font-weight: bold;
          line-height: 1.18;
          opacity: 0.8;

          @include respond(phone-land) {
            font-size: 4rem;
          }
        }

        &>p {
          margin-top: 3rem;
          font-family: "Open Sans";
          font-size: 2rem;
          line-height: 1.38;
          max-width: 63rem;
          opacity: 0.6;

          @include respond(small-laptop) {
            max-width: 53rem;
          }

          @include respond(tablet-port) {
            font-size: 1.8rem;
            max-width: 90%;
          }

          @include respond(phone-land) {
            font-size: 1.6rem;
            max-width: 90%;
          }
        }
      }
    }

    &__image {
      position: relative;
      margin-right: 5rem;
      // width: 75rem;
      display: flex;

      @include respond(small-laptop) {
        margin-top: 15rem;
        margin-right: 5rem;
      }

      @include respond(tablet-port) {
        margin-right: 0rem;
        margin: 0 0 15rem 0;
        width: 100%;
      }

      @include respond(phone-land) {
        margin-top: 1rem;
        margin-right: 0rem;
        width: 100%;
        margin: 0rem;
      }

      &>div:nth-child(1) {
        width: 70rem;
        height: 70rem;
        margin: 0.5rem;
        align-self: flex-end;

        @include respond(small-laptop) {
          width: 60rem;
          height: 60rem;
        }

        @include respond(tablet-port) {
          width: 50rem;
          height: 50rem;
          margin-left: auto;
          margin-right: auto;
        }

        @include respond(phone-land) {
          background-size: 100% auto;
          width: 100% !important;
          //padding-top: 100% !important;
          height: 35rem;
        }
      }
    }
  }

  &__pagination {
    position: fixed;
    top: 50%;
    right: -10rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    transform: rotate(-90deg);

    @include respond(tablet-land) {
      right: -15rem;
    }

    @include respond(phone-land) {
      right: -18rem;
    }

    &__text {
      margin-top: -0.3rem;
      margin-left: 2rem;

      &>p {
        font-family: "Open Sans";
        font-size: 2.3rem;
        line-height: 1.38;
      }
    }

    &__circles {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__bullet {
      cursor: pointer;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      border: 0.2rem solid black;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &--active {
      background-color: black;
    }

    &--active-line {
      position: relative;
      margin-right: 14rem !important;

      &::after {
        content: "";
        position: absolute;
        top: 45%;
        left: 2.6rem;
        width: 11rem;
        height: 0.2rem;
        border-radius: 10rem;
        background-color: black;
      }
    }
  }

  &__hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-gray-2;

    @include respond(tablet-port) {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__img {
      &>div {
        background-image: url("../assets/softwareImg.png");
        width: 62rem;
        height: 60rem;

        @include respond(small-laptop) {
          width: 50rem;
          height: 50rem;
        }

        @include respond(tablet-land) {
          width: 40rem;
          height: 39rem;
        }

        @include respond(tablet-port) {
          width: 35rem;
          height: 34rem;
        }

        @include respond(phone-land) {
          width: 30rem;
          height: 29rem;
        }
      }
    }

    &__heading {
      margin-left: 10rem;
      margin-top: -10rem;

      @include respond(tablet-port) {
        margin-left: 0;
        margin-top: 0;
        text-align: center;
      }

      &>h1 {
        font-family: "Raleway";
        font-size: 8rem;
        line-height: 1;
        color: $color-blue-3;

        @include respond(phone-land) {
          font-size: 5rem;
          margin-top: 10rem;
          margin-bottom: 10rem;
        }

        &>span {
          color: $color-blue-4;
        }
      }
    }

    &__btn-container {
      margin: 35rem -15.5rem 0 auto;
      transform: rotate(-90deg);

      @include respond(tablet-port) {
        margin: 20rem -15.5rem 0 auto;
      }

      @include respond(phone-land) {
        margin: 5rem -10rem 0 auto;
      }
    }
  }

  &__htmSection {
    background-color: $color-yellow;
  }

  &__gms {
    background-color: $color-green;
  }

  &__smartfloor {
    background-color: $color-gray-3;
  }
}

.change_gray {
  background-color: $color-gray-2;
}

.change_yellow {
  background-color: $color-yellow;
}

.change_green {
  background-color: $color-green;
}

.change_gray_2 {
  background-color: $color-gray-3;
}

.change_yellow_2 {
  background-color: $color-yellow;
}

.change_red {
  background-color: $color-red-2;
}

.change_blue {
  background-color: $color-blue-2;
}

.change_cyan_1 {
  background-color: $color-cyan-1;
}

.change_orange_1 {
  background-color: $color-orange-1;
}
</style>
