<template>
  <div ref="lines" style="height:100%;"></div>
</template>

<script>
export default {
    data() {
        return {
            TAU : 1.5 * Math.PI,
            settings : {
                bgColor: [255, 255, 255],
                filamentCount: 3,
                lineColor: [
                    [50, 79, 199],
                    [133, 153, 233],
                    [205, 214, 252],
                ],
                lineWidth: 2.5,
                minY: 5 * window.devicePixelRatio,
                maxY: 35 * window.devicePixelRatio,
                showFps: false,
                splines: {
                    segmentCount: 16,
                    tension: 0.4
                }
            },
            dom : Object,
            oscillator : 1,
            startTime : +new Date(),
            dbMultiplier : 2,
            filaments : [],
            isPaused : false,
            isTicking : false,
            ctx: null,
        }
    },
    mounted() {
        this.dom = {
            container: this.$refs.lines,
            canvas: document.createElement('canvas')
        }
        this.ctx = this.dom.canvas.getContext('2d')
        this.ctx.scale(window.devicePixelRatio, window.devicePixelRatio)

        this.setupEvents()
        this.buildCanvas()
        this.buildFilaments()
        this.animate()
    },

    methods: {
        setupEvents() {
            window.addEventListener('resize', () => {
                if (!this.isTicking) {
                    window.requestAnimationFrame(this.setCanvasDimensions);
                }
                this.isTicking = true
            })
        },
        // Build
        buildCanvas() {
            this.dom.container.appendChild(this.dom.canvas)
            this.setCanvasDimensions()
        },
        setCanvasDimensions() {
            this.isTicking = false
            this.dom.canvas.width = this.dom.container.offsetWidth * window.devicePixelRatio
            this.dom.canvas.height = this.dom.container.offsetHeight  * window.devicePixelRatio
            this.ctx.translate(0, this.dom.canvas.height / 2) // height of the canvas

            Object.assign(this.dom.canvas.style, {
                width: `${this.dom.container.offsetWidth}px`,
                height: "157px"
            })
        },
        buildFilaments() {
            this.filaments = []
            for (let i = 0; i < this.settings.filamentCount; i++) {
                this.filaments.push(this.getPoints())
            }
            this.buildOscillators()
        },
        buildOscillators() {
            this.oscillators = []
            this.filaments.forEach((filament) => {
                for (let i = 1; i < filament.length; i += 2) {
                    this.oscillators.push({
                        x: {
                            interval: this.getRandomMinMax(3000, 6000),
                            multiplier: this.getRandomMinMax(10, 30),
                            start: Math.random() * this.TAU
                        },
                        y: {
                            interval: this.getRandomMinMax(2000, 3000),
                            multiplier: this.getRandomMinMax(0.4, 1),
                            start: Math.acos(filament[i] / this.settings.maxY)
                        }
                    })
                }
            })
        },
        // Draw
        animate() {
            if (!this.isPaused) {
                window.requestAnimationFrame(this.animate)
                this.draw()
            }
        },
        draw() {
            if (!this.isPaused) {
                this.clearCanvas()
            }
            this.drawBackground()
            let oscillatorIndex = 0
            this.filaments.forEach((filament, i) => {
                const _fil = filament.slice(0)
                for (let j = 0; j < _fil.length; j += 2) {
                    const now = +new Date()
                    const diff = now - this.startTime
                    const oscillator = this.oscillators[oscillatorIndex]
                    const xMultiplier = Math.max(Math.pow(Math.sin(_fil[j] / this.dom.canvas.width * Math.PI), 4), 0.1)

                    _fil[j + 1] = _fil[j + 1] * Math.cos(diff / oscillator.y.interval * this.TAU + oscillator.y.start) * oscillator.y.multiplier * this.dbMultiplier * xMultiplier

                    if (j > 0 && j < _fil.length - 2) {
                        _fil[j] = _fil[j] + Math.sin(diff / oscillator.x.interval * this.TAU + oscillator.x.start) * oscillator.x.multiplier
                    }
                    oscillatorIndex++
                }
                this.drawSpline(this.getSpline(_fil), i)
            })
        },
        clearCanvas() {
            this.ctx.clearRect(0, 0, this.dom.canvas.width, this.dom.canvas.height)
        },
        drawBackground() {
            this.ctx.fillStyle = `rgba(${this.settings.bgColor.join()}, 1)`
            this.ctx.beginPath()
            this.ctx.rect(0, -this.dom.canvas.height / 2, this.dom.canvas.width, this.dom.canvas.height)
            this.ctx.fill()
        },
        drawSpline(points, i) {
            this.ctx.strokeStyle = `rgb(${this.settings.lineColor[i].join()})`
            this.ctx.lineWidth = this.settings.lineWidth * window.devicePixelRatio
            this.ctx.beginPath()
            this.ctx.moveTo(points[0], points[1])

            for (let i = 2; i < points.length - 1; i += 2) {
                this.ctx.lineTo(points[i], points[i + 1])
            }

            this.ctx.stroke()
        },
        getPoints() {
            const {
                width,
            } = this.dom.canvas
            const points = []
            let x = 0

            while (x < width) {
                points.push(x)
                let y = 0

                while (y < this.settings.minY && y > -this.settings.minY) {
                    y = this.getRandomMinMax(-this.settings.maxY, this.settings.maxY)
                }

                points.push(y)
                x += this.getRandomMinMax(width / 15, width / 10)

                if (width - x < width / 15) {
                    x = width
                }
            }

            points.push(x)
            points.push(this.getRandomMinMax(-this.settings.maxY, this.settings.maxY))

            return points
        },
        getSpline(points) {
            const _pts = points.slice(0)
            const res = []
            let x, y, t1x, t2x, t1y, t2y, c1, c2, c3, c4, st

            _pts.unshift(points[1])
            _pts.unshift(points[0])
            _pts.push(points[points.length - 2])
            _pts.push(points[points.length - 1])

            for (let i = 2; i < (_pts.length - 4); i += 2) {
                for (let t = 0; t <= this.settings.splines.segmentCount; t++) {
                    st = t / this.settings.splines.segmentCount

                    t1x = (_pts[i + 2] - _pts[i - 2]) * this.settings.splines.tension
                    t2x = (_pts[i + 4] - _pts[i]) * this.settings.splines.tension
                    t1y = (_pts[i + 3] - _pts[i - 1]) * this.settings.splines.tension
                    t2y = (_pts[i + 5] - _pts[i + 1]) * this.settings.splines.tension

                    c1 = 2 * Math.pow(st, 3) - 3 * Math.pow(st, 2) + 1
                    c2 = -(2 * Math.pow(st, 3)) + 3 * Math.pow(st, 2)
                    c3 = Math.pow(st, 3) - 2 * Math.pow(st, 2) + st
                    c4 = Math.pow(st, 3) - Math.pow(st, 2)

                    x = c1 * _pts[i] + c2 * _pts[i + 2] + c3 * t1x + c4 * t2x
                    y = c1 * _pts[i + 1] + c2 * _pts[i + 3] + c3 * t1y + c4 * t2y

                    res.push(x)
                    res.push(y)
                }
            }
            return res
        },
        // Utilities
        getRandomMinMax(min, max) {
            return Math.round(Math.random() * (max - min) + min)
        }
    }
}
</script>

<style>

</style>