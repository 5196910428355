<template>
  <div class="products" ref="products">
    <section class="products__section products__hero" id="section-products-hero" :class="colorToScrollPositionMap">
      <div class="products__hero__img">
        <div class="div-background-img"></div>
      </div>
      <div class="products__hero__heading">
        <h1>
          {{ $t("checkOutOurLbl") }} <br />
          <span>{{ $t("ourProductsLbl") }}</span>.
        </h1>
      </div>
      <div class="products__hero__btn-container" v-show="isWindowWidthDesktop">
        <a class="btn-primary btn-primary-down btn-primary-down--blue" href="#smartgoalsSection" @click="setUrlBack">
          <div></div>
          <p>{{ $t("scrollDownLbl") }}</p>
          <span></span>
        </a>
      </div>
    </section>
    <section class="products__section products__container" :id="product.idTag" :ref="product.idTag"
      :class="[{ 'products__container--reverse': !product.positionLeft }]" v-for="product in products" :key="product.id">
      <div class="products__container__content__number" v-show="!isWindowWidthDesktop">
        <h1>{{ product.number }}</h1>
      </div>
      <div class="products__container__image" v-show="!isWindowWidthDesktop">
        <Carousel :images="product.image" :class="
          product.watermarkClass === 'products__container__image--circle'
            ? ''
            : product.watermarkClass
        " />
        <!-- <div
          class="div-background-img"
          :style="{
            backgroundImage:
              'url(' + require(`../assets/imgs/${product.image}`) + ')',
          }"
          
        ></div> -->
        <div class="div-background-img" :class="
          product.watermarkClass === 'products__container__image--circle'
            ? product.watermarkClass
            : ''
        "></div>
      </div>
      <div class="products__container__content">
        <div class="products__container__content__number" v-show="isWindowWidthDesktop">
          <h1>{{ product.number }}</h1>
        </div>
        <div class="products__container__content__main">
          <h1>{{ product.name }}</h1>

          <p style="white-space: pre-line" v-html="product.description"></p>

          <div class="technologies-icons"
            style="display: flex; justify-content:start; flex-wrap: wrap; margin: 16px 0px 16px 0;">
            <img v-for="tech in product.technologies" :key="tech.path" :src="require(`../assets/${tech.path}`)"
              style="margin:0px 5px 0 0; width:56px; align-self: center" alt="" />
          </div>
        </div>
        <div class="products__container__content__btn-container">
          <button v-if="product.button" @click="goToPage(product.button.link)" class="btn-primary btn-primary-right"
            :class="`btn-primary-right--${product.button.color}`">
            <span></span>
            <p>{{ product.button.text }}</p>
            <div></div>
          </button>
        </div>
      </div>
      <div class="products__container__image" v-show="isWindowWidthDesktop">
        <Carousel :images="product.image" />
        <!-- <div
          class="div-background-img"
          :class="
            product.watermarkClass === 'products__container__image--circle'
              ? product.watermarkClass
              : ''
          "
        ></div> -->
      </div>
    </section>
    <transition name="fade">
      <div class="products__pagination" v-if="currentSlide > 0 && currentSlide < 8" v-show="isWindowWidthDesktop">
        <div class="products__pagination__text">
          <p>{{ $t("clickOrScrollLbl") }}</p>
        </div>
        <div class="products__pagination__circles">
          <span @click="moveScrollTo(7)" :class="{
            'products__pagination--active-line products__pagination--active':
              currentSlide === 7,
          }" class="products__pagination__bullet "></span>
          <span @click="moveScrollTo(6)" :class="{
            'products__pagination--active-line products__pagination--active':
              currentSlide === 6,
          }" class="products__pagination__bullet "></span>
          <span @click="moveScrollTo(5)" :class="{
            'products__pagination--active-line products__pagination--active':
              currentSlide === 5,
          }" class="products__pagination__bullet "></span>
          <span @click="moveScrollTo(4)" :class="{
            'products__pagination--active-line products__pagination--active':
              currentSlide === 4,
          }" class="products__pagination__bullet "></span>
          <span @click="moveScrollTo(3)" :class="{
            'products__pagination--active-line products__pagination--active':
              currentSlide === 3,
          }" class="products__pagination__bullet "></span>
          <span @click="moveScrollTo(2)" :class="{
            'products__pagination--active-line': currentSlide === 1,
            'products__pagination--active-line products__pagination--active':
              currentSlide === 2,
          }" class="products__pagination__bullet "></span>
          <span @click="moveScrollTo(1)" :class="{ 'products__pagination--active': currentSlide === 1 }"
            class="products__pagination__bullet "></span>
        </div>
      </div>
    </transition>
    <section class="products__footer-section">
      <Footer />
    </section>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import EventBus from "../event-bus";
import Carousel from "../components/Carousel.vue";
// import TechnologiesTable from "../components/TechnologiesTable.vue";

import useHtmlToTextConverter from "../mixins/useHtmlToTextConverter";
export default {
  data() {
    const { converHtmlToText } = useHtmlToTextConverter();
    return {
      scrollPosition: 0,
      currentElId: "other",
      inWidth: 0,
      converHtmlToText,
    };
  },
  computed: {
    products() {
      return [
        {
          id: 0,
          number: "01",
          name: "SmartGoals",
          description: this.converHtmlToText(this.$t("projectSmartGoalsDesc")),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "yellow",
            link: "https://www.smartgoals.nl",
          },
          image: [
            { id: 0, path: "SmartGoals01_min.png" },
            { id: 0, path: "SmartGoals02_min.png" },
            { id: 0, path: "SmartGoals03_min.png" },
          ],
          watermarkClass: "products__container__image--circle",
          classTag: "products__smartgoals",
          idTag: "smartgoalsSection",
          positionLeft: true,
        },
        {
          id: 1,
          number: "02",
          name: "SkillCourt",
          description: this.$t("skillCourtProductDesc"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "green",
            link: "https://www.urbanskillcourt.com/nl/",
          },
          image: [
            { id: 0, path: "SkillCourt01_min.png" },
            { id: 1, path: "Skillcourt02_min.png" }
          ],
          // watermarkClass: "products__container__image--triangle",
          idTag: "skillcourtSection",
          classTag: "products__skillcourt",
          positionLeft: false,
        },
        {
          id: 2,
          number: "03",
          name: "Skill games",
          description: this.$t("skillGamesDescription"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "yellow",
            link: "https://smartgoals.nl/skillgames",
          },
          image: [
            { id: 0, path: "SkillGames01_min.png" },
            { id: 1, path: "SkillGames02_min.png" },
            { id: 2, path: "SkillGames03_min.png" }
          ],
          // watermarkClass: "products__container__image--circles",
          idTag: "skillgamesSection",
          classTag: "products__skillgames",
          positionLeft: true,
        },
        {
          id: 3,
          number: "04",
          name: "Fieldback",
          description: this.$t("fieldBackDescription"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "red3",
            link: "https://fieldback.net/sports/",
          },
          image: [{ id: 0, path: "machine.png" }],
          // watermarkClass: "products__container__image--square-2",
          idTag: "fieldbackSection",
          classTag: "products__fieldback",
          positionLeft: false,
        },
        {
          id: 4,
          number: "05",
          name: "PROspike",
          description: this.$t("projectPROSpikeDesc"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "blue2",
            link: "https://prospike.nl/en/",
          },
          image: [
            { id: 0, path: "ProSpike01_min.png" },
            { id: 1, path: "ProSpike02_min.png" }
          ],
          // watermarkClass: "products__container__image--triangle-2",
          idTag: "prospikeSection",
          classTag: "products__pro-spike",
          positionLeft: true,
        },
        {
          id: 5,
          number: "06",
          name: "Enexis",
          description: this.$t("enexisDescription"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "blue2",
            link: "https://www.enexis.nl/meter/meterstanden-doorgeven",
          },
          image: [
            { id: 0, path: "Enexis01-min.png" },
            { id: 1, path: "Enexis02-min.png" },
            { id: 2, path: "Enexis03-min.png" }
          ],
          // watermarkClass: "products__container__image--triangle-2",
          idTag: "enexisSection",
          classTag: "products__enexis",
          positionLeft: false,
        },
        {
          id: 6,
          number: "07",
          name: "OpenCare Gateway",
          description: this.$t("openCareGatewayDescription"),
          button: {
            text: this.$t("caseStudyLbl"),
            color: "blue2",
            link: "https://opencareconnect.eu/",
          },
          image: [
            { id: 0, path: "OpenCare01_min.png" },
            { id: 1, path: "OpenCare02_min.png" },
            { id: 2, path: "OpenCare03_min.png" },
          ],
          // watermarkClass: "products__container__image--triangle-2",
          idTag: "opencareSection",
          classTag: "products__openCareGateway",
          positionLeft: true,
        },

        //TODO: Uncomment when GreenDMS picture is added
        // {
        //   id: 7,
        //   number: "08",
        //   name: "Green DMS",
        //   description: this.$t("greenDMSDescription"),
        //   button: {
        //     text: this.$t("caseStudyLbl"),
        //     color: "blue2",
        //     link: "https://www.greendms.nl/",
        //   },
        //   image: [],
        //   idTag: "greendmsSection",
        //   classTag: "products__greenDMS",
        //   positionLeft: false,
        // }
      ];
    },
    isWindowWidthDesktop() {
      if (this.inWidth <= 960) {
        return false;
      }
      return true;
    },
    colorToScrollPositionMap() {
      // if (this.currentElId === "other") {
      //   this.emitMethod("gray");
      // } else if (this.currentElId === "smartgoalsSection") {
      //   this.emitMethod("yellow");
      // } else if (this.currentElId === "skillcourtSection") {
      //   this.emitMethod("green");
      // } else if (this.currentElId === "safezoneSection") {
      //   this.emitMethod("gray_2");
      // } else if (this.currentElId === "skillgamesSection") {
      //   this.emitMethod("yellow_2");
      // } else if (this.currentElId === "fieldbackSection") {
      //   this.emitMethod("red");
      // } else if (this.currentElId === "playLikeMessiSection") {
      //   this.emitMethod("blue");
      // }
      return {
        //   change_gray: this.currentElId === "other",
        //   change_yellow: this.currentElId === "smartgoalsSection",
        //   change_green: this.currentElId === "skillcourtSection",
        //   change_gray_2: this.currentElId === "safezoneSection",
        //   change_yellow_2: this.currentElId === "skillgamesSection",
        //   change_red: this.currentElId === "fieldbackSection",
        //   change_blue: this.currentElId === "playLikeMessiSection",
        //   // change_blue: this.currentElId === "smartgoalsSection",
      };
    },
    currentSlide() {
      for (let index = 0; index < this.products.length; index++) {
        if (this.products[index].idTag === this.currentElId) {
          return index + 1;
        } else if (this.products[index].idTag === "other") {
          return 0;
        }
      }
      return 0;
    },
  },
  mounted() {
    // TODO: remove when website is done
    //this.testingOverflow();
    if (this.$refs.products) {
      this.$refs.products.addEventListener("scroll", this.updateScroll);
      this.$refs.products.addEventListener("scroll", this.isScrolledToElem);
      this.$refs.products.addEventListener("scroll", this.onScrollHandler);
    }
    window.addEventListener("resize", this.updateInnerWidth);
    this.updateInnerWidth();
  },
  methods: {
    onScrollHandler(event) {
      if (this.resizedSmallerScreen) {
        window.scrollTo(0, 1);
      }
      let x = (event.target.scrollTop / 10) % Math.PI;
      EventBus.$emit("pageScrolled", { theta: x });
    },
    goToPage(link) {
      window.open(
        link,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    emitMethod(payload) {
      EventBus.$emit("colorChange", payload);
    },
    updateInnerWidth() {
      this.inWidth = window.innerWidth;
    },
    updateScroll(event) {
      this.scrollPosition = event.target.scrollTop;
    },
    isScrolledToElem(event) {
      let elOffsetTop = 0;
      let idTags;
      // * looping through all products, calculating the scroll position
      // * from the middle of them to the top and changing the colour based on that
      for (let index = 0; index < this.products.length; index++) {
        idTags = this.products[index].idTag;
        elOffsetTop = this.$refs[idTags][0].offsetTop;

        // if (
        //   event.target.scrollTop > elOffsetTop &&
        //   event.target.scrollTop < elementPoscenter
        // )
        if (
          event.target.scrollTop + 400 <
          this.$refs[this.products[index].idTag][0].offsetTop
        ) {
          this.currentElId = "other";
          return;
        }
        if (index < this.products.length - 1) {
          if (
            event.target.scrollTop + 400 >= elOffsetTop &&
            event.target.scrollTop + 400 <=
            this.$refs[this.products[index + 1].idTag][0].offsetTop
          ) {
            this.currentElId = this.products[index].idTag;
            return;
          }
        } else {
          this.currentElId = this.products[index].idTag;
          return;
        }
      }
    },
    moveScrollTo(scrollPos) {
      switch (scrollPos) {
        case 1:
          window.location.href = "#smartgoalsSection";
          break;
        case 2:
          window.location.href = "#skillcourtSection";
          break;
        case 3:
          window.location.href = "#skillgamesSectionn";
          break;
        case 4:
          window.location.href = "#fieldbackSection";
          break;
        case 5:
          window.location.href = "#prospikeSection";
          break;
        case 6:
          window.location.href = "#enexisSection";
          break;
        default:
          window.location.href = "#opencareSection";
          break;
      }
      this.setUrlBack();
    },
    setUrlBack() {
      setTimeout(() => {
        history.replaceState(null, null, "/#/products");
      }, 50);
    },
    // testingOverflow() {
    //   console.log("works");
    //   var docWidth = document.documentElement.offsetWidth;
    //   [].forEach.call(document.querySelectorAll("*"), function(el) {
    //     if (el.offsetWidth > docWidth) {
    //       console.log(el);
    //     }
    //   });
    // },
  },
  components: {
    Footer,
    Carousel,
    // TechnologiesTable,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";

.products {
  height: calc(100vh - 115.2px);
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond(laptop) {
    height: calc(100vh - 108px);
  }

  @include respond(small-laptop) {
    height: calc(100vh - 101.73px);
  }

  @include respond(tablet-land) {
    height: calc(100vh - 96px);
  }

  @include respond(tablet-port) {
    height: calc(100vh - 72px);
  }

  @media screen and (min-width: 1920px) {
    width: 1920px;
    margin: 0 auto;
  }

  &__section {
    padding: 240px 15rem 240px 15rem;
    transition: 0.6s background-color;
    scroll-snap-align: center;
    min-height: 90vh;

    @include respond(laptop) {
      padding: 10rem 12rem 10rem 12rem;
    }

    @include respond(small-laptop) {
      padding: 0 10rem 0 10rem;
    }

    @include respond(tablet-land) {
      min-height: 80rem;
      padding: 0 8rem 0 8rem;
    }

    @include respond(tablet-port) {
      min-height: 60rem;
      padding: 0 5rem 0 5rem;
    }

    @include respond(phone-land) {
      min-height: 40rem;
      padding: 2rem 3rem 0 3rem;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: 10rem;

    @include respond(tablet-port) {
      flex-flow: row wrap;
    }

    @include respond(phone-land) {
      padding-bottom: 0.5rem;
      flex-flow: row wrap;
      padding-top: 5rem;
    }

    &--reverse {
      flex-direction: row-reverse;
      justify-content: space-evenly;

      @include respond(tablet-land) {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 5rem;
      }

      @include respond(phone-land) {
        padding-bottom: 0.5rem;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;

      @include respond(phone-land) {
        justify-content: center;
      }

      &__number {
        &>h1 {
          position: relative;
          font-family: "Raleway";
          font-size: 14rem;
          font-weight: bold;
          line-height: 1.17;
          -webkit-text-stroke: 3px #000000;
          -webkit-text-fill-color: transparent;
          opacity: 0.8;

          @include respond(phone-land) {
            font-size: 10rem;
            margin-bottom: 5rem;
          }

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1rem;
            background-color: black;
            border-radius: 0.2rem;
          }
        }
      }

      &__main {
        &>h1 {
          font-family: "Raleway";
          font-size: 7rem;
          font-weight: bold;
          line-height: 1.18;
          opacity: 0.8;

          @include respond(phone-land) {
            font-size: 4rem;
          }
        }

        &>p {
          margin-top: 3rem;
          font-family: "Open Sans";
          font-size: 2rem;
          line-height: 1.38;
          max-width: 63rem;
          opacity: 0.6;

          @include respond(small-laptop) {
            max-width: 53rem;
          }

          @include respond(tablet-port) {
            font-size: 2rem;
            max-width: 90%;
          }

          @include respond(phone-land) {
            font-size: 1.6rem;
            max-width: 90%;
          }
        }
      }
    }

    &__image {
      position: relative;
      margin-right: 5rem;
      // width: 70rem;
      display: flex;
      justify-content: center;

      @include respond(small-laptop) {
        margin-top: 15rem;
        margin-right: 5rem;
      }

      @include respond(tablet-port) {
        margin-right: 0rem;
        margin: 0 0 15rem 0;
        width: 100%;
      }

      @include respond(phone-land) {
        margin-top: 1rem;
        margin-right: 0rem;
        width: 100%;
        margin: 0rem;
      }

      &>div:nth-child(1) {
        width: 70rem;
        height: 70rem;
        margin: 0.5rem;
        align-self: flex-end;

        @include respond(small-laptop) {
          width: 60rem;
          height: 60rem;
        }

        @include respond(tablet-port) {
          width: 50rem;
          height: 50rem;
          margin-left: auto;
          margin-right: auto;
        }

        @include respond(phone-land) {
          background-size: 100% auto;
          width: 100% !important;
          //padding-top: 100% !important;
          height: 35rem;
        }
      }
    }
  }

  &__pagination {
    position: fixed;
    top: 50%;
    right: -10rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    transform: rotate(-90deg);

    @include respond(tablet-land) {
      right: -15rem;
    }

    @include respond(phone-land) {
      right: -18rem;
    }

    &__text {
      margin-top: -0.3rem;
      margin-left: 2rem;

      &>p {
        font-family: "Open Sans";
        font-size: 2.3rem;
        line-height: 1.38;
      }
    }

    &__circles {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__bullet {
      cursor: pointer;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      border: 0.2rem solid black;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &--active {
      background-color: black;
    }

    &--active-line {
      position: relative;
      margin-right: 14rem !important;

      &::after {
        content: "";
        position: absolute;
        top: 45%;
        left: 2.6rem;
        width: 11rem;
        height: 0.2rem;
        border-radius: 10rem;
        background-color: black;
      }
    }
  }

  &__hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-gray-2;

    @include respond(tablet-port) {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__img {
      &>div {
        background-image: url("../assets/products_hero.svg");
        width: 70rem;
        height: 50rem;

        @include respond(small-laptop) {
          width: 50rem;
          height: 40rem;
        }

        @include respond(tablet-land) {
          width: 40rem;
          height: 30rem;
        }

        @include respond(tablet-port) {
          width: 50rem;
          height: 40rem;

          margin-top: 5rem;
        }

        @include respond(phone-land) {
          width: 40rem;
          height: 30rem;

          margin-top: 5rem;
        }
      }
    }

    &__heading {
      margin-left: 10rem;
      margin-top: -10rem;

      @include respond(tablet-port) {
        margin-left: 0;
        margin-top: 0;
        text-align: center;
      }

      @include respond(phone-land) {
        margin-left: 0;
        margin-top: 10rem;
        margin-bottom: 10rem;
        text-align: center;
      }

      &>h1 {
        font-family: "Raleway";
        font-size: 8rem;
        line-height: 1;
        color: $color-blue-3;

        @include respond(phone-land) {
          font-size: 5rem;
        }

        &>span {
          color: $color-blue-4;
        }
      }
    }

    &__btn-container {
      margin: 35rem -15.5rem 0 auto;
      transform: rotate(-90deg);

      @include respond(tablet-port) {
        margin: 20rem -15.5rem 0 auto;
      }

      @include respond(phone-land) {
        margin: 5rem -10rem 0 auto;
      }
    }
  }

  &__smartgoalsSection {
    background-color: $color-yellow;
  }

  &__skillcourt {
    background-color: $color-green;
  }

  &__safezone {
    background-color: $color-gray-3;
  }

  &__skillgames {
    background-color: $color-yellow;
  }

  &__fieldback {
    background-color: $color-red-2;
  }

  &__play-like-messi {
    background-color: $color-blue-2;
  }

  &__footer-section {
    scroll-snap-align: center;
  }
}

.change_gray {
  background-color: $color-gray-2;
}

.change_yellow {
  background-color: $color-yellow;
}

.change_green {
  background-color: $color-green;
}

.change_gray_2 {
  background-color: $color-gray-3;
}

.change_yellow_2 {
  background-color: $color-yellow;
}

.change_red {
  background-color: $color-red-2;
}

.change_blue {
  background-color: $color-blue-2;
}
</style>
