import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    inovWith: "Innovation with",
    tech: "technology",
    homeFirstDescription1:
      "Spinnov is a fast-growing design & engineering team skilled in product design,",
    homeFirstDescription2:
      "electronic design, (embedded) software design, cloud applications, and IoT development.",
    homeFirstDescription3: "",
    homeFirstDescription4:
      "We create technological innovations from concept to production which fits businesses in various sectors.",
    scrollDownLbl: "scroll down",
    whatWeDoLbl: "What we do",
    clickOrSwipeLbl: "Click or swipe",
    ourProjectLbl: "Our Projects",
    ourProjectsDescrption:
      "In recent years, the demand for total solutions in high-tech has increased. Due to our partnership with Bestronics BV and their accumulated know-how in the industrial and electronics market - including assembly of Printed Circuit Boards - we could broaden our services further and offer our clients a holistic package from development through manufacturing and delivery to the warehouse.",
    caseStudyLbl: "Case study",
    seeAllOurProjectsLbl: "See all our projects",
    aboutUsLbl: "About us",
    abousUsDescription:
      "It is one thing to come up with the idea and a whole other to bring it to life when your team lacks internal resources. That is where our engineers come into play to provide you with expertise synchronized with the preferred methodology and tools to deliver a high-quality service that feels just like an in-house development process.",
    meetTheTeamBtnLbl: "Meet our team",
    partnersLbl: "References",
    parthenrsDescriptionLbl:
      "From med-tech through agritech, telecom, and automotive to sports and consumer goods, we are honored that many businesses in a broad spectrum of industries trust our solution.",

    //#region projects/product desctiptions

    skillCourtProductDesc: `<p><strong><span data-contrast="auto">Bridging the gap between exercising and gaming to stimulate local communities and get them active.</span></strong><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Sport </span><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">The SmartGoals Urban SkillCourt is an in-house project to support the digitalisation of sports across cities and local communities. We help municipalities to truly innovate within their neighbourhood and stimulate locals to maintain an active lifestyle. Through this smart mobility concept, we not just offer a unique way of playing sports and games for citizens but also help governmental stakeholders and policy makers, to make the smartest decision possible, regarding investments into public sports facilities. The SmartGoals Urban SkillCourts now has three permanent, interactive installations in Amsterdam, Rotterdam and Lichtenvoorde.</span><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Technology: </span></strong><span data-contrast="auto">Wifi, Raspberry Pi, Laravel, Embedded Software</span><span data-ccp-props="{}"> </span></p>`,
    skillGamesDescription: `<p><strong><span data-contrast="auto">Tracking results and scores through sport gamification solutions. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Sport </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">SkillGames is a performance analysis app &amp; web-based solution for football, with a SaaS business model. It utilises a gamified measurement system that collects individual performance data through standardised as well as custom training exercises. With its unique global challenges, SkillGames collects and organises global data from every level, allowing for competition, benchmarking and player improvement. </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">The visual interface of SkillGames is powered by VUE JS while it runs on aPHP Laravel backend, harnessing bluetooth technology. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Laravel, Node.js, PHP </span><span data-ccp-props="{}"> </span></p>`,
    fieldBackDescription: `<p><strong><div data-contrast="auto">Improving player performance through video analysis. </div></strong></p>
    <p><strong><div data-contrast="auto">Industry: </strong>Sport </div></p>
    <p><div data-contrast="auto">The Fieldback system automatically records matches and takes sports games to the next level. </div></p>
    <p><div data-contrast="auto">We built the system and algorithms of an instant performance analysis tool from scratch that automatically records and classifies matches to create highlights from the most meaningful on-court actions. Our wireless web-based camera system and the cloud-based application instantly process moments from various camera positions so coaches and trainers can analyze the progress of the team and can optimize their training schedule based on the results.  </div></p>
    <p><div data-contrast="auto">The system works with webcams and HD cameras which record in 4K and capture the action at a wide 180-degree angle. </div></p>
    <p><strong><div data-contrast="auto">Technology: </strong>Cloud based video, IP cameras, Online video editing</div></p>`,
    smartTrailerDescription: `<p><strong><span data-contrast="auto">Bringing a new level of efficiency to trailer transportation operations. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Transport </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">We provided our client with a solution that helps increase your safety on the road by improving your vehicle's maneuverability. </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">The Smart trailer monitoring system allows you to observe real-time data regarding your mounted trailer connection, the status of your light, tire pressure, and GPS data while you are driving. We also designed and developed the cloud-based system and the app. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto"> Technology:</span></strong><span data-contrast="auto"> Bluetooth, GPS, Application, IoT</span><span data-ccp-props="{}"> </span></p>`,
    projectSmartGoalsDesc: `<p><strong><span data-contrast="auto">Building spatial awareness and sport-specific skills during unpredictable movements.</span></strong><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">  </span><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Sport</span><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">SmartGoals is our in-house IP-protected infrared and sensor technology, complemented with a Bluetooth communication protocol. As soon as a body part or a ball passes through the smart-lighting gates, the built-in sensor automatically transmits the signal to the next pair of cones, measuring the effectiveness of the exercise with an accuracy of 0.01 seconds.</span><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">With visual stimuli and unexpected twists, SmartGoals requires sharp concentration and creativity from players. However, the system not only tests performance, but also improves the players’ vision, and decision-making in terms of speed and quality, reaction time, agility, cognitive skills, and collective problem-solving.</span><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Technology: </strong>Radio Frequency, Embedded Software, Bluetooth</span><span data-ccp-props="{}"> </span></p>`,
    projectPROSpikeDesc: `<p><strong><span data-contrast="auto">Developing an innovative vehicle-stop system with end-user safety measures.</span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry</span></strong><span data-contrast="auto">: Governance </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">PROSpike STINGER hardware and software allows for remote, delayed, and repetitive launch, for traffic control involving border checkpoints, roadblocks, security perimeters, or other remote environments where unauthorized vehicle entry is not allowed. </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Our team was trusted to develop and build the hardware, the Central Processor Unit (CPU), the battery, and the remote control that provides continuous system status about battery capacity, pressure, and possible disturbances on the LCD screen of the remote control. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Radio frequency communication, Control system </span><span data-ccp-props="{}"> </span></p>`,
    enexisDescription: `<p><strong><span data-contrast="auto">Turning training into a real-life simulation to increase interactivity, engagement, and memorability. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Energy </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">We designed a learning environment for our clients to guarantee more realistic and rewarding training for their engineers. Our installed training system includes features that help delegates to be exposed to situations as close to real-life emergencies as possible. The implemented training method challenges participants and makes progress visible and measurable. It also brings clarity and familiarity to new situations. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> VueJS, Node.js,  EPS8266, Embedded Wifi, TFT/LCD/Oled technology, Mechanical design </span><span data-ccp-props="{}"> </span></p>`,
    openCareGatewayDescription: `<p><strong><span data-contrast="auto">Supporting healthcare organizations with digital transformation. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry: </span></strong><span data-contrast="auto">Healthcare </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">We helped to develop a cloud-based software linked with a hardware solution to provide healthcare automation. This rich ecosystem provides healthcare organizations data, in addition to various solutions in the field of prevention, vitality, and safety. We developed the motherboard the healthcare technology. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Raspberry Pi, Audio Chips, RS428, RS485</span><span data-ccp-props="{}"> </span></p>`,

    //TODO: Uncomment when picture is added
    // greenDMSDescription: `<p><strong><span data-contrast="auto">Improving urban forestry through innovative IoT solutions. </span></strong><span data-ccp-props="{}"> </span></p>
    // <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Government </span><span data-ccp-props="{}"> </span></p>
    // <p><span data-contrast="auto">Municipalities of the Netherlands face the problem of one out of three trees dying in an urban environment. To find a solution and ensure the tree patches' long-lasting health, Green DMS partnered with seventeen pilot parties, including businesses and municipalities, to conduct a significant data analysis using the data gathered by soil sensors in Apeldoorn, Nieuwegein, and Tilburg. Our team helped design and develop the low-cost sensor systems installed through cities combined with software algorithms to regularly monitor the soil's gas, water, CO2, methane, and oxygen content. Currently almost 500 of these soil sensors are installed across the country. </span><span data-ccp-props="{}"> </span></p>
    // <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Waterproof IP65, Temperature and EC Microsensor, Bluetooth, Raspberry Pi, IoT </span><span data-ccp-props="{}"> </span></p>`,

    //#endregion

    //#region projects/electronics description

    projectTessaDesc: `<p><strong><span data-contrast="auto">Developing a care taking robot that helps people with Alzheimer's to get rhythm during the day.  </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Healthcare </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Tessa provides comfort and cares through notifications and communication created by close family. For this project, we have developed the electronics inside Tessa that connects to a cloud-based infrastructure that manages the device and efficiently stores any kind of data like music, speech, or memories.  </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Wifi, Internet, Bluetooth, Linux, Raspberry Pi, IoT</span><span data-ccp-props="{}"> </span></p>`,

    floatPlusElecDescription: `<p><strong><span data-contrast="auto">Upgrading electric motor solutions to make fishing more comfortable, more accessible and more professional.&nbsp;</span></strong><span data-ccp-props="{}">&nbsp;</span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Fishing&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
    <p><span data-contrast="auto">Our team selected the technology stack, designed the architecture, and built the electric motor which is made of strong and specially developed polycarbonate filled with glass fiber. The FloatPlus system consists of one case with a battery, control unit, and charging point for the wireless remote control with a full-color LCD screen. We also paid utmost attention to creating a user-friendly interface, optimizing performance, and limiting battery drain.&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Bluetooth, Display, Power motor control, Applications</span><span data-ccp-props="{}">&nbsp;</span></p>`,

    smartFloorDescription: `<p><strong><span data-contrast="auto">Reducing fall and injury risk for elderly people. </span></strong></p>
      <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Healthcare </span><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">The Smart Floor is a proven measuring instrument (with a CE mark) for motion analysis to reduce fall and injury risk and also to detect forms of dementia in the early stage. Our team developed and implemented embedded hardware of sensors (film and wearable) that automatically derive skeletal alignment data and movement information (position, orientation, speed, acceleration) from foot-floor contact of the person in the integrated environment. This system has been designed to closely synchronize with an application to help rehabilitation projects for disabled people with foot or motor-control disorders. </span><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Wifi, Low power, Long-rangerfid, Battery</span><span data-ccp-props="{}"> </span></p>`,

    monoaDescription: `<p><strong><span data-contrast="auto">Improving wearable connectivity of a stress level measuring device. </span></strong><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Healthcare </span><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">We helped to improve an unique wearable that tracks your stress level all day, accompanied by a coaching platform/app where your stress data is analyzed to see the impact of stress on your physical and mental health. We used galvanic skin response which measures the electrodermal activity of your skin and its temperature throughout the day to track your stress level. By doing this 24/7 it detects in a very accurate way the effectiveness of your stress rise and decompression. Besides that, it also measures steps, distance, calories, and activities. </span><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Bluetooth, Accelerometer, Analogfiltering, Lowpower</span></p>`,

    inicareDescription: `<p><strong><span data-contrast="auto">Providing advanced smart patient care through automated and continuous vital signs monitoring. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Health care </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Our solution helps measure heart rate, blood pressure, and saturation monitoring of patients in the clinic and at home. The clinically validated measuring device offers a real-time overview of all patients and their status while also providing accurate and continuous monitoring of the patient's vital function. The technology consists of an intelligent disposable strap, a smart box, and a patient portal - which wirelessly communicates via a secure low power. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Tecnology:</span></strong><span data-contrast="auto"> Raspberry Pi, Bluetooth, nfc, 1D and 2D barcodes</span></p>`,

    fysiconDesc: `<p><strong><span data-contrast="auto">Ensure the safety and reliability of blood pressure meter to meet the highest standards of medical devices. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Healthcare </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Our team of engineers and designers delivered a state-of-the-art interface for the blood pressure meter. We designed a PCB with the utmost care and attention to detail, using the latest technologies and materials to ensure that it met the highest standards of safety and reliability and pass the stringent CE tests required for medical devices. Our team also worked closely with Fysicon to create a user-friendly interface that would meet the needs of medical professionals, even in the high-stress environment of the operating room. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Analog circuits, Galvanic Isolation</span><span data-ccp-props="{}"> </span></p>`,

    tapliteDesc: `<p><strong><span data-contrast="auto">A programmable LED array that shows different and consistent light patterns on a tap. </span></strong><span data-ccp-props="{}"> </span></p>	
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Consumer goods </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Our team collaborated with Taplite to deliver a one-of-a-kind PCB solution that controls the lighting behind beer taps. By harnessing the power of a programmable LED array, our engineers created consistent and customizable light patterns on each tap. It not only adds a visually stunning touch to any bar or brewery - it also enhances the customer experience by making it easier to identify different beer brands. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Microcontroller, Embedded software</span><span data-ccp-props="{}"> </span></p>`,

    //#endregion

    //#region projects/software description

    htmSoftDesc: `<p><strong><span data-contrast="auto">Improving public transport vehicle communication and control. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Governance </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">We designed and implemented vehicle communication software in trams and busses of Den Haag to regulate safety and contribute to the operational performance, improving travel time, and comfort of public transportation. Its principal function is to exchange real-time information between vehicles and the central control room. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology: </span></strong><span data-contrast="auto">Tetra / Portofonie, C# Applications, Windows Services, Embedded Software (Linux), RS232, RS485</span><span data-ccp-props="{}"> </span></p>`,

    gmsSoftDesc: `<p><strong><span data-contrast="auto">Bringing citizens quicker and more efficient emergency assistance. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Governance </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Control rooms are available 24/7 and have a critical role in assisting people in need and support from emergency services. Our team designed, built, and installed the software that links the integrated telephony system with the ticketing system. The cloud-based system and its user-friendly interface enable reliable multi-channel data communication across large-scale operations to provide high-quality support and meet the safety needs of the public. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Windows Server, Typescript, ASN-1, Node.js. MySQL, Vue.JS </span><span data-ccp-props="{}"> </span></p>`,

    woltringDesc: `<p><strong><span data-contrast="auto">Building a fair and transparent performance review process for organizations. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Education </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">We built an employee evaluation platform that makes the performance management process automated and simple. The HR system was designed in a way that helps to create and sustain an engaging and growth-oriented feedback culture. It also allows managers to recognize competencies and hidden talent easily while keeping communication smooth and effective. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Laravel (PHP), NodeJs &amp; VueJS </span><span data-ccp-props="{}"> </span></p>`,

    movingIntelligenceDesc: `<p><strong><span data-contrast="auto">Helping people to manage their means of transport through real-time data and security. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Security </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Services With the Moving Intelligence app you have full control over all your means of transport and mobile objects. If your car, boat, motorcycle or other means of transport is equipped with a Moving Intelligence system, you can keep control over your trip logging, follow all your objects real time or operate your immobilizer. Always and anywhere. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Bluetooth</span><span data-ccp-props="{}"> </span></p>`,

    aceChallengeDescription: `<p><strong><span data-contrast="auto">Revolutionizing professional golf experience with unique challenges. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Sport </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Our frontend and backend developers built an Android and iOS application that supports players with real-time monitoring and elevate their experience using artificial intelligence. With the feature set implemented the idea was to create a betting challenge for players from all levels throughout the gamified platform and the UI elements, while simultaneously improving your swing technique with personalized videos. The Ace Challenge is the perfect blend of challenge and entertainment, making every round of golf a truly unforgettable experience with an extra layer of excitement and competition. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> IP Camera’s, FFMPEG, Laravel (PHP), VueJS, Cordova &amp; Quasar </span><span data-ccp-props="{}"> </span></p>`,

    //#endregion

    swiperProductLbl: "Product Design",
    swiperSoftwareLbl: "Software Design",
    swiperElectronicLbl: "Electronic Design",
    spinnovLbl: "Spinnov",
    followUsLbl: "Follow us",
    ourOfficeLbl: "Our offices",
    addressPartOne: "De Run 4547",
    addressPartTwo: "5503 LT Veldhoven",
    addressSecondPartOne: "Torenallee 32-06",
    addressSecondPartTwo: " 5617 BD Eindhoven ",
    getInTouchLbl: "Get in touch",
    spinnovEmail: "info@spinnov.com ",
    spinnovNumber: "(+31) 40 340 01 41",
    scrollUpLbl: "scroll up",
    projectsFootLbl: "Projects",
    skillsFootLbl: "Skills",
    teamFootLbl: "Team",
    contactFootLbl: "Contact",
    jobsFootLbl: "Vacancies",
    hiLetsTalkLbl: "Hi, let's talk!",
    contactUsByLbl: "Contact us by:",
    contactPhoneNumber: "(+31) 40 340 01 41",
    contactEmailSpinnov: "info@spinnov.com",
    orByFormLbl: "Or by form:",
    yourNameLblForm: "Your name:",
    yourEmailAddressFormLbl: "Your Email address:",
    textArealblForm: "Leave us a message:",
    weNeedYourEmailAddrToLbl: "We need your email address to contact you",
    doYouLikeUsLbl: "Do you like us?",
    joinOurTeamLbl: "Join our team",
    checkOutOffersLbl: "Check our offers",
    electronicsHeading1: "Check out our designed",
    electronicsHeading2: "electronics",
    clickOrScrollLbl: "Click or scroll",
    checkTheWebsiteLbl: "Check website",
    checkTheProductLbl: "Check the product",
    joinSpinnovLbl: "Join Spinnov",
    jobsWeAreCompanyLbl:
      "Spinnov is a fast growing design & engineering in the heart of the Brainport region. Spinnov is specialized in creating innovation with technology.  Innovations in different fields and branches are created, designed and produced by Spinnov. You can be part of this innovation cycle from idea to product or for challenging improvements on existing products.  Spinnov is working for different markets (Sports, healthcare, automotive, IoT, industry among others).",
    jobsPartOfUsLbl: "Do you want be part of the team? Apply now.",
    currentOffersLbl: "Current vacancies",
    sendMassageBtn: "Send message",
    someLbl: "Some example",
    projectsLbl: "projects ",
    weLbl: "we ",
    haveDoneLbl: "have developed",
    seeMoreLbl: "See more",
    productMenuLbl: "Product",
    electronicMenuLbl: "Electronics",
    softwareMenuLbl: "Software",
    productTextDesc:
      "The development of a complete product. This is a journey from idea to mass produced product. Every aspect on the development road has been taken care of.",
    softwareTextDesc:
      "Software development in different fields. In today's world software is everywhere. It is in products, applications and services. Every link to the internet. Checkout our connecting software examples.",
    electronicTextDesc:
      "Electronic development and linking it is our core. The hearth of a technological product is often the electronics. That is why this part deserves extra attention. Electronics is not only hardware design, but also the embedded firmware that drives it.",
    checkOutOurLbl: " Check out our designed ",
    ourProductsLbl: "Products",
    downloadTheAppLbl: "Download app",
    checkGameLbl: "Check game",
    aboutElectronicDesign: "About Electronic Design",
    aboutElecDesc:
      "From idea and prototyping to full PCB solutions. Electronics can be pretty complex unless of course, you have a team of tech gurus who live and breathe innovation. Our team of Electronic Engineers and Designers has extensive knowledge of electronic designs, circuit layouts, hardware, and embedded firmware for different design challenges.<br><br> Our expertise includes: <br><ul><li> System design</li><li> Schematics design</li><li> PCB Layout design</li><li> Analysis system</li><li> Cloud computing </li></ul>",
    elecIsNotLimLbl: "Electronics design is not limited to hardware design.",
    checkOutElec: "Check out electronics",
    aboutSoftwareDesignLbl: "About Software Design",
    abotSoftwareDesc:
      "Fast, affordable, and flexible solutions. We create computer software, cloud-based software, software for mobile and browser with seamless integration and efficiency. Our proven technologies and secure solutions fit organizations of varied sectors. From the very start, we develop in-house, end-to-end production which agnostic, scalable and reliable for groundbreaking custom IoT ideas.<br><br>Our expertise includes: <br><ul> <li>  IoT connectivity  </li> <li>  IoT device management  </li> <li>  Application management  </li> <li>  Cloud applications  </li> </ul>  ",
    softwareIsAVeryLbl: "Software is a very",
    broadWorkingAreaLbl: "broad working area.",
    softSpinnovHasExpDesc:
      "Spinnov has experience in computer software, software in the cloud, on the mobile phone, in the browder or as a service. Making the world connected is what Spinnov does.",
    checkOutSoftwareLbl: "Check out software",
    aboutProductDesignLbl: "About Product Design",
    aboutProductDesignDesc:
      "From concept to production. From simple to complex projects, from small to robust. We map, sketch, decide, needed prototype, test, integrate and manage our product lifecycle. We provide solutions at all the different stages of the development process.<br><br>Under our portfolio, we have performance tracking and interactive training solutions such as smart cones and smart fitness installations,video analysis devices such as wireless web-based camera systems,motion analysis devices, agricultural and healthcare technologies.<br><br> Our expertise includes: <br><ul><li> New product development</li><li> Industrial design</li><li> UX/UI design</li><li> Mechanical Engineering</li><li> Electrical Engineering</li><li> Software Engineering</li><li> Hardware design</li></ul>",
    itsMoreThanAProductLbl: "It is more than just the product",
    itsMoreThanProductDesc:
      "A successful product is so much more than just the product. It starts with a good declaration of the market, the functionalities, the needs and the users. When all the needs, the value proposition is clear, than the concept design can start. Concept sketching, prototyping, proof of concepts, user testing and validation, product engineering, production engineering, certification can all be part of the work.",
    checkOutProductsBtn: "Check out our products",
    interestedLbl: "Interested?",
    writeToUsLbl: "Write to us",
    contactUsLbl: "Contact",
    ourAwesomeLbl: "Our awesome ",
    softwareAppsLbl: "software apps",

    meetOurTeemLbl: "Meet our team",
    teamDesc:
      "After 11 years spent creating technological innovations in different fields of the industry, we strive to support our customers through cost-efficient and scalable solutions to help them leverage their competitive strength. Our international engineering team of the Dutch Brainport High Tech Region is always on the go to stay relevant on the latest technology, industry trends, and best practices. ",
    ourTeamLbl: "Our Team",
    applyNow: "Apply now",
    applyLbl: "Apply",
    aboutThePosition: "About the position",
    perfectCandidateLbl: "Perfect candidate",
    whatWeOfferYouLbl: "What we offer you",
    weWantToMeetYou:
      "We want to meet you, complete the fields and send us your application",
    pleaseUpluadAPdfFile: "Please upload a pdf file",
    addYourCvLbl: "Add your CV",
    uploadLbl: "Upload",
    thankYouFor: "Thank you for contacting us",
    errSubmittingContact: "Error submitting the contact form",
    applyNowLblHeader: "Apply now",
  },
  nl: {
    inovWith: "Innovatie met",
    tech: "technologie",
    homeFirstDescription1: "Spinnov is een snelgroeiend design & engineerings bureau, gespecialiseerd in product ontwerp,",
    homeFirstDescription2: "electronisch ontwerp, (embedded) software ontwerp, cloud applicaties en IoT ontwerp.",
    homeFirstDescription3: "We maken technologische innovaties van concept tot productie, geschikt voor bedrijven in diverse sectoren.",
    homeFirstDescription4: "",
    scrollDownLbl: "Scroll naar beneden",
    whatWeDoLbl: "Wat doen we?",
    clickOrSwipeLbl: "Klik of swipe",
    ourProjectLbl: "Onze projecten",
    ourProjectsDescrption:
      "In de afgelopen 5 jaar heeft Spinnov in vele markten gewerkt. Soms als onderdeel van een ontwikkeling en soms de totale ontwikkeling. Hier zie je een glimp van het werk wat we hebben gedaan.",
    caseStudyLbl: "Case study",
    seeAllOurProjectsLbl: "Bekijk al onze projecten",
    aboutUsLbl: "Over ons",
    abousUsDescription:
      "Spinnov is een jong dynamisch professioneel multidisciplinair internationaal wendbaar technisch ontwikkelaars team. Wat een mond vol. ;) Onthoud maar: wij maken jou technische dromen waar. Als je iets gemaakt wilt hebben kom naar Eindhoven. ",
    meetTheTeamBtnLbl: "Ontmoet ons team",
    partnersLbl: "Partners",
    parthenrsDescriptionLbl:
      "Vandaag de dag is samenwerken het toverwoord. Je hebt partners nodig op verschillende vlakken. We zijn blij met de partners die we om ons heen hebben verzameld om onze wereld te innoveren. Mocht je willen bel ze gerust.",
    //#region projects/product desctiptions

    skillCourtProductDesc: `<p><strong><span data-contrast="auto">Voetbal en lichamelijke opvoeding innovatief maken om lokale gemeenschappen te stimuleren en steden slimmer te maken.</span></strong><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Sport </span><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">Het SmartGoals Urban SkillCourt is een intern project ter ondersteuning van de digitalisering van sport in steden en lokale gemeenschappen. Wij helpen gemeenten echt te innoveren binnen hun wijk en stimuleren de lokale bevolking tot een actieve levensstijl. Met dit slimme mobiliteitsconcept bieden we niet alleen burgers een unieke manier van sporten en spelen, maar helpen we ook overheidsstakeholders en beleidsmakers om de zo best mogelijke beslissng te nemen over inversteringen in openbare sportfaciliteiten. Het SmartGoals Urban SkillCourt heeft inmiddels drie interactieve installaties in Amsterdam, Rotterdam en Lichtenvoorde.</span><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Technologie: </span></strong><span data-contrast="auto">Wifi, Raspberry Pi, Laravel, Embedded Software</span><span data-ccp-props="{}"> </span></p>`,
    skillGamesDescription: `<p><strong><span data-contrast="auto">Resultaten en scores bijhouden door middel van sportgamificatie oplossingen.</span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Sport </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">SkillGames is een prestatieanalyse-app en webgebaseerde oplossing voor voetbal, met een SaaS-bedrijfsmodel. Het maakt gebruik van een gegamificeerd meetsysteem dat individuele prestatiegegevens verzamelt via zowel gestandaardiseerde als aangepaste trainingsoefeningen. Met zijn unieke mondiale uitdagingen verzamelt en organiseert SkillGames mondiale gegevens van elk niveau, waardoor wedstrijden, benchmarking en spelersverbetering mogelijk zijn. </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">De visuele interface van SkillGames wordt mogelijk gemaakt door VUE JS terwijl het draait op een PHP Laravel-Backend, waarbij gebruik wordt gemaakt van Bluetooth-technologie. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Laravel, Node.js, PHP </span><span data-ccp-props="{}"> </span></p>`,
    fieldBackDescription: `<p><strong><div data-contrast="auto"> Het Fieldback-systeem is een online video analyse tool. Fieldback brengt video analyse op het veld. Dit online systeemen maakt het mogelijk om beelden op te nemen en later terug te kijken. De beelden kunnen ook met het team gedeeld worden. dit maakt het makkelijk om beelden binnen het team te delen. </div></strong></p>
    <p><strong><div data-contrast="auto">Industrie: </strong>Sport </div></p>
    <p><div data-contrast="auto">Het Fieldback-systeem registreert automatisch wedstrijden en tilt het spel naar een hoger niveau. </div></p>
    <p><div data-contrast="auto">We hebben een betaalbaar systeem en algoritmen gebouwd voor een directe prestatieanalysetool. Wedstrijden worden automatisch geregisteerd en geclassificeerd om hoogtepunten van de belangrijkste acties op het veld te creëren. Bovendien laten het draadloze webgebaseerde camerasysteem en de cloudgebaseerde applicatie de video van het zojuist gepasseerde moment zien, vanuit verschillende cameraposities, aan coaches en trainers. Zo kan de speler direct feedback op zijn spel krijgen.  </div></p>
    <p><div data-contrast="auto">Het systeem werkt met webcams tot en met HD-camera's die opnemen in 4K en de actie vastleggen in een brede hoek van 180 graden. </div></p>
    <p><strong><div data-contrast="auto">Technologie: </strong>Cloud-gebaseerde video, IP-camera's, online videobewerking</div></p>`,
    smartTrailerDescription: `<p><strong><span data-contrast="auto">Bringing a new level of efficiency to trailer transportation operations. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Transport </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">We provided our client with a solution that helps increase your safety on the road by improving your vehicle's maneuverability. </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">The Smart trailer monitoring system allows you to observe real-time data regarding your mounted trailer connection, the status of your light, tire pressure, and GPS data while you are driving. We also designed and developed the cloud-based system and the app. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto"> Technology:</span></strong><span data-contrast="auto"> Bluetooth, GPS, Application, IoT</span><span data-ccp-props="{}"> </span></p>`,
    projectSmartGoalsDesc: `<p><strong><span data-contrast="auto">Het opbouwen van ruimtelijk bewustzijn en sportspecifieke vaardigheden tijdens onvoorspelbare bewegingen in een continue veranderde situatie.</span></strong><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">  </span><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Sport</span><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">SmartGoals is onze interactieve trainingsmethode met infrarood- en sensortechnologie, aangevuld met een Bluetooth-communicatieprotocol. Zodra een lichaamsdeel of een bal door de slimme lichtpoortjes gaat, stuurt de ingebouwde sensor het signaal automatisch door naar het volgende poortje, waardoor de effectiviteit van de oefening met een nauwkeurigheid van 0,01 seconde gemeten wordt.</span><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">Met visuele prikkels en onverwachte wendingen vereist SmartGoals scherpe concentratie en creativiteit van de spelers. Het systeem test echter niet alleen vaardigheden, maar verbetert ook het kijkgedrag, de besluitvorming, snelheid en kwaliteit van de spelers, evenals reactiesnelheid, wendbaarheid, behendigheid, cognitieve vaardigheden en teamspel.</span><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Technologie: </strong>Radiofrequentie, Embedded Software, Bluetooth</span><span data-ccp-props="{}"> </span></p>`,
    projectPROSpikeDesc: `<p><strong><span data-contrast="auto">Een innovatief voertuigstopsysteem met veiligheidsmaatregelen voor eindgebruikers, zoals politieagenten, militairen en veiligheidsagenten. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie</span></strong><span data-contrast="auto">: Overheid </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">PROSpike STINGER hardware en software maken externe, vertraagde en herhaalde activering mogelijk voor een verkeerscontrole met grenscontroles, wegversperringen, veiligheidsperimeters of andere afgelegen omgevingen waar ongeautoriseerde toegang van voertuigen verboden is. </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Ons team heeft de hardware, de Central Processor Unit (CPU), de batterij en de afstandsbediening ontwikkeld en gebouwd. Het LCD-scherm van de afstandsbediening geeft continue de systeemstatus weer van de batterijcapaciteit, druk en mogelijke storingen. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Radiofrequentiecommunicatie, besturingssysteem </span><span data-ccp-props="{}"> </span></p>`,
    enexisDescription: `<p><strong><span data-contrast="auto">Training omzetten in een real-life simulatie om de interactiviteit, betrokkenheid en onthoudbaarheid te vergroten. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Energie </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">We hebben een leeromgeving voor onze klanten ontworpen om een meer realistische en lonende training voor hun ingenieurs te garanderen. Ons geïnstalleerde trainingssysteem bevat functies waarmee deelnemers blootgesteld kunnen worden aan situaties die zo dicht mogelijk bij noodsituaties uit het hedendaagse leven liggen. De geïmplementeerde trainingsmethode daagt deelnemers uit en maakt voortgang zichtbaar en meetbaar. In nieuwe situaties brengt het ook duidelijkheid en meer vertrouwen. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> VueJS, Node.js,  EPS8266, Embedded Wifi, TFT/LCD/Oled technologie, Mechanisch ontwerp </span><span data-ccp-props="{}"> </span></p>`,
    openCareGatewayDescription: `<p><strong><span data-contrast="auto">Zorgorganisaties ondersteunen met digitale transformatie. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie: </span></strong><span data-contrast="auto">Gezondheidszorg </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">We hebben geholpen bij het ontwikkelen van cloud gebaseerde software gekoppeld aan een hardware oplossing om gezondheidszorgautomatisering te bieden. Dit ecosysteem levert zorgorganisaties data, naast diverse oplossingen, op het gebied van preventie, vitaliteit en veiligheid. We hebben het moederbord en de gezondheidszorgtechnologie ontwikkeld. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Raspberry Pi, audiochips, RS428, RS485</span><span data-ccp-props="{}"> </span></p>`,

    //TODO: Uncomment when picture is added
    // greenDMSDescription: `<p><strong><span data-contrast="auto">Improving urban forestry through innovative IoT solutions. </span></strong><span data-ccp-props="{}"> </span></p>
    // <p><strong><span data-contrast="auto">Industry:</span></strong><span data-contrast="auto"> Government </span><span data-ccp-props="{}"> </span></p>
    // <p><span data-contrast="auto">Municipalities of the Netherlands face the problem of one out of three trees dying in an urban environment. To find a solution and ensure the tree patches' long-lasting health, Green DMS partnered with seventeen pilot parties, including businesses and municipalities, to conduct a significant data analysis using the data gathered by soil sensors in Apeldoorn, Nieuwegein, and Tilburg. Our team helped design and develop the low-cost sensor systems installed through cities combined with software algorithms to regularly monitor the soil's gas, water, CO2, methane, and oxygen content. Currently almost 500 of these soil sensors are installed across the country. </span><span data-ccp-props="{}"> </span></p>
    // <p><strong><span data-contrast="auto">Technology:</span></strong><span data-contrast="auto"> Waterproof IP65, Temperature and EC Microsensor, Bluetooth, Raspberry Pi, IoT </span><span data-ccp-props="{}"> </span></p>`,

    //#endregion

    //#region projects/electronics description

    projectTessaDesc: `<p><strong><span data-contrast="auto">Tessa is een zorgassistent die mensen met cognitieve problematiek helpt door middel van verbale begeleiding gedurende de dag.  </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Gezondheidszorg </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Tessa ondersteund bij het behouden/terugkrijgen van zelfredzaamheid en reigenregie. Dit doet Tessa door het geven van verbale herinneringen en instructies voor bijvoorbeeld het innemen van medicatie of het attenderen op het prikken van de bloedsuikers. Familie en/of de wijkverpleegkundige kan ook direct een bericht naar Tessa sturen en deze uit laten spreken. Tessa maakt het mogelijk dat cliënten langer zelfstandig thuis kunnen blijven wonen. We hebben de elektronica in Tessa ontwikkeld die verbinding maakt een cloud-gebaseerde infrastructuur die het apparaat beheert en efficiënt alle soorten gegevens opslaat, zoals muziek, spraak en herinneringen. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Wifi, internet, Bluetooth, Linux, Raspberry Pi, IoT</span><span data-ccp-props="{}"> </span></p>`,

    floatPlusElecDescription: `<p><strong><span data-contrast="auto">Het upgraden van elektromotoroplossingen om het vissen comfortabeler, toegankelijker en professioneler te maken.&nbsp;</span></strong><span data-ccp-props="{}">&nbsp;</span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Visserij&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
    <p><span data-contrast="auto">Ons team selecteerde de technology stack, ontwierp de architectuur en bouwde de elektrische motor die is gemaakt van sterk en speciaal ontwikkeld polycarbonaat gevuld met glasvezel. Het FloatPlus systeem bestaat uit één koffer met daarin een accu, bedieningsunit en oplaadpunt voor de draadloze afstandsbediening met een full-color LCD-scherm. We hebben ook veel aandacht besteed aan het creëren van een gebruiksvriendelijke interface, het optimaliseren van de presentatie en het beperken van het batterijverbruik.&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Bluetooth, scherm, Motorbediening, applicaties</span><span data-ccp-props="{}">&nbsp;</span></p>`,

    smartFloorDescription: `<p><strong><span data-contrast="auto">Vermindering van het val- en letselrisico voor ouderen. </span></strong></p>
      <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Gezondheidszorg </span><span data-ccp-props="{}"> </span></p>
      <p><span data-contrast="auto">De Smart Floor is een bewezen meetinstrument (met CE-markering) voor bewegingsanalyse om het val- en letselrisico te beperken, maar ook om vormen van dementie in een vroeg stadium te kunnen ontdekken. Ons team ontwikkelde en implementeerde Embedded Hardware van sensoren (film en draagbaar) die automatisch gegevens over de uitlijning van het skelet en bewegingsinformatie (positie, oriëntatie, snelheid, versnelling) afleiden uit voet-vloercontact van de persoon in de geïntegreerde omgeving. Dit systeem is ontworpen om te kunnen synchroniseren met een applicatie ter ondersteuning van revalidatieprojecten voor gehandicapten met voet- of motorische stoornissen.  </span><span data-ccp-props="{}"> </span></p>
      <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Wifi, Laag stroomverbruik, Lange afstandsbeveiliging, Batterij</span><span data-ccp-props="{}"> </span></p>`,

    inicareDescription: `<p><strong><span data-contrast="auto">Het bieden van geavanceerde slimme patiëntenzorg door middel van geautomatiseerde en continue monitoring van vitale functies. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Gezondheidszorg </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Onze oplossing helpt bij het automatiseren van de hartslag, bloeddruk en saturatiemonitoring van patiënten in het ziekenhuis en thuis. Het klinisch gevalideerde meetapparaat biedt een realtime overzicht van alle patiënten en hun status en biedt tegelijkertijd nauwkeurige en continue monitoring van de vitale functies van de patiënt. De technologie bestaat uit een intelligente wegwerpband, slimme box en een patiëntenportaal dat draadloos communiceert via een veilig en laag vermogen. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Raspberry Pi, Bluetooth, nfc, 1D and 2D barcodes</span></p>`,

    fysiconDesc: `<p><strong><span data-contrast="auto">Zorg ervoor dat de veiligheid en betrouwbaarheid van de bloeddrukmeter voldoet aan de hoogste normen voor medische apparatuur. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Gezondheidszorg </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Ons team van ingenieurs en ontwerpers heeft een modern interface voor de bloeddrukmeter geleverd. We hebben een PCB met de grootste zorg en aandacht voor detail ontworpen. We hebben gebruik gemaakt van de nieuwste technologieën en materialen om ervoor te zorgen dat het product voldoet aan de hoogste normen op het gebied van veiligheid en betrouwbaarheid, maar dat hij ook voldoet aan de strenge CE-tests die vereist zijn voor medische apparaten. Ons team heeft nauw samengewerkt met Fysicon om een gebruiksvriendelijke interface te creëren die voldoet aan de behoeften van medische professionals, zelfs in de stressvolle omgeving van de operatiekamer. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Analoge circuits, Galvanische Isolatie</span><span data-ccp-props="{}"> </span></p>`,

    tapliteDesc: `<p><strong><span data-contrast="auto">Een programmeerbare LED-reeks die verschillende en consistente lichtpatronen op een tap laat zien. </span></strong><span data-ccp-props="{}"> </span></p>	
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Consumptiegoederen </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Ons team heeft samengewerkt met Taplite om een unieke PCB-oplossing te leveren die de verlichting achter biertaps regelt. Door de kracht van een programmeerbare LED-reeks te benutten, creëerden onze ingenieurs consistente en aanpasbare lichtpatronen bij elke tap. Het voegt niet alleen een mooie touch toe aan elke bar of brouwerij, maar het verbetert ook de klantervaring doordat het gemakkelijker wordt om verschillende biermerken te identificeren. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Microcontroller, Embedded software</span><span data-ccp-props="{}"> </span></p>`,

    //#endregion

    //#region projects/software description

    htmSoftDesc: `<p><strong><span data-contrast="auto">Verbetering van de communicatie en besturing van voertuigen in het openbaar vervoer. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Overheid </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">We hebben voertuigcommunicatiesoftware ontworpen en geïmplementeerd in trams en bussen die rijden in Den Haag. Hiermee wordt de veiligheid gereguleerd en wordt bijgedragen aan de operationele prestaties, het verbeteren van de reistijd en het comfort van het openbaar vervoer. De belangrijkste functie is het uitwisselen van realtime informatie tussen voertuigen en de centrale controlekamer. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie: </span></strong><span data-contrast="auto">Tetra / Portofonie, C# applicaties, Windows Services, Embedded Software (Linux), RS232, RS485</span><span data-ccp-props="{}"> </span></p>`,

    gmsSoftDesc: `<p><strong><span data-contrast="auto">Burgers sneller en efficiënter noodhulp verlenen. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Overheid </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Controlekamers zijn 24/7 beschikbaar en spelen een cruciale rol bij het helpen van mensen in nood en bij de ondersteuning van hulpdiensten. Ons team ontwierp, bouwde en installeerde de software die het geïntegreerde telefoniesysteem koppelt aan het ticketsysteem. Het cloud gebaseerde systeem en de gebruiksvriendelijke interface maken betrouwbare meerkanaals datacommunicatie bij grootschalige operaties mogelijk, daarnaast bieden ze hoogwaardige ondersteuning en voldoen ze aan de veiligheidsbehoeften. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Windows Server, Typescript, ASN-1, Node.js. MySQL, Vue.JS </span><span data-ccp-props="{}"> </span></p>`,

    woltringDesc: `<p><strong><span data-contrast="auto">Het bouwen van een eerlijk transparant prestatiebeoordelingsproces voor organisaties. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Onderwijs </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">We hebben een platform voor werknemersevaluatie gebouwd dat het prestatiebeheerproces automatiseert en eenvoudig maakt. Het HR-systeem is zo ontworpen dat het helpt een boeiende en op groei gerichte feedbackcultuur te creëren en in stand te houden. Het stelt managers ook in staat om competenties en verborgen talent gemakkelijk te herkennen, terwijl de communicatie soepel en effectief blijft. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Laravel (PHP), NodeJs &amp; VueJS </span><span data-ccp-props="{}"> </span></p>`,

    movingIntelligenceDesc: `<p><strong><span data-contrast="auto">Mensen helpen hun transportmiddelen te beheren door middel van real-time data en beveiliging. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Veiligheid </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Met de Moving Intelligence app heeft de gebruiker volledige controle over al zijn transportmiddelen en mobiele objecten. Wanneer een auto, boot, motor of ander vervoermiddel is uitgerust met het Moving Intelligence systeem, kan er controle gehouden worden over de rittenregistratie, de transportmiddelen real-time volgen of de startonderbreker bedienen. Dit kan altijd en overal. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> Bluetooth</span><span data-ccp-props="{}"> </span></p>`,

    aceChallengeDescription: `<p><strong><span data-contrast="auto">Professionele golfervaring verbeteren door middel van videoanalyse en unieke uitdagingen. </span></strong><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Industrie:</span></strong><span data-contrast="auto"> Sport </span><span data-ccp-props="{}"> </span></p>
    <p><span data-contrast="auto">Onze front-end en back-end ontwikkelaars hebbeen een Android en iOS-applicatie gebouwd die spelers ondersteunt met real-time monitoring en hun ervaring naar een hoger niveau met behulp van kunstmatige intelligentie. Nadat deze functie geïmplementeerd was, is er een gokuitdaging toegevoegd, aan het gegamificeerde platform en de UI-elementen, voor spelers van elk niveau. Tegelijkertijd kan je je swingtechniek verbeteren met gepersonaliseerde video's. De Ace Challenge is de perfecte combinatie van uitdaging en entertainment, waardoor elke golfronde een onvergetelijke ervaring wordt met een extra laag spanning en competitie. </span><span data-ccp-props="{}"> </span></p>
    <p><strong><span data-contrast="auto">Technologie:</span></strong><span data-contrast="auto"> IP-camera’s, FFMPEG, Laravel (PHP), VueJS, Cordova &amp; Quasar </span><span data-ccp-props="{}"> </span></p>`,

    //#endregion
    swiperProductLbl: "Product ontwerp",
    swiperSoftwareLbl: "Software ontwerp",
    swiperElectronicLbl: "Elektronisch ontwerp",
    spinnovLbl: "Spinnov",
    followUsLbl: "Volg ons",
    ourOfficeLbl: "Onze vestiging",
    addressPartOne: "De Run 4547",
    addressPartTwo: " 5503 LT Veldhoven",
    addressSecondPartOne: "Torenallee 32-06",
    addressSecondPartTwo: " 5617 BD Eindhoven ",
    getInTouchLbl: "Contact",
    spinnovEmail: "info@spinnov.com ",
    spinnovNumber: "(+31) 40 340 01 41",
    scrollUpLbl: "scroll naar boven",
    projectsFootLbl: "Projecten",
    skillsFootLbl: "Onze expertise",
    teamFootLbl: "Team",
    contactFootLbl: "Contact",
    jobsFootLbl: "Vacatures",
    hiLetsTalkLbl: "Hi, let's talk!",
    contactUsByLbl: "Neem contact met ons op:",
    contactPhoneNumber: "(+31) 40 340 01 41",
    contactEmailSpinnov: "info@spinnov.com",
    orByFormLbl: "Of vul dit formulier in:",
    yourNameLblForm: "Je naam:",
    yourEmailAddressFormLbl: "Je e-mailadres:",
    textArealblForm: "Laat een bericht achter:",
    weNeedYourEmailAddrToLbl:
      "We hebben een emailadres nodig om je te bereiken",
    doYouLikeUsLbl: "Ben je geïnteresseerd?",
    joinOurTeamLbl: "Kom ons team versterken",
    checkOutOffersLbl: "Bekijk ons aanbod",
    electronicsHeading1: "Bekijk onze ontworpen",
    electronicsHeading2: "elektronica",
    clickOrScrollLbl: "Klik of scroll",
    floatPlusElecDescription1:
      "Float Plus is een elektrische belly boat motor. Deze motor kan traploos worden bediend met een waterdichte afstandsbediening. Dit product is ontwikkeld door vissers voor vissers voor de beste vis ervaring.",
    smartFloorDescription1:
      "Dit product wordt om de enkel gedragen om te bepalen wat de positie is op een vloer.  Met deze informatie kan het systeem bepalen of je valgevaarlijk bent. Dit product is ontwikkeld voor zorginstellingen en Spinnov heeft de elektronica ontwikkeld.",
    checkTheWebsiteLbl: "Bekijk de website",
    checkTheProductLbl: "Bekijk het product",
    joinSpinnovLbl: "Versterk Spinnov",
    jobsWeAreCompanyLbl:
      "Spinnov is een snelgroeiend design & engineering bureau in het centrum van de Brainport regio, gespecialiseerd in innovatie door technologie. Spinnov is werkzaam in een verscheidenheid aan markten en branches. Of het nu Sport, Gezondheidszorg, automotive, industrie of IoT is, Spinnov werkt er allemaal aan. Jij kan onderdeel worden van deze innovatie machine van idee tot product of de nieuwe versie van een bestaand succesvol product. ",
    jobsPartOfUsLbl: "Wil je onderdeel worden van ons team? Solliciteer dan nu.",
    currentOffersLbl: "Huidige vacatures",
    sendMassageBtn: "Verstuur bericht",
    someLbl: "Enkele ",
    projectsLbl: "projecten ",
    weLbl: "die ",
    haveDoneLbl: "we hebben ontwikkeld",
    seeMoreLbl: "Bekijk meer",
    productMenuLbl: "Product",
    electronicMenuLbl: "Elektronica",
    softwareMenuLbl: "Software",
    productTextDesc:
      "Product ontwikkeling is een reis langs verschillende fases. De complete reis van idee naar technologisch massa product vergt kennis en ervaring. We doen er alles aan om deze reis zo goed en snel mogelijk te laten verlopen.",
    softwareTextDesc:
      "Software ontwikkeling bestaat er in vele vormen. Vandaag de dag is software overal. In producten, diensten en de link naar het internet. Elke link met data betekent software. Bekijk wat wij al hebben gemaakt.",
    electronicTextDesc:
      "Elektronica ontwikkeling zit in ons bloed. Het hart van een technisch product is de elektronica. Daarom verdient dat extra aandacht. Elektronica is niet alleen hardware, maar zeker ook embedded firmware.",
    checkOutOurLbl: " Bekijk onze ontworpen",
    ourProductsLbl: "Producten",
    skillCourtProductDesc1:
      "SkillCourt is speciaal ontwikkeld voor de openbare ruimte. Het dicht het gat tussen gaming en buiten sport. Het SmartGoals Urban SkillCourt is een permanente, interactieve installatie voor de openbare ruimte. Het SkillCourt kan helemaal worden opgezet naar eigen wens. En het kan worden verrijkt met Led scshermen, voetbal doelen, boardings enz. Door middel van een app kunnen gebruikers inloggen en zichzelf testen en uitdagen.",
    skillGamesDescription1:
      "SkillGames verzameld individuele prestaties van spelers met gestandaardiseerde oefeningen. De groei van spelers wordt objectief gevolgd met vaste tests op een interactieve en leuke manier.",
    fieldBackDescription1:
      "Het is een online video analyse tool. Fieldback brengt video analyse op het veld en maakt het makkelijk om op te nemen en terug te kijken. Het hele systeem is een online systeem dat het makkelijk maakt om beelden binnen het team te delen.",
    smartTrailerDescription1:
      "Deze killer computer game maakt het mogelijk om je fijne voetbalvaardigheden op een leuke manier te leren. Probeer net zo goed te worden als Messi voor de TV. ",
    downloadTheAppLbl: "Download app",
    checkGameLbl: "Bekijk de Game",
    aboutElectronicDesign: "Elektronisch ontwerp",
    aboutElecDesc:
      "Van idee en prototyping tot volledige PCB-oplossingen. Elektronica kan behoorlijk complex zijn, tenzij je natuurlijk een team van tech-goeroes hebt die innovatie ademen. Ons team van elektronische ingenieurs en ontwerpers heeft uitgebreide kennis van electronic designs, circruit layouts, hardware en embedded firmware voor verschillende ontwerpuitdagingen. <br><br>Onze expertise omvat: <br><ul> <li>  Systeemontwerp  </li> <li>  Schematisch ontwerp   </li> <li>  PCB layout ontwerp  </li> <li>  Analysesysteem  </li> <li>  Cloudcomputing  </li></ul>",
    elecIsNotLimLbl: "Elektronica ontwerp is niet beperkt tot de hardware",
    checkOutElec: "Bekijk de elektronica",
    aboutSoftwareDesignLbl: "Software ontwerp",
    abotSoftwareDesc:
      "Snelle, betaalbare en flexibele oplossingen. We creëren computersoftware, cloudgebaseerde software, software voor mobiel en browser met naadloze integratie en efficiëntie. Onze bewezen technologieën en veilige oplossingen passen bij organisaties uit uiteenlopende sectoren. Vanaf het allereerste begin ontwikkelen we in-house, end-to-end productie die agnostisch, schaalbaar en betrouwbaar is voor baanbrekende IoT-ideeën op maat.<br><br>Onze expertise omvat: <br><ul> <li>  IoT-connectiviteit  </li> <li>  IoT-apparaatbeheer  </li> <li>  Applicatiebeheer  </li> <li>  Cloud-applicaties  </li> </ul>",
    softwareIsAVeryLbl: "Software is overal",
    broadWorkingAreaLbl: "in elk product",
    softSpinnovHasExpDesc:
      "Spinnov heeft ervaring met computer software, software in de cloud, mobiele telefoons, in de browser of op servers. Diensten aan elkaar koppelen is wat Spinnov doet.",
    checkOutSoftwareLbl: "Bekijk onze software",
    aboutProductDesignLbl: "Product ontwerp",
    aboutProductDesignDesc:
      "Van concept tot productie. Van eenvoudige tot complexe projecten, van klein tot robuust. We brengen in kaart, schetsen, beslissen, prototypen, testen, integreren en beheren onze product levenscyclus. Wij bieden oplossingen in alle verschillende stadia van het ontwikkelingsproces. In ons portfolio hebben we prestatietracking en interactieve trainingsoplossingen zoals slimme kegels en slimme fitnessinstallaties, videoanalyseapparatuur zoals draadloze webgebaseerde camerasystemen, bewegingsanalyseapparatuur, landbouw- en gezondheidszorgtechnologieën.<br><br>Onze expertise omvat: <br><ul> <li>  Nieuwe productontwikkeling  </li> <li>  Industrieel ontwerp   </li> <li>  UX/UI-ontwerp    </li> <li>  Werktuigbouwkunde    </li> <li>  Elektrotechniek   </li> <li>  Software-engineering   </li> <li>  Hardware-ontwerp   </li> </ul>",
    itsMoreThanAProductLbl: "It is more than just the product",
    itsMoreThanProductDesc:
      "Een succesvol product is veel meer dan enkel het product. Het start met een goede bepaling van de markt, de waarde, de gebruikers en de functionaliteiten. Wanneer de waardepropositie klaar is kan het concept bedacht worden. Concept ontwikkeling, electronca ontwikkeling, hardware ontwikkeling en alles wat er bij komt kijken om een product naar de markt te brengen kunnen we bij helpen.",
    checkOutProductsBtn: "Bekijk onze producten",
    interestedLbl: "Geïnteresseerd?",
    writeToUsLbl: "Neem contact met ons op",
    contactUsLbl: "Contact",
    ourAwesomeLbl: "Onze fantastische ",
    softwareAppsLbl: "software apps",
    htmSoftDesc1:
      "Binnen in elke openbaar voertuig in Den Haag is een communicatie computer die communiceert met de bijsturing. De communicatie is gebaseerd op TETRA technolgie en de implementatie van de software is gedaan door Spinnov. ",
    gmsSoftDesc1:
      "De GIK software is ontwikkeld om een telefonie systeem te koppelen aan een Meldkamer Systeem. ",
    meetOurTeemLbl: "Maak kennis met ons team",
    teamDesc:
      "Na 11 jaar besteed te hebben aan het creëren van technologische innovaties op verschillende gebieden, streven we ernaar onze klanten te ondersteunen met kostenefficiënte en schaalbare oplossingen om hen te helpen hun concurrentiekracht te benutten. Ons internationale engineeringteam in de Nederlandse Brainport High Tech Regio, is altijd bezig om up to date te blijven op het gebied van de nieuwste technologie, trends en best practices.",
    ourTeamLbl: "Ons Team",
    applyNow: "Sollicteer nu",
    applyLbl: "Solliciteer",
    aboutThePosition: "Over de functie",
    perfectCandidateLbl: "Perfecte kandidaat",
    whatWeOfferYouLbl: "Wat bieden we",
    weWantToMeetYou:
      "We willen graag kennismaken. Vul het formulier in en we hebben contact.",
    pleaseUpluadAPdfFile: "Upload een PDF alsjeblief",
    addYourCvLbl: "Voeg CV toe",
    uploadLbl: "Upload",
    thankYouFor: "Bedankt dat je ons benaderde",
    errSubmittingContact: "Fout bij het verzenden van het contactformulier",
    applyNowLblHeader: "Sollicteer nu",
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "nl",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "nl",
  messages: messages,
});
