<template>
  <div class="container" ref="homeContainer">
    <section class="carousel" id="carousel-section">
      <div class="carousel__container">
        <swiper
          class="swiper"
          :options="swiperOptionMob"
          ref="mySwiperMob"
          @slide-change-transition-start="onSwiperSlideChangeTransitionStart"
          @click-slide="onSwiperSlide"
          v-show="!isWindowWidthDesktop"
          style="margin-top: 10rem;"
        >
          <swiper-slide v-for="(slide, index) of slides" :key="index">
            <div class="carousel__slide--active">
              <div class="carousel__slide__image">
                <img
                  class="carousel__slide__image--active"
                  style="margin-left: auto;display: block;margin-right: auto;"
                  src="../assets/interaction-design-temp.png"
                />
              </div>
              <div class="carousel__slide__headline">
                <h2 class="carousel__slide__text--active">
                  {{ slide }}
                </h2>
                <span class="carousel__slide__headline--active"></span>
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <swiper
          class="swiper"
          :options="swiperOption"
          ref="mySwiper"
          @slide-change-transition-start="onSwiperSlideChangeTransitionStart"
          @click-slide="onSwiperSlide"
          v-show="isWindowWidthDesktop"
        >
          <swiper-slide v-for="(slide, index) of slides" :key="index">
            <div
              class="carousel__slide"
              :class="{
                'carousel__slide--active':
                  index + 2 === currentSlide || index + 5 === currentSlide,
              }"
            >
              <div class="carousel__slide__image">
                <img
                  :class="{
                    'carousel__slide__image--active':
                      index + 2 === currentSlide || index + 5 === currentSlide,
                  }"
                  src="../assets/interaction-design-temp.png"
                />
              </div>
              <div class="carousel__slide__headline">
                <h2
                  :class="{
                    'carousel__slide__text--active':
                      index + 2 === currentSlide || index + 5 === currentSlide,
                  }"
                >
                  {{ slide }}
                </h2>
                <span
                  :class="{
                    'carousel__slide__headline--active':
                      index + 2 === currentSlide || index + 5 === currentSlide,
                  }"
                ></span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination__container">
          <div class="swiper-pagination__text" v-show="isWindowWidthDesktop">
            <p>{{ $t("clickOrSwipeLbl") }}</p>
          </div>
          <div class="swiper-pagination">
            <span
              @click="moveSlideTo(1)"
              :class="{
                'swiper-pagination--active-line swiper-pagination--active': [
                  0,
                  3,
                ].includes(currentSlide),
              }"
              class="swiper-pagination__bullet "
            ></span>
            <span
              @click="moveSlideTo(2)"
              :class="{
                'swiper-pagination--active-line swiper-pagination--active': [
                  1,
                  4,
                ].includes(currentSlide),
                'swiper-pagination--active-line': currentSlide == 2,
              }"
              class="swiper-pagination__bullet "
            ></span>
            <span
              @click="moveSlideTo(0)"
              :class="{
                'swiper-pagination--active': currentSlide === 2,
              }"
              class="swiper-pagination__bullet "
            ></span>
          </div>
        </div>
        <div class="hero__btn-container" v-show="isWindowWidthDesktop">
          <a
            class="btn-primary btn-primary-down--skills"
            href="#projectsSection"
            @click="setUrlBack"
          >
            <div></div>
            <p></p>
            <span></span>
          </a>
        </div>
      </div>
    </section>
    <!-- // ! ELECTRONICS SECTION -->
    <section
      ref="projectsSection"
      id="projectsSection"
      v-show="showSection('E')"
    >
      <div
        class="software__section software__container software__container--reverse"
      >
        <div class="software__container__content">
          <div class="software__container__content__main">
            <h1>{{ $t("aboutElectronicDesign") }}</h1>
            <p
              v-html="converHtmlToText($t('aboutElecDesc'))"
              style="white-space: pre-wrap"
            ></p>
          </div>
        </div>
        <div class="software__container__image">
          <div class="div-background-img"></div>
        </div>
      </div>
      <div class="see-all">
        <button
          class="btn-primary"
          :class="
            isWindowWidthDesktop
              ? 'btn-primary-right--skills'
              : 'btn-primary-right--center'
          "
          @click="$router.replace('/electronics')"
        >
          <span></span>
          <p>{{ $t("checkOutElec") }}</p>
          <div></div>
        </button>
      </div>
    </section>
    <!-- // ! SOFTWARE SECTION -->
    <section
      ref="projectsSection"
      id="projectsSection"
      v-show="showSection('S')"
    >
      <div class="software__section software__container software__container">
        <div class="software__container__content">
          <div class="software__container__content__main">
            <h1>{{ $t("aboutSoftwareDesignLbl") }}</h1>
            <p
              v-html="this.converHtmlToText($t('abotSoftwareDesc'))"
              style="white-space: pre-wrap"
            ></p>
          </div>
        </div>
        <div class="software__container__image">
          <div class="div-background-img-soft1"></div>
        </div>
      </div>
      <div class="see-all">
        <button
          class="btn-primary "
          :class="
            isWindowWidthDesktop
              ? 'btn-primary-right--skills'
              : 'btn-primary-right--center'
          "
          @click="$router.replace('/software')"
        >
          <span></span>
          <p>{{ $t("checkOutSoftwareLbl") }}</p>
          <div></div>
        </button>
      </div>
    </section>
    <!-- // ! PRODUCTS SECTION -->
    <section
      ref="projectsSection"
      id="projectsSection"
      v-show="showSection('P')"
    >
      <div class="software__section software__container software__container">
        <div class="software__container__content">
          <div class="software__container__content__main">
            <h1>{{ $t("aboutProductDesignLbl") }}</h1>
            <p
              v-html="converHtmlToText($t('aboutProductDesignDesc'))"
              style="white-space: break-spaces"
            ></p>
          </div>
        </div>
        <div class="software__container__image" style="text-align: center">
          <div class="div-background-img-products1"></div>
        </div>
      </div>

      <div class="see-all">
        <button
          class="btn-primary"
          :class="
            isWindowWidthDesktop
              ? 'btn-primary-right--skills'
              : 'btn-primary-right--center'
          "
          @click="$router.replace('/products')"
        >
          <span></span>
          <p>{{ $t("checkOutProductsBtn") }}</p>
          <div></div>
        </button>
      </div>
    </section>
    <section class="offersSection" id="hero-section-3">
      <div class="offersSection__headline_likeUs">
        <h1>{{ $t("interestedLbl") }}</h1>
      </div>
      <div class="offersSection__headline_Team">
        <h1>{{ $t("writeToUsLbl") }}</h1>
      </div>
      <div class="offersSection__see-all">
        <button class="button_skills" @click="$router.replace('/contact')">
          {{ $t("contactUsLbl") }}
        </button>
      </div>
    </section>
    <div class="footer-section">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import EventBus from "../event-bus";

import useHtmlToTextConverter from "../mixins/useHtmlToTextConverter";

export default {
  data() {
    const { converHtmlToText } = useHtmlToTextConverter();
    return {
      converHtmlToText,
      inWidth: 0,
      swiperOption: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 500,
          disableOnInteraction: false,
        },
      },
      swiperOptionMob: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        autoplay: {
          delay: 500,
          disableOnInteraction: false,
        },
      },
      resizedSmallerScreen: false,
      currentSlide: 3,

      hoveredProjectsState: [false, false, false],
    };
  },
  mounted() {
    this.$refs.mySwiper.$swiper.slideTo(this.currentSlide, 1000, false);
    this.changeSlidesTo960(window.matchMedia("(max-width: 960px)"));
    window.addEventListener("resize", this.updateInnerWidth);
    this.updateInnerWidth();
    this.$refs.homeContainer.addEventListener("scroll", this.onScrollHandler);
    if (!this.isWindowWidthDesktop) {
      this.currentSlide = 1;
    }
  },
  computed: {
    slides() {
      return [
        this.$t("swiperProductLbl"),
        this.$t("swiperElectronicLbl"),
        this.$t("swiperSoftwareLbl"),
      ];
    },
    isWindowWidthDesktop() {
      if (this.inWidth <= 600) {
        return false;
      }
      return true;
    },
  },
  methods: {
    showSection(section) {
      if (this.isWindowWidthDesktop) {
        if (this.currentSlide === 3 && section === "E") {
          return true;
        } else if (this.currentSlide === 4 && section === "S") {
          return true;
        } else if (this.currentSlide === 2 && section === "P") {
          return true;
        }
      } else {
        if ([2].includes(this.currentSlide) && section === "E") {
          return true;
        } else if ([3, 0].includes(this.currentSlide) && section === "S") {
          return true;
        } else if ([1, 4].includes(this.currentSlide) && section === "P") {
          return true;
        }
      }
      return false;
    },
    isExpanded(id) {
      if (this.isWindowWidthDesktop) {
        if (!this.hoveredProjectsState[id]) {
          return "projects__element__img--not-expanded";
        }
      }
      return "";
    },
    onSwiperSlideChangeTransitionStart(swiper) {
      if (swiper.activeIndex === 4) {
        this.currentSlide = 4;
        return;
      } else if (swiper.activeIndex === 5) {
        this.currentSlide = 2;
        return;
      } else if (swiper.activeIndex === 6) {
        this.currentSlide = 3;
        return;
      } else if (swiper.activeIndex === 0) {
        this.currentSlide = 3;
        return;
      } else if (swiper.activeIndex === 1) {
        this.currentSlide = 4;
        return;
      }
      this.currentSlide = swiper.activeIndex;
    },
    onSwiperSlide(index, realIndex) {
      if (realIndex === 0) {
        this.currentSlide = 2;
      } else {
        this.currentSlide = realIndex + 2;
      }
      this.$refs.mySwiper.$swiper.slideTo(this.currentSlide, 1000, false);
    },
    moveSlideTo(slide) {
      this.currentSlide = slide + 2;
      if (this.isWindowWidthDesktop) {
        this.$refs.mySwiper.$swiper.slideTo(this.currentSlide, 1000, false);
      } else {
        this.$refs.mySwiperMob.$swiper.slideTo(this.currentSlide, 1000, false);
      }
    },

    onProjectHover(value) {
      if (this.isWindowWidthDesktop) {
        this.hoveredProjectsState.forEach((element, index) => {
          this.$set(this.hoveredProjectsState, index, false);
          if (index === value) {
            if (!this.hoveredProjectsState[index]) {
              this.$set(this.hoveredProjectsState, index, true);
            }
          }
        });
      }
    },
    onProjectMouseLeave() {
      if (this.isWindowWidthDesktop) {
        this.hoveredProjectsState = [false, false, false];
      }
    },
    setUrlBack() {
      setTimeout(() => {
        history.replaceState(null, null, "/#/");
      }, 50);
    },
    changeSlidesTo960(x) {
      if (!this.resizedSmallerScreen && x.matches) {
        this.resizedSmallerScreen = true;
      }
    },
    updateInnerWidth() {
      this.inWidth = window.innerWidth;
      if (!this.isWindowWidthDesktop) {
        this.hoveredProjectsState.forEach((element, index) => {
          this.$set(this.hoveredProjectsState, index, true);
        });
      } else {
        this.hoveredProjectsState.forEach((element, index) => {
          this.$set(this.hoveredProjectsState, index, false);
        });
      }
    },
    onScrollHandler(event) {
      if (this.resizedSmallerScreen) {
        window.scrollTo(0, 1);
      }
      let x = (event.target.scrollTop / 10) % Math.PI;
      EventBus.$emit("pageScrolled", { theta: x });
    },
  },
  components: {
    Footer,
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";
@import "../scss/_mixins.scss";

.container {
  height: calc(100vh - 115.2px);
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond(laptop) {
    height: calc(100vh - 108px);
  }
  @include respond(small-laptop) {
    height: calc(100vh - 101.73px);
  }
  @include respond(tablet-land) {
    height: calc(100vh - 96px);
  }

  @include respond(tablet-port) {
    height: calc(100vh - 72px);
  }
  @media screen and (min-width: 1920px) {
      width: 1920px;
      margin: 0 auto;
    }

}

.hero {
  display: flex;
  align-items: flex-end;
  margin: 10rem 15rem 0 15rem;
  scroll-snap-align: center;

  @include respond(laptop) {
    margin: 10rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 8rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 8rem 8rem 0 8rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    margin: 8rem 3rem 0 3rem;
  }

  &__btn-container {
    margin-left: auto;
    transform: rotate(-90deg);

    @include respond(laptop) {
    }

    @include respond(phone-land) {
      margin-top: 15rem;
    }
  }
}

.carousel {
  margin: 17rem 15rem 0 15rem;
  scroll-snap-align: center;

  @include respond(laptop) {
    margin: 0rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 0rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 0rem 8rem 0 8rem;
  }
  @include respond(tablet-port) {
    margin: 0rem 5rem 0 5rem;
  }
  @include respond(phone-land) {
    margin: 0rem 3rem 0 3rem;
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      font-family: "Raleway";
      font-size: 10rem;
      font-weight: bold;
      line-height: 1.04;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 9.5rem;
      }

      @include respond(laptop) {
        font-size: 8.3rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(tablet-port) {
        min-width: 43rem;
      }

      @include respond(phone-land) {
        min-width: 35rem;
        font-size: 5.5rem;
      }
    }

    &__line {
      margin-top: 1.5rem;
      width: 57%;
      height: 0.4rem;
      border-radius: 1rem;
      background-color: black;

      @include respond(tablet-port) {
        width: 47%;
      }
      @include respond(phone-land) {
        display: none;
      }
    }
  }

  &__container {
    margin-top: 5rem;

    @include respond(desktop) {
      margin-top: 5rem;
    }

    @include respond(laptop) {
      margin-top: 5rem;
    }

    @include respond(small-laptop) {
      margin-top: 5rem;
    }

    @include respond(tablet-land) {
      margin-top: 4rem;
    }

    @include respond(tablet-port) {
      margin-top: 3rem;
    }

    @include respond(phone-land) {
      margin-top: 2rem;
    }
  }

  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 54rem;
    height: 34rem;
    opacity: 0.4;

    @include respond(small-laptop) {
      width: 44rem;
    }

    @include respond(tablet-land) {
      width: 40rem;
    }
    @include respond(tablet-port) {
      width: 20rem;
    }
    @include respond(phone-land) {
      width: 100%;
    }
    @include respond(phone) {
      width: 100%;
    }

    &--active {
      opacity: 1;
    }

    &__image {
      & > img {
        width: 10rem;
        height: 8rem;
        transform: rotate(90deg);
        transition: all 0.3s !important;

        @include respond(tablet-port) {
          width: 8rem;
          height: 6rem;
        }
        @include respond(phone) {
          width: 5rem;
          height: 4rem;
        }
      }

      &--active {
        width: 16rem !important;
        height: 12rem !important;
        transform: rotate(0deg) !important;
        transition: all 0.3s !important;

        @include respond(tablet-port) {
          width: 16rem !important;
          height: 12rem !important;
        }
        // @include respond(phone-land) {
        //     width: 8rem !important;
        //     height: 6rem !important;
        // }
        @include respond(phone) {
          width: 16rem !important;
          height: 12rem !important;
        }
      }
    }

    &__headline {
      display: flex;
      justify-content: center;
      flex-direction: column;

      & > h2 {
        margin-top: 7rem;
        font-family: "Raleway";
        font-size: 2.6rem;
        font-weight: bold;
        line-height: 1.17;

        @include respond(tablet-port) {
          font-size: 1.8rem;
          text-align: center;
          margin-top: 3rem;
        }
        @include respond(phone-land) {
          font-size: 1.6rem;
          text-align: center;
          margin-top: 2rem;
        }
      }

      &--active {
        margin-top: 1rem;
        width: 5rem;
        height: 0.7rem;
        border-radius: 2rem;
        background-color: black;
        display: none;

        @include respond(tablet-port) {
          display: none;
        }
      }
    }

    &__text--active {
      font-size: 3.8rem !important;

      @include respond(tablet-port) {
        font-size: 3rem !important;
      }

      @include respond(phone-land) {
        font-size: 1.8rem !important;
        text-align: center;
      }
    }
  }
}

.swiper-pagination {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8rem;

    @include respond(tablet-port) {
      justify-content: flex-end;
    }
    @include respond(phone-land) {
      justify-content: center;
    }
    @include respond(phone) {
      justify-content: center;
    }
  }

  &__text {
    margin-top: -0.3rem;
    margin-right: 5rem;

    @include respond(tablet-port) {
      margin-right: 3rem;
    }

    @include respond(phone-land) {
      margin-right: 2rem;
    }

    & > p {
      font-family: "Open Sans";
      font-size: 2.2rem;
      line-height: 1.38;

      @include respond(desktop) {
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        font-size: 1.8rem;
      }

      @include respond(phone) {
        font-size: 1.6rem;
      }
    }
  }

  &__bullet {
    cursor: pointer;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.2rem solid black;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &--active {
    background-color: black;
  }

  &--active-line {
    position: relative;
    margin-right: 14rem !important;

    &::after {
      content: "";
      position: absolute;
      top: 45%;
      left: 2.6rem;
      width: 11rem;
      height: 0.2rem;
      border-radius: 10rem;
      background-color: black;
    }
  }
}

.projects {
  margin-top: 20rem;

  @include respond(tablet-port) {
    margin-top: 10rem;
  }

  @include respond(phone-land) {
    margin-top: 10rem;
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $margin-sides;
    scroll-snap-align: start;

    @include respond(laptop) {
      margin: 0 12rem;
    }

    @include respond(small-laptop) {
      margin: 0 10rem;
    }

    @include respond(tablet-land) {
      margin: 0 8rem;
    }

    @include respond(tablet-port) {
      margin: 0 5rem;
    }

    @include respond(phone-land) {
      margin: 0 3rem;
    }

    & > h1 {
      font-family: "Raleway";
      font-size: 10rem;
      font-weight: bold;
      line-height: 1.04;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 9.5rem;
      }

      @include respond(laptop) {
        font-size: 8.3rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(tablet-port) {
        min-width: 43rem;
        font-size: 6rem;
      }

      @include respond(phone) {
        min-width: 35rem;
        font-size: 5.5rem;
      }
    }

    &__line {
      margin-top: 1.5rem;
      width: 57%;
      height: 0.4rem;
      border-radius: 1rem;
      background-color: black;

      @include respond(tablet-port) {
        width: 47%;
      }
      @include respond(phone-land) {
        display: none;
      }
    }
  }

  &__text {
    margin: 10rem 15rem;

    @include respond(laptop) {
      margin: 10rem 12rem;
    }

    @include respond(small-laptop) {
      margin: 10rem 10rem;
    }

    @include respond(tablet-land) {
      margin: 10rem 8rem;
    }
    @include respond(tablet-port) {
      margin: 5rem;
    }
    @include respond(phone) {
      margin: 3rem 3rem 5rem 3rem;
    }

    & > p {
      font-family: "Open Sans";
      font-size: 2.2rem;
      width: 60rem;
      line-height: 1.38;
      opacity: 0.6;

      @include respond(desktop) {
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        font-size: 1.8rem;
      }

      @include respond(phone-land) {
        max-width: 35rem;
        font-size: 1.6rem;
      }
    }
  }

  &__element {
    cursor: default;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 23rem;
    scroll-snap-align: center;
    max-width: 250rem;

    @include respond(tablet-port) {
      margin-bottom: 0rem;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      scroll-snap-align: end !important;
    }

    &__img {
      @include respond(tablet-port) {
        width: 100%;
      }

      & > div {
        width: 100rem;
        height: 70rem;
        transition: 0.2s all;

        @include respond(laptop) {
          width: 80rem;
        }

        @include respond(small-laptop) {
          width: 60rem;
        }

        @include respond(tablet-land) {
          width: 50rem;
        }

        @include respond(tablet-port) {
          width: 100%;
          height: 40rem;
        }

        @include respond(phone) {
          width: 100%;
          height: 33rem;
        }
      }

      &--not-expanded {
        width: 150rem !important;

        @include respond(laptop) {
          width: 135rem !important;
        }

        @include respond(small-laptop) {
          width: 105rem !important;
        }

        @include respond(tablet-land) {
          width: 75rem !important;
        }

        @include respond(tablet-port) {
          width: 100% !important;
        }
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin: $margin-sides;

      @include respond(laptop) {
        margin: 0 12rem;
      }

      @include respond(small-laptop) {
        margin: 0 10rem;
      }

      @include respond(tablet-land) {
        margin: 0 8rem;
      }
      @include respond(tablet-port) {
        margin: 0 5rem;
      }
      @include respond(phone-land) {
        margin: 0 3rem;
      }
      &__numberMob {
        @include respond(laptop) {
          margin: 0 12rem;
        }

        @include respond(small-laptop) {
          margin: 0 10rem;
        }

        @include respond(tablet-land) {
          margin: 0 8rem;
        }
        @include respond(tablet-port) {
          margin: 0 5rem;
        }
        @include respond(phone-land) {
          margin: 0 3rem;
        }
        & > h1 {
          position: relative;
          font-family: "Raleway";
          font-size: 14rem;
          font-weight: bold;
          line-height: 1.17;
          -webkit-text-stroke: 3px #000000;
          -webkit-text-fill-color: white;
          opacity: 0.8;

          @include respond(tablet-port) {
            font-size: 12rem;
          }
          @include respond(phone-land) {
            font-size: 10rem;
          }

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1rem;
            background-color: black;
            border-radius: 0.2rem;
          }
        }
      }
      &__number {
        & > h1 {
          position: relative;
          font-family: "Raleway";
          font-size: 14rem;
          font-weight: bold;
          line-height: 1.17;
          -webkit-text-stroke: 3px #000000;
          -webkit-text-fill-color: white;
          opacity: 0.8;

          @include respond(tablet-port) {
            font-size: 12rem;
          }
          @include respond(phone-land) {
            font-size: 10rem;
          }

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1rem;
            background-color: black;
            border-radius: 0.2rem;
          }
        }
      }

      &__heading {
        & > h1 {
          font-family: "Raleway";
          font-size: 7rem;
          font-weight: bold;
          line-height: 1.18;
          opacity: 0.8;

          @include respond(tablet-port) {
            margin-top: 3rem;
            font-size: 5.5rem;
          }
          @include respond(phone-land) {
            margin-top: 2rem;
            font-size: 4.5rem;
          }
        }

        & > p {
          margin-top: 3rem;
          font-family: "Open Sans";
          font-size: 2rem;
          line-height: 1.38;
          max-width: 63rem;
          opacity: 0.6;

          @include respond(tablet-port) {
            margin: 3rem 0;
            font-size: 1.8rem;
          }
          @include respond(phone-land) {
            margin: 2rem 0;
            font-size: 1.6rem;
          }
        }
      }

      &__btn-container {
        @include respond(tablet-port) {
          margin-bottom: 5rem;
        }
        @include respond(phone-land) {
          margin-bottom: 3rem;
        }
        & > button {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            animation-name: bounce;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
          }

          & > p {
            margin-right: 2rem;
            font-family: "Open Sans";
            font-size: 2rem;
            line-height: 1.38;
          }

          & > div {
            width: 4rem;
            height: 3rem;
            background-image: url("../assets/right.png");
          }
        }
      }
    }
  }

  &__see-all {
    display: flex;
    justify-content: flex-end;
    margin: 10rem 15rem 0 0;
    scroll-snap-align: center;

    @include respond(tablet-port) {
      margin: 8rem 5rem 0 5rem;
      width: 60rem;
      justify-content: flex-start;
    }
    @include respond(phone-land) {
      margin: 6rem 3rem 0 3rem;
      width: 50rem;
      justify-content: flex-start;
    }
    @include respond(phone) {
      margin: 6rem 3rem 0 3rem;
      width: 30rem;
      justify-content: flex-start;
    }
  }
}

.about {
  margin: 20rem 15rem 0 15rem;
  scroll-snap-align: end;

  @include respond(laptop) {
    margin: 20rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 20rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 20rem 8rem 0 8rem;
  }
  @include respond(tablet-port) {
    margin: 8rem 8rem 0 5rem;
  }
  @include respond(phone-land) {
    margin: 8rem 8rem 0 3rem;
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      font-family: "Raleway";
      font-size: 10rem;
      font-weight: bold;
      line-height: 1.04;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 9.5rem;
      }

      @include respond(laptop) {
        font-size: 8.3rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(tablet-port) {
        min-width: 43rem;
        font-size: 6rem;
      }

      @include respond(phone) {
        min-width: 35rem;
        font-size: 5.5rem;
      }
    }

    &__line {
      margin-top: 1.5rem;
      width: 68%;
      height: 0.4rem;
      border-radius: 1rem;
      background-color: black;

      @include respond(tablet-port) {
        width: 47%;
      }
      @include respond(phone-land) {
        display: none;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 7rem;

    @include respond(tablet-port) {
      flex-direction: column-reverse;
      margin-top: 5rem;
    }
    @include respond(phone-land) {
      margin-top: 3rem;
    }
  }

  &__content {
    &__text {
      & > p {
        font-family: "Open Sans";
        font-size: 1.8rem;
        width: 60rem;
        line-height: 1.38;
        opacity: 0.6;

        @include respond(laptop) {
          width: 58rem;
        }

        @include respond(small-laptop) {
          width: 55rem;
        }

        @include respond(tablet-land) {
          width: 47rem;
        }
        @include respond(tablet-port) {
          width: 40rem;
          margin-top: 3rem;
          font-size: 1.7rem;
        }
        @include respond(phone-land) {
          width: 35rem;
          margin-top: 2rem;
          font-size: 1.6rem;
        }
      }
    }

    &__btn {
      margin-top: 10rem;

      & > btn {
        @include respond(tablet-port) {
          max-width: 20rem;
        }
      }
      @include respond(tablet-port) {
        margin-top: 0;
      }
    }
  }

  &__image {
    & > div {
      width: 90rem;
      height: 60rem;
      background-image: url("../assets/about_us.jpg");

      @include respond(laptop) {
        width: 80rem;
      }

      @include respond(small-laptop) {
        width: 65rem;
        height: 55rem;
      }

      @include respond(tablet-land) {
        width: 55rem;
        height: 50rem;
      }
      @include respond(phone-land) {
        width: 45rem;
        height: 30rem;
      }
      @include respond(phone) {
        width: 35rem;
        height: 30rem;
      }
    }
  }
}

.partners {
  margin: 20rem 15rem 0 15rem;
  scroll-snap-align: start;

  @include respond(laptop) {
    margin: 20rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 20rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 20rem 8rem 0 8rem;
  }
  @include respond(tablet-port) {
    margin: 20rem 5rem 0 5rem;
  }
  @include respond(phone-land) {
    margin: 20rem 3rem 0 3rem;
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      font-family: "Raleway";
      font-size: 10rem;
      font-weight: bold;
      line-height: 1.04;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 9.5rem;
      }

      @include respond(laptop) {
        font-size: 8.3rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(tablet-port) {
        min-width: 43rem;
        font-size: 6rem;
      }

      @include respond(phone-land) {
        min-width: 35rem;
        font-size: 5.5rem;
      }
    }

    &__line {
      margin-top: 1.5rem;
      width: 69%;
      height: 0.4rem;
      border-radius: 1rem;
      background-color: black;

      @include respond(tablet-port) {
        width: 47%;
      }
      @include respond(phone-land) {
        display: none;
      }
    }
  }

  &__text {
    margin: 5rem 0;

    @include respond(tablet-port) {
      margin: 5rem 0;
    }
    @include respond(phone-land) {
      margin: 3rem 0;
    }

    & > p {
      width: 60rem;
      font-family: "Open Sans";
      font-size: 1.8rem;
      line-height: 1.38;
      opacity: 0.6;

      @include respond(tablet-port) {
        width: 35rem;
        font-size: 1.6rem;
      }
    }
  }

  &__logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15rem 0;
    opacity: 0.5;

    @include respond(tablet-land) {
      flex-wrap: wrap;
    }

    @include respond(tablet-port) {
      display: block;
      flex-direction: column;
      align-items: center;
      margin: 0rem auto 3rem auto;
    }

    &--1 {
      width: 40rem;
      height: 4rem;
      background-image: url("../assets/smartgoals_logo.svg");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-right: 0;
        margin-bottom: 5rem;
        width: 40rem;
        height: 4rem;
      }
      @include respond(phone-land) {
        margin-right: 0;
        margin-bottom: 3rem;
        width: 35rem;
        height: 3.6rem;
      }
    }

    &--2 {
      width: 40rem;
      height: 8rem;
      background-image: url("../assets/bestronics.svg");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 5rem;
      }
      @include respond(phone-land) {
        margin-bottom: 3rem;
      }
    }
    &--3 {
      width: 45rem;
      height: 8rem;
      background-image: url("../assets/KH-logo.svg");

      @include respond(tablet-land) {
        margin-right: 3rem;
      }
      @include respond(tablet-port) {
        margin-bottom: 5rem;
      }
      @include respond(phone-land) {
        margin-bottom: 3rem;
      }
    }
  }
}
.div-background-img {
  background-image: url("../assets/skills/skills_assets_electronics-01.png");
  background-size: 600px auto;
}
.div-background-img-products1 {
  background-image: url("../assets/skills/skills_assets_product-05.png");
  background-repeat: no-repeat;
  background-size: 600px auto;
}
.div-background-img-products2 {
  background-image: url("../assets/arch.png");
  background-repeat: no-repeat;
  background-size: auto;
}
.div-background-img-soft1 {
  background-image: url("../assets/skills/skills_assets_software-03.png");
  background-repeat: no-repeat;
  background-size: 600px auto;
}
.footer-section {
  scroll-snap-align: center;
}

.software {
  margin-top: -12.3rem;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond(phone-land) {
    height: 105vh;
  }

  &__section {
    padding: 20rem 15rem 0 15rem;
    transition: 0.6s background-color;
    scroll-snap-align: center;

    @include respond(laptop) {
      padding: 20rem 12rem 0 12rem;
    }

    @include respond(small-laptop) {
      padding: 20rem 10rem 0 10rem;
    }

    @include respond(tablet-land) {
      min-height: 80rem;
      padding: 20rem 8rem 0 8rem;
    }

    @include respond(tablet-port) {
      min-height: 60rem;
      padding: 20rem 5rem 0 5rem;
    }

    @include respond(phone-land) {
      min-height: 40rem;
      padding: 0.5rem 3rem 0 3rem;
    }
  }

  &__section-container {
    position: relative;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: 10rem;

    @include respond(tablet-port) {
      flex-flow: row wrap;
    }
    @include respond(phone-land) {
      padding-bottom: 0.5rem;
      padding-top: 8rem;
      flex-flow: row wrap;
    }

    &--reverse {
      flex-direction: row-reverse;
      justify-content: space-evenly;

      @include respond(tablet-land) {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 5rem;
      }
      @include respond(phone-land) {
        padding-bottom: 0.5rem;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;

      @include respond(phone-land) {
        justify-content: center;
      }

      &__number {
        & > h1 {
          position: relative;
          font-family: "Raleway";
          font-size: 14rem;
          font-weight: bold;
          line-height: 1.17;
          -webkit-text-stroke: 3px #000000;
          -webkit-text-fill-color: transparent;
          opacity: 0.8;

          @include respond(phone-land) {
            font-size: 10rem;
            margin-bottom: 5rem;
          }

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1rem;
            background-color: black;
            border-radius: 0.2rem;
          }
        }
      }
      &__mainQuote {
        & > h1 {
          font-family: "Raleway";
          font-weight: bold;
          font-size: 6rem;
          @include respond(laptop) {
            font-size: 5.6rem;
          }
          @include respond(small-laptop) {
            font-size: 5.2rem;
          }

          @include respond(tablet-land) {
            font-size: 4.8rem;
          }

          @include respond(tablet-port) {
            font-size: 4.5rem;
          }

          @include respond(phone-land) {
            font-size: 4rem;
          }
        }

        & > p {
          margin-top: 3rem;
          font-family: "Open Sans";
          font-size: 2rem;
          line-height: 1.38;
          max-width: 63rem;
          opacity: 0.6;

          @include respond(small-laptop) {
            max-width: 53rem;
          }

          @include respond(phone-land) {
            font-size: 1.6rem;
            max-width: 90%;
          }
        }
      }
      &__main {
        & > h1 {
          font-family: "Raleway";
          font-size: 7rem;
          font-weight: bold;
          line-height: 1.18;

          @include respond(phone-land) {
            font-size: 4rem;
          }
        }

        & > p {
          margin-top: 3rem;
          font-family: "Open Sans";
          font-size: 2rem;
          line-height: 1.38;
          max-width: 63rem;
          opacity: 0.6;

          @include respond(small-laptop) {
            max-width: 53rem;
          }

          @include respond(phone-land) {
            font-size: 1.6rem;
            max-width: 90%;
          }
        }
      }
    }

    &__image {
      position: relative;
      margin-right: 10rem;
      margin-left: 10rem;
      display: flex;
      justify-content: center;

      @include respond(small-laptop) {
        margin-top: 1rem;
        margin-right: 5rem;
        margin-left: 5rem;
      }
      @include respond(tablet-port) {
        margin-top: 1rem;
        margin-right: 0rem;
        margin-left: 0rem;
        width: 100%;
      }
      @include respond(phone-land) {
        margin-top: 1rem;
        margin-right: 0rem;
        width: 100%;
        margin: 0rem;
      }
      & > div:nth-child(1) {
        width: 70rem;
        height: 60rem;
        align-self: end;

        @include respond(small-laptop) {
          width: 70rem;
          height: 60rem;
        }
        @include respond(tablet-port) {
          width: 100%;
          height: 50rem;
        }
        @include respond(phone-land) {
          background-size: 100% auto;
          width: 100% !important;
          max-height: 40rem !important;
          margin-top: 2rem;
        }
      }

      &--circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 70rem;
        height: 70rem;
        border-radius: 50%;
        border: 1px solid black;

        @include respond(small-laptop) {
          display: none;
        }
      }

      &--triangle {
        height: 80rem !important;

        @include respond(small-laptop) {
          width: 50rem !important;
          height: 60rem !important;
        }
      }

      &--square {
        position: relative;
        margin-right: 15rem;
        width: 50rem !important;
        height: 70rem !important;

        @include respond(small-laptop) {
          margin-right: 0;
          width: 40rem !important;
          height: 60rem !important;
        }

        &::after {
          content: "";
          position: absolute;
          top: 2rem;
          left: -3rem;
          width: 35rem;
          height: 35rem;
          border: 1px solid black;
          z-index: -1;

          @include respond(small-laptop) {
            display: none;
          }
        }
      }

      &--square-2 {
        position: relative;
        margin-right: 10rem;
        margin-top: 18rem;
        width: 57rem !important;
        height: 43rem !important;

        @include respond(small-laptop) {
          margin-right: 0;
          width: 47rem !important;
          height: 33rem !important;
        }

        &::after {
          content: "";
          position: absolute;
          top: -10rem;
          left: -6rem;
          width: 67rem;
          height: 63rem;
          border: 1px solid black;
          z-index: -1;

          @include respond(small-laptop) {
            display: none;
          }
        }
      }

      &--circles {
        position: relative;
        margin-right: 15rem;
        margin-top: 5rem;
        width: 38rem !important;
        height: 60rem !important;

        @include respond(small-laptop) {
          margin-right: 5rem;
          margin-top: 10rem;
          width: 33rem !important;
          height: 50rem !important;
        }

        &::before {
          content: "";
          position: absolute;
          top: -8rem;
          left: -10rem;
          width: 25rem;
          height: 25rem;
          border-radius: 50%;
          border: 1px solid black;
          z-index: -1;

          @include respond(small-laptop) {
            display: none;
          }
        }

        &::after {
          content: "";
          position: absolute;
          bottom: -7rem;
          right: -12rem;
          width: 40rem;
          height: 40rem;
          border-radius: 50%;
          border: 1px solid black;
          z-index: -1;

          @include respond(small-laptop) {
            display: none;
          }
        }
      }

      &--triangle-2 {
        width: 75rem !important;
        height: 70rem !important;

        @include respond(small-laptop) {
          width: 45rem !important;
          height: 40rem !important;
        }
      }
    }
  }

  &__pagination {
    position: fixed;
    top: 50%;
    right: -10rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    transform: rotate(-90deg);

    @include respond(tablet-land) {
      right: -15rem;
    }
    @include respond(phone-land) {
      right: -18rem;
    }
    &__text {
      margin-top: -0.3rem;
      margin-left: 2rem;

      & > p {
        font-family: "Open Sans";
        font-size: 2.3rem;
        line-height: 1.38;
      }
    }

    &__circles {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__bullet {
      cursor: pointer;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      border: 0.2rem solid black;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &--active {
      background-color: black;
    }

    &--active-line {
      position: relative;
      margin-right: 14rem !important;

      &::after {
        content: "";
        position: absolute;
        top: 45%;
        left: 2.6rem;
        width: 11rem;
        height: 0.2rem;
        border-radius: 10rem;
        background-color: black;
      }
    }
  }

  &__hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-gray-2;

    @include respond(tablet-port) {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__img {
      & > div {
        background-image: url("../assets/softwareImg.png");
        width: 62rem;
        height: 60rem;

        @include respond(small-laptop) {
          width: 50rem;
          height: 50rem;
        }

        @include respond(tablet-land) {
          width: 40rem;
          height: 39rem;
        }

        @include respond(tablet-port) {
          width: 35rem;
          height: 34rem;
        }

        @include respond(phone-land) {
          width: 30rem;
          height: 29rem;
        }
      }
    }

    &__heading {
      margin-left: 10rem;
      margin-top: -10rem;

      @include respond(tablet-port) {
        margin-left: 0;
        margin-top: 0;
        text-align: center;
      }

      & > h1 {
        font-family: "Raleway";
        font-size: 8rem;
        line-height: 1;
        color: $color-blue-3;

        @include respond(phone-land) {
          font-size: 5rem;
          margin-top: 20rem;
          margin-bottom: 10rem;
        }

        & > span {
          color: $color-blue-4;
        }
      }
    }

    &__btn-container {
      margin: 35rem -15.5rem 0 auto;
      transform: rotate(-90deg);

      @include respond(tablet-port) {
        margin: 20rem -15.5rem 0 auto;
      }

      @include respond(phone-land) {
        margin: 5rem -10rem 0 auto;
      }
    }
  }

  &__htmSection {
    background-color: $color-yellow;
  }

  &__gms {
    background-color: $color-green;
  }

  &__smartfloor {
    background-color: $color-gray-3;
  }
}
.quote {
  padding: 12.3rem 12rem 0rem 12rem;
  width: 65%;
  @include respond(laptop) {
    padding: 12.3rem 12rem 0 12rem;
    width: 65%;
  }

  @include respond(small-laptop) {
    padding: 12.3rem 10rem 0 10rem;
    width: 70%;
  }

  @include respond(tablet-land) {
    padding: 12.3rem 8rem 0 8rem;
    width: 85%;
  }

  @include respond(tablet-port) {
    padding: 12.3rem 5rem 0 5rem;
    width: 90%;
  }

  @include respond(phone-land) {
    padding: 5rem 3rem 0 3rem;
    width: 100%;
  }
  &__heading {
    font-family: "Raleway";
    font-weight: bold;
    font-size: 6rem;
    @include respond(laptop) {
      font-size: 5.6rem;
    }
    @include respond(small-laptop) {
      font-size: 5.2rem;
    }

    @include respond(tablet-land) {
      font-size: 4.8rem;
    }

    @include respond(tablet-port) {
      font-size: 4.5rem;
    }

    @include respond(phone-land) {
      font-size: 4rem;
    }
  }
}
.see-all {
  display: flex;
  justify-content: flex-end;
  margin: 8rem 3rem 20rem auto;

  @include respond(tablet-port) {
    margin: 8rem 3rem 15rem auto;
    width: 60rem;
  }
  @include respond(phone-land) {
    justify-content: center;
    margin: 8rem auto 13rem auto;
    width: 50rem;
  }
  @include respond(phone) {
    margin: 8rem auto 10rem auto;
    width: 40rem;
  }
}
.offersSection {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 20rem 15rem 15rem 15rem;

  @include respond(desktop) {
    margin: 20rem 12rem 20rem 12rem;
  }

  @include respond(laptop) {
    margin: 20rem 12rem 20rem 12rem;
  }

  @include respond(small-laptop) {
    margin: 15rem 10rem 15rem 10rem;
  }

  @include respond(tablet-land) {
    margin: 10rem 0rem 10rem 0rem;
  }
  @include respond(phone-land) {
    margin: 7rem 0rem 7rem 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__headline_likeUs {
    & > h1 {
      font-family: "Open Sans";
      font-size: 8rem;
      font-weight: bold;
      text-align: center;

      @include respond(desktop) {
        font-size: 8rem;
      }

      @include respond(laptop) {
        font-size: 8rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(phone-land) {
        font-size: 4.5rem;
      }
    }
  }
  &__headline_Team {
    & > h1 {
      font-family: "Open Sans";
      font-size: 6.4rem;
      font-weight: 600;
      text-align: center;

      @include respond(desktop) {
        font-size: 6.4rem;
      }

      @include respond(laptop) {
        font-size: 6.4rem;
      }

      @include respond(small-laptop) {
        font-size: 5.2rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }
  &__see-all {
    display: flex;
    justify-content: center;
    margin: 8rem auto 8rem auto;
    width: 80rem;

    @include respond(tablet-port) {
      margin: 8rem auto 7rem auto;
      width: 60rem;
    }
    @include respond(phone-land) {
      margin: 8rem auto 6rem auto;
      width: 50rem;
    }
    @include respond(phone) {
      margin: 8rem auto 5rem auto;
      width: 40rem;
    }
  }
}
.button_skills {
  font-family: "Raleway";
  font-size: 2.8rem;
  border: none;
  background: #4e3ee0;
  padding: 20px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  @include respond(laptop) {
    font-size: 2.6rem;
  }

  @include respond(small-laptop) {
    font-size: 2.5rem;
  }

  @include respond(tablet-land) {
    font-size: 2.2rem;
  }

  @include respond(phone-land) {
    font-size: 2rem;
  }
}
</style>
