<template>
  <div class="carousel-wrapper">
    <hooper class="slider" :autoPlay="true" :playSpeed="10000">
      <slide v-for="i in img" :key="i">
        <img :src="require(`../assets/imgs/carousels/${i.path}`)"
      /></slide>

      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>
    <!-- <transition-group name="fade" tag="div">
      <div v-for="i in [currentIndex]" :key="i"></div>
    </transition-group>
    <div v-if="images.length > 1">
      <button unelevated icon="arrow_forward_ios" />
      <button unelevated icon="arrow_back_ios_new" />
    </div> -->
  </div>
</template>
<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
export default {
  name: "Carousel",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  props: ["images"],
  data(props) {
    const img = props.images;
    return {
      img,
      timer: null,
      currentIndex: 0,
    };
  },

  mounted: function() {
    if (this.images.length > 1) {
      this.startSlide();
    }
  },

  methods: {
    startSlide: function() {
      this.timer = setInterval(this.next, 3000);
    },

    next: function() {
      this.currentIndex += 1;
      console.log("index +", this.currentIndex);
    },
    prev: function() {
      this.currentIndex -= 1;
      console.log("index -", this.currentIndex);
    },
  },

  computed: {
    currentImg: function() {
      return this.img[Math.abs(this.currentIndex) % this.img.length];
    },
  },
};
</script>
<style lang="scss">
@import "../scss/_mixins.scss";

.fade-enter-active,
.fade-leave-active {
  transition: all 0.7s ease-in-out !important;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  opacity: 1;
  width: inherit;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: inherit;
  opacity: 0;
}

.slider {
  width: 70rem;
  height: 65rem;
  @include respond(small-laptop) {
    width: 55rem;
    height: 55rem;
  }
  @include respond(tablet) {
    width: 65rem;
    height: 65rem;
  }
  @include respond(phone-land) {
    width: 35rem;
    height: 35rem;
  }
}

img {
  width: 65rem;
  height: 65rem;
  @include respond(small-laptop) {
    width: 55rem;
    height: 55rem;
  }
  @include respond(tablet) {
    width: 65rem;
    height: 65rem;
  }
  @include respond(phone-land) {
    width: 35rem;
    height: 35rem;
  }
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 27px;
  transition: 0.7s ease-in-out !important;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover,
.next:hover {
  background-color: transparent;
}
</style>
